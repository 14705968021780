import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ErrorMessages } from "src/app/shared/constants/utility.constants";
import { District } from "src/app/shared/models/district";
import { ApiDataService } from "src/app/shared/services/api.service";
import { DistrictReport } from "../report-models/distict-report";
import { ReportParameters } from "../report-models/report-param";
import { ReportsAPIService } from "../reports-api.service";
import { ReportsService } from "../reports.service";

@Component({
  selector: "app-district-reports",
  templateUrl: "./district-reports.component.html",
})
export class DistrictReportsComponent implements OnInit {
  loading = true;
  allDistricts: District[];
  selectedDistrict: District = {};
  schoolYears: string[];
  reportForm: FormGroup;
  filtersSelected: any;
  selectedReport: DistrictReport;
  selectedIndex = 0;
  currentGrade = "first_grade";
  title = "";
  graphData: ReportParameters = {};
  fileName = "";
  reportLoaded: boolean;

  constructor(
    private apiService: ApiDataService,
    private reportsAPIService: ReportsAPIService,
    private toastrService: ToastrService,
    private reportsService: ReportsService
  ) {
    this.reportForm = new FormGroup({
      district: new FormControl("", Validators.required),
      school_year: new FormControl("", Validators.required),
    });
  }

  ngOnInit(): void {
    this.getAllDistricts();
  }

  getAllDistricts() {
    this.loading = true;
    this.apiService.getAllDistricts().subscribe((res) => {
      if (res.length > 0) {
        this.allDistricts = res;
      }
      this.loading = false;
    });
  }

  districtSelected(event) {
    this.reportLoaded = false;
    this.reportsAPIService
      .getSchoolsAndYearsByDistrict(event.value)
      .subscribe((res) => {
        this.selectedDistrict = this.allDistricts.find(
          (dis) => dis.id === event.value
        );
        this.schoolYears = res.schoolYears;
        this.reportForm.patchValue({
          school_year: "",
        });
      });
  }

  renderReport(dataSelected) {
    this.filtersSelected = dataSelected;
    this.loading = true;
    this.reportsAPIService.getDistrictReport(dataSelected).subscribe(
      (report) => {
        if (report) {
          this.selectedReport = report;
          this.reportLoaded = true;
          if (
            this.selectedReport.num_second_graders === 0 &&
            this.selectedReport.num_third_graders > 0
          )
            this.currentGrade = "third_grade";
          if (this.selectedReport) {
            this.parseDataForGrade();
            this.loading = false;
          }
        }
      },
      (err) => {
        this.loading = false;
        this.toastrService.error(ErrorMessages.RUN_REPORT);
      }
    );
  }

  setListForTab(event) {
    switch (event) {
      case 0:
        this.currentGrade = "first_grade";
        break;
      case 1:
        this.currentGrade = "second_grade";
        break;
      case 2:
        this.currentGrade = "third_grade";
        break;
      case 3:
        this.currentGrade = "fifth_grade";
        break;
    }
    this.parseDataForGrade();
  }

  parseDataForGrade() {
    this.parseTitles();
    const currentGradeData = this.selectedReport[this.currentGrade];
    if (!currentGradeData) {
      this.loading = false;
    } else {
      this.graphData = null;
      this.graphData = {
        data: [
          [
            currentGradeData.dra.mid_year_gain,
            0,
            currentGradeData.rit.mid_year_gain,
          ],
          [
            currentGradeData.dra.end_year_gain,
            currentGradeData.acumen.gain_average,
            currentGradeData.rit.end_year_gain,
          ],
        ],
        extraData: [
          {
            data: this.selectedReport.num_of_schools,
            description: "Number of schools",
          },
          {
            data:
              this.currentGrade === "first_grade"
                ? this.selectedReport.num_first_graders
                : this.currentGrade === "second_grade"
                ? this.selectedReport.num_second_graders
                : this.selectedReport.num_third_graders,
            description: "Number of students",
          },
        ],
      };
    }
  }
  parseTitles() {
    const currentGradeTitle =
      this.currentGrade === "first_grade"
        ? "1st Grade"
        : this.currentGrade === "second_grade"
        ? "2nd Grade"
        : "3rd Grade";
    const schoolYearGradeCombined = `${this.selectedDistrict.district_name}<br>${this.filtersSelected.school_year}<br>${currentGradeTitle}`;
    this.title = `Average Gains - ${schoolYearGradeCombined}`;
    this.fileName = `${this.selectedDistrict.district_name.replace(
      " ",
      "_"
    )}_${this.filtersSelected.school_year.replace("/", "_")}_${
      this.currentGrade === "first_grade"
        ? "1st"
        : this.currentGrade === "second_grade"
        ? "2nd"
        : "3rd"
    }_grade`;
  }

  exportIndividualReport() {
    this.reportsService.takeSnapshot(
      document.getElementById("districtGraph"),
      `${this.fileName}`
    );
  }

  exportAllReports() {
    if (this.selectedReport) {
      this.reportsService.takeSnapshot(
        document.getElementById("allGraphs"),
        this.fileName
      );
    }
  }
}
