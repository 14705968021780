import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedDialogService } from 'src/app/shared/services/shared-dialog.service';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { Forms } from 'src/app/shared/models/form';
import { PathRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { ToastrService } from 'ngx-toastr';
import { SuccessMessages, ErrorMessages } from 'src/app/shared/constants/utility.constants';
import { AppUser } from 'src/app/shared/models/app-user';
import { StudentConstants } from '../../student-list/constants/student.constants';
@Component({
  selector: 'app-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.css']
})
export class FormDetailsComponent implements OnInit {
  eventsSignUp: Forms;
  loading: boolean;
  activeIcon = '';
  loggedInUser: AppUser;
  tutorRoleId=StudentConstants.TUTOR_ROLE_ID;
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiDataService,private sharedDialogservice:SharedDialogService,private router:Router,
    private toastr:ToastrService) { }
  ngOnInit() {
    this.loading = true;
    this.getUserInfo()
    const id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.apiService.getLink(id).subscribe(res => { this.eventsSignUp = res;
    this.loading = false;
  });
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe(res => {
      if (res) {
        this.loggedInUser = res.role.id;
      }
    })
  }
  deleteEvent(link) {
    this.sharedDialogservice.DeleteForm({ title: link.title }).subscribe(res => {
      if (res) {
        this.apiService.deleteLink(link.id, link).subscribe(response => {
          if (response) {
            this.toastr.success(SuccessMessages.DELETE_SCHOOL);
            this.navigateToEventSignUp();
          }
          else {
            this.toastr.error(ErrorMessages.SCHOOL_DELETE);
          }
        });
      }
    });
  }

navigateToEventSignUp()
{
  this.router.navigate([PathRouteConstants.FORMS]);
}
}
