import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { StudentUtility } from "../../../services/student-utility-services";

@Component({
  selector: "app-delete-absence",
  templateUrl: "./delete-absence.component.html",
})
export class DeleteAbsenceComponent {
  public dialogDataInstance: any;
  constructor(public dialogRef: MatDialogRef<DeleteAbsenceComponent>) {}

  getFullName(firstName, secondName, lastName) {
    return StudentUtility.getFullName(firstName, secondName, lastName);
  }
}
