import { AppRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateEditTutorComponent } from './create-edit-tutor/create-edit-tutor.component';
import { TutorDetailsComponent } from './tutor-details/tutor-details.component';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { TutorListComponent } from './tutor-list.component';


const routes:Routes=[
    { path: AppRouteConstants.DEFAULT, component: TutorListComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
    { path: AppRouteConstants.CREATE_TUTOR,component: CreateEditTutorComponent},
    { path: AppRouteConstants.UPDATE_TUTOR,component: CreateEditTutorComponent,pathMatch: 'full'},
    { path: AppRouteConstants.TUTOR_DETAILS,component: TutorDetailsComponent,pathMatch: 'full'},
    {path: AppRouteConstants.CREATE_TUTOR, component: CreateEditTutorComponent, pathMatch: 'full'}
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TutorRoutingModule { }
