import { Injectable, Injector } from "@angular/core";
import { RestService } from "src/app/shared/services/rest.service";
import { Observable } from "rxjs";
import { ApiUrlConstants } from "src/app/shared/constants/api-url.constants";
import { UtilityService } from "src/app/shared/services/utility.service";

@Injectable()
export class LessonsApiService extends RestService {
  // constructor to inject base url from environment
  constructor(injector: Injector) {
    super(injector);
  }
  getAllLessons(searchBase): Observable<any> {
    return this.post(ApiUrlConstants.GET_ALL_LESSONS, searchBase);
  }
  getLessonNames(): Observable<any> {
    return this.get(ApiUrlConstants.GET_LESSONS);
  }
  getLessonByGrade(body): Observable<any> {
    return this.post(ApiUrlConstants.GET_LESSONS_BY_GRADE, body);
  }
  getPassagesByGrade(body): Observable<any> {
    return this.post(ApiUrlConstants.GET_PASSAGES_BY_GRADE, body);
  }
  deleteLesson(id): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.DELETE_LESSON, [
        { text: "@lessonId", data: id },
      ])
    );
  }
  getLesson(id): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.GET_LESSON_DETAILS, [
        { text: "@lessonId", data: id },
      ])
    );
  }
  createLesson(lesson): Observable<any> {
    return this.post(ApiUrlConstants.CREATE_LESSON, lesson);
  }
  updateLesson(lesson): Observable<any> {
    return this.post(ApiUrlConstants.UPDATE_LESSON, lesson);
  }
}
