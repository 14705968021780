export class StudentConstants {
    public static GRADES = ['All Grades', '2nd Grade', '3rd Grade', '5th Grade'];
    public static EXISTING_GRADES = ['2nd', '3rd', '5th'];
    public static BULK_ACTIONS_ACTIVE = ['Advance Grade', 'Revert Grade', 'Deactivate', 'Edit Teacher', 'Add Notes'];
    public static TUTOR_BULK_ACTIONS_ACTIVE=['Edit Teacher', 'Add Notes']
    public static BULK_ACTIONS_INACTIVE = ['Activate', 'Add Notes'];
    public static TUTOR_BULK_ACTIONS_INACTIVE=['Add Notes']
    public static STUDENT_ACTIONS = ['Import Students', 'Create Student', 'Import Scores'];
    public static TUTOR_ACTION = ['Import Tutor', 'Create Tutor'];
    public static ALL_SCHOOLS = ['All Schools'];
    public static ALL_GRADES = ['All Grades'];
    public static INACTIVE_STATUS = ['Graduated', 'Scored Out', 'Moved', 'Removed'];
    public static MAT_TAB_LABELS = ['H.U.G Students', 'Control Group'];
    public static STUDENT_BLANK_TEMPLATE_LINK = 'https://s3.amazonaws.com/hug-dev/hug-dev/student_import_blank.xlsx';
    public static SCORE_BLANK_TEMPLATE_LINK = 'https://s3.amazonaws.com/hug-dev/hug-dev/DRA_import_template.xlsx';
    public static PAIRING_GROUP = [
        { display: '0', value: 0 },
        { display: '*', value: 1 },
        { display: '**', value: 2 },
        { display: '***', value: 3 },
        { display: '****', value: 4 }
    ];
    public static SKILL_LEVELS = [
        { color: 'Red', value: 1 },
        { color: 'Orange', value: 2 },
        { color: 'Yellow', value: 3 },
        { color: 'Light Blue', value: 4 },
        { color: 'Green', value: 5 }
    ];
    public static ROLES = [
        { role: 'Admin', value: 1 },
        { role: 'Specialist', value: 2 },
        { role: 'Tutor', value: 3 },
    ];
    public static TUTOR_ROLE_ID=3;
    public static get YEARREGEX(): RegExp { return /^\d{4}\/\d{4}$/; }
}