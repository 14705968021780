import { Component, Inject } from "@angular/core";
import { ApiDataService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import {
  ActionMessages,
  ErrorMessages,
} from "../../constants/utility.constants";
import { BulkAction } from "../../models/bulk-action";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-deactivate-tutor",
  templateUrl: "./deactivate-tutor.component.html",
})
export class DeactivateTutorComponent {
  public dialogDataInstance: any;
  inactiveReason: string;
  actionMessages = ActionMessages;
  notes: string;
  bulkActionBody: BulkAction = {};

  constructor(
    public dialogRef: MatDialogRef<DeactivateTutorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {}

  confirmAction() {
    // tslint:disable-next-line:no-conditional-assignment
    this.apiService
      .setTutorActivitys(this.data.tutor.id, this.data.tutor)
      .subscribe(
        (res) => this.dialogRef.close(true),
        (err) => this.toastrService.error(ErrorMessages.BULK_ACTION)
      );
  }
}
