import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthorizationService } from "./authorization.service";
import { StorageService } from "./storage.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private _authService: AuthorizationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!StorageService.getToken()) {
      this._authService.logoutWithReturnUrl(state.url);
      return Promise.resolve(false);
    } else if (localStorage.getItem("previousURL") && this.router.url !== "/") {
      return Promise.resolve(true);
    }
    return Promise.resolve(true);
  }
}
