import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import { Tutor } from "src/app/shared/models/tutor-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { UtilityService } from "src/app/shared/services/utility.service";
import { TutorInfo } from "src/app/shared/models/tutor-info";
import { Subject } from "rxjs";
import { School } from "src/app/shared/models/school";
import { NumberConstants } from "src/app/shared/constants/utility.constants";
import { AppUser } from "src/app/shared/models/app-user";
import { takeUntil } from "rxjs/operators";
import { StudentConstants } from "../student-list/constants/student.constants";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { TutorFilterService } from "src/app/tutor-filter.service";

@Component({
  selector: "app-tutor-list",
  templateUrl: "./tutor-list.component.html",
  styleUrls: ["./tutor-list.component.css"],
})
export class TutorListComponent implements OnInit {
  tutorList: Tutor[];
  volunteerId: number;
  tutorId: number;
  entireListSelected: any;
  schoolSelected = this.tutorFilterService.tutorInfo.school;
  levelSelected = this.tutorFilterService.tutorInfo.commitmentFilter;
  loading: boolean;
  tutorActions = StudentConstants.TUTOR_ACTION;
  basicLimit = NumberConstants.LIST_LIMIT;
  selectedTutors: Tutor[] = [];
  filteredTutorList: Tutor[] = [];
  TutorListCopy: Tutor[];
  selectedIndex = 0;
  tutorInfo: TutorInfo;
  schoolList: School[] = [];
  levelList: any;
  count: number;
  loggedInUser: AppUser;
  studentActions: any;
  selectedBulkAction: any;
  selectedtutors: any;
  private ngUnsubscribe = new Subject();
  constructor(
    private apiService: ApiDataService,
    private router: Router,
    private sharedDialogService: SharedDialogService,
    private tutorFilterService: TutorFilterService,
    private navigationService: NavigationService
  ) {}
  ngOnInit() {
    this.loading = true;
    this.tutorInfo = this.tutorFilterService.tutorInfo;
    this.tutorInfo.isActive = 1;
    if (this.tutorInfo.offset) {
      this.tutorInfo.offset = 0;
    }
    this.getUserInfo();
    this.getTutorList();
    this.getSchools();
    this.getCommitmentLevels();
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
      }
    });
  }
  getTutorList() {
    this.loading = true;
    this.apiService.getVolunteerList(this.tutorInfo).subscribe((res) => {
      if (res) {
        // tslint:disable-next-line:prefer-for-of
        for (let tutor of res.tutor) {
          this.filteredTutorList.push(tutor.user);
        }
        this.count = res.count;
        this.loading = false;
      }
    });
  }
  getSchools() {
    this.apiService.getSchoolList().subscribe((response) => {
      if (response) {
        this.schoolList = response;
        this.schoolList = this.schoolList.filter(
          (data) => data.school_name != null
        );
        UtilityService.sortArray(this.schoolList);
      }
    });
  }
  getCommitmentLevels() {
    this.apiService.getAllCommitments().subscribe((response) => {
      if (response) {
        this.levelList = response;
        this.levelList = this.levelList.filter(
          (data) => data.commitment_level != null
        );
        const allLevel = { id: -1, commitment_level: "All Levels" };
        this.levelList.push(allLevel);
        this.levelList = this.levelList.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
      }
    });
  }
  onSearch() {
    this.tutorInfo.offset = 0;
    this.filteredTutorList = [];
    this.getTutorList();
  }
  onLevelChange(event) {
    if (event.value) {
      this.filteredTutorList = [];
      this.tutorInfo.offset = 0;
      this.tutorInfo.commitmentFilter = event.value;
      this.tutorFilterService.tutorInfo.commitmentFilter = event.value;
      this.getTutorList();
    }
  }
  onSchoolChange(event) {
    if (event.value) {
      this.filteredTutorList = [];
      this.tutorInfo.offset = 0;
      this.tutorInfo.school = event.value;
      this.tutorFilterService.tutorInfo.school = event.value;
      this.getTutorList();
    }
  }
  onScrollDown() {
    this.tutorInfo.offset = this.tutorInfo.offset + this.basicLimit;
    this.getTutorList();
  }
  tutorProfile(volunteerId) {
    this.router.navigate([
      PathRouteConstants.TUTOR_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.TUTOR_DETAILS, [
          { text: ":id", data: volunteerId },
        ]),
    ]);
  }
  tutorAction(event) {
    switch (event) {
      case this.tutorActions[0]: {
        this.sharedDialogService
          .ImportTutor({
            action: this.selectedBulkAction,
            tutorList: this.selectedtutors,
          })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res) {
              this.filteredTutorList = [];
              this.getTutorList();
            }
          });
        break;
      }
      case this.tutorActions[1]: {
        this.navigationService.navigateToPage([
          PathRouteConstants.TUTOR_LIST + "/" + AppRouteConstants.CREATE_TUTOR,
        ]);
        break;
      }
    }
  }
  editTutor(tutorId) {
    this.router.navigate([
      PathRouteConstants.TUTOR_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.UPDATE_TUTOR, [
          { text: ":id", data: tutorId },
        ]),
    ]);
  }

  createTutor() {
    this.router.navigate([
      PathRouteConstants.TUTOR_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.CREATE_TUTOR, [{}]),
    ]);
  }
}
