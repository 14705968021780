import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthorizationService } from "src/app/shared/services/authorization.service";
import { ToastrService } from "ngx-toastr";
import {
  SuccessMessages,
  ErrorMessages,
} from "src/app/shared/constants/utility.constants";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { AppRouteConstants } from "src/app/shared/constants/app-route.constants";
import { LoginBase } from "src/app/shared/models/login-base";
import { UtilityService } from "src/app/shared/services/utility.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  ForgotPasswordForm: FormGroup;
  userInfo: LoginBase = {};
  isSubmitted = false;
  loading: any;
  error: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthorizationService,
    private toaster: ToastrService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.ForgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required,  Validators.pattern(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      )]],
    });  
  }
  OnForgotPasssword() {
    this.loading = true;
    this.userInfo.email = this.ForgotPasswordForm.get("email").value;
    this.authService.forgetpassword(this.userInfo).subscribe(
      (result) => {
        if (result) {
          this.loading = false;
          this.toaster.success(
            UtilityService.replaceText(SuccessMessages.PASSWORD, [
              { text: "@email", data: this.userInfo.email },
            ]),
            "",
            { timeOut: 3000 }
          );
          this.navigationService.navigateToPage([AppRouteConstants.LOGIN]);
        }
      },
      (err) => {
        this.loading = false;
        this.toaster.error(ErrorMessages.USER_NOT_FOUND);
      }
    );
  }
  navigateToLogin() {
    this.navigationService.navigateToPage([AppRouteConstants.LOGIN]);
  }
}
