import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-delete-school",
  templateUrl: "./delete-school.component.html",
})
export class DeleteSchoolComponent {
  public dialogDataInstance: any;
  constructor(public dialogRef: MatDialogRef<DeleteSchoolComponent>) {}
}
