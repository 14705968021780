import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import { UtilityService } from "src/app/shared/services/utility.service";
import { ToastrService } from "ngx-toastr";
import {
  SuccessMessages,
  ErrorMessages,
  ActionMessages,
  NumberConstants,
} from "src/app/shared/constants/utility.constants";
import { LessonsApiService } from "../../services/lessons-api.service";
import { Lesson } from "../../models/lesson";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ListBase } from "src/app/shared/models/list-base";
import { AppUser } from "src/app/shared/models/app-user";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentConstants } from "src/app/feature-modules/student-list/constants/student.constants";

@Component({
  selector: "app-lesson-list",
  templateUrl: "./lesson-list.component.html",
  styleUrls: ["./lesson-list.component.css"],
})
export class LessonListComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  basicLimit = NumberConstants.LESSONS_LIMIT;
  searchBase: ListBase = { search: "", offset: 0, limit: this.basicLimit };
  filteredLessonCount: number;
  loading = false;
  filteredLessons: Lesson[] = [];
  loggedInUser: AppUser;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;

  constructor(
    private apiService: ApiDataService,
    private lessonsApiService: LessonsApiService,
    private sharedDialogservice: SharedDialogService,
    private navigationService: NavigationService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.getUserInfo();
    this.getLessons();
  }

  getLessons() {
    this.loading = true;
    this.lessonsApiService
      .getAllLessons(this.searchBase)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.filteredLessonCount = res[1];
          this.filteredLessons.push(...res[0]);
        }
        this.loading = false;
      });
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
      }
    });
  }
  onScroll() {
    this.searchBase.offset = this.searchBase.offset + this.basicLimit;
    this.getLessons();
  }

  searchChange() {
    this.searchBase.offset = 0;
    this.filteredLessons = [];
    this.getLessons();
  }

  goToLessonDetail(id) {
    this.navigationService.navigateToPage([
      PathRouteConstants.LESSONS +
        UtilityService.replaceText(AppRouteConstants.LESSON_DETAILS, [
          { text: ":id", data: id },
        ]),
    ]);
  }

  updateLesson(id) {
    if (id) {
      this.navigationService.navigateToPage([
        PathRouteConstants.LESSONS +
          UtilityService.replaceText(AppRouteConstants.LESSON_UPDATE, [
            { text: ":id", data: id },
          ]),
      ]);
    } else {
      this.navigationService.navigateToPage([
        PathRouteConstants.LESSONS + AppRouteConstants.LESSON_CREATE,
      ]);
    }
  }

  deleteLesson(lesson) {
    this.sharedDialogservice
      .ConfirmAction({
        Title: "Delete " + lesson?.lesson_plan_name,
        Message: UtilityService.replaceText(
          ActionMessages.DELETE_CONFIRMATION,
          [{ text: "_name", data: lesson?.lesson_plan_name }]
        ),
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.lessonsApiService
            .deleteLesson(lesson.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              if (result) {
                this.toastrService.success(SuccessMessages.DELETE_LESSON);
                this.filteredLessons = this.filteredLessons.filter(
                  (l) => l.id !== lesson.id
                );
                this.filteredLessonCount = this.filteredLessons.length;
              } else {
                this.toastrService.error(ErrorMessages.LESSON_DELETE);
              }
            });
        }
      });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
