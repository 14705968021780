// APIs invoked

export class ApiUrlConstants {
  public static LOGIN_URL = "user/login";
  public static FORGOT_PASSWORD = "user/forgetPassword";
  public static CHANGE_PASSWORD = "user/changePassword";
  public static GET_USER_INFO = "user/self";
  public static VERIFY_USER = "user/glogin";

  public static GRADE_LIST = "basic/getAllGrades";
  public static ZONE_LIST = "basic/getAllZones";
  public static COMMITMENT_LEVELS = "basic/getAllCommitments";
  public static GET_ALL_PREFERENCES = "basic/getAllPreferences";
  public static GET_ALL_ROLES = "basic/getAllRoles";
  public static STATES_LIST = "basic/getAllStates";

  public static GET_VOLUNTEERS = "appuser/getAll";
  public static GET_SUB_TUTORS = "appuser/getSubTutors";
  public static ADD_SUBSTITUTION = "appuser/addSubstitution";
  public static ADD_TEAMUSERS = "session/inviteTeamuser";
  public static GET_ALL_SUBSTITUTION = "appuser/getSubstitutions";
  public static DELETE_SUBSTITUTION = "appuser/deleteSubstitution";
  public static GET_VOLUNTEER_LIST = "appuser/getUser";
  public static CREATE_TUTOR = "appuser/create";
  public static UPDATE_TUTOR = "appuser/update/@id";
  public static ISACTIVE_TUTOR = "appuser/activate/@id";
  public static VOLUNTEER_DETAILS = "appuser/getDetails/@id";
  public static TUTOR_ASSOCIATED_SCHOOLS = "appuser/schoolsAssocWithTutor/@id";
  public static STUDENT_ASSOCIATED_SCHOOLS =
    "appuser/studentsAssocWithSchool/@id";
  public static STUDENT_DETAILS = "student/getDetails/@id";
  public static IMPORT_TUTOR = "appuser/importTutor";

  public static DISTRICT_LIST = "district/getAllNames";
  public static DISTRICT = "district/getAll";
  public static DISTRICT_DETAILS = "district/getDetails/@id";
  public static CREATE_DISTRICT = "district/create";
  public static UPDATE_DISTRICT = "district/update";

  // students
  public static CREATE_STUDENT = "student/create";
  public static UPDATE_STUDENT = "student/update/@id";
  public static GET_TUTORS_TO_ASSOCIATE = "student/tutorsToAssociate";
  public static REMOVE_ASSOCIATION = "student/deleteTutor";
  public static ASSOCIATE_TUTORS = "student/associate";
  public static CHANGE_PRIMARY_ASSOC = "student/changePrimary";
  public static STUDENT_LIST = "student/getAll";
  public static GET_SUB_STUDENTS = "student/getSubStudents";

  public static ASSOCIATED_STUDENT_LIST =
    "student/getAllAssociatedStudents/@id";
  public static BULK_ACTION = "student/bulkAction";

  // Lessons
  public static GET_ALL_LESSONS = "lesson/getAll";
  public static GET_LESSONS = "lesson/getAllNames";
  public static GET_LESSONS_BY_GRADE = "lesson/getAllLessons";
  public static GET_PASSAGES_BY_GRADE = "lesson/getPassageByGrade";
  public static DELETE_LESSON = "lesson/delete/@lessonId";
  public static GET_LESSON_DETAILS = "lesson/getDetails/@lessonId";
  public static CREATE_LESSON = "lesson/create";
  public static UPDATE_LESSON = "lesson/update";

  // schools
  public static GET_SCHOOLS = "schools/getAll";
  public static GET_SCHOOL_NAMES = "schools/getAllNames";
  public static SCHOOL_DETAILS = "schools/getDetails/@id";
  public static UPDATE_ASSOCIATED_TUTORS_COUNT =
    "schools/updateAssociatedTutorsCount";
  public static DELETE_SCHOOL = "schools/delete/@id";
  public static CREATE_SCHOOL = "schools/create";
  public static UPDATE_SCHOOL = "schools/update";

  public static SCHOOL_REPORT = "report/schools";
  public static STUDENT_REPORT = "report/students";
  public static GET_STUDENTS_BY_SCHOOL_AND_YEAR =
    "report/getStudentBySchoolAndYear";
  public static TUTOR_REPORT = "report/tutors";
  public static DISTRICT_REPORT = "report/districts";
  public static GET_SCHOOLS_YEARS_BY_DISTRICT =
    "report/getSchoolsAndYearsByDistrict/@districtId";
  public static GET_SCHOOLS_YEARS_TUTORS_BY_DISTRICT =
    "report/getSchoolsTutorsYearsByDistrict/@districtId";

  public static GET_APP_LINKS = "events-signup/getAll";

  public static DELETE_LINK = "events-signup/delete/@id";
  public static GET_LINK = "events-signup/getDetails/@id";
  public static CREATE_LINK = "events-signup/create";
  public static UPDATE_LINK = "events-signup/update";
  public static IMPORT_STUDENTS = "student/importStudents";
  public static IMPORT_SCORES = "scores/import";

  // Absences
  public static GET_ABSENCES = "absence/getAll/@studentId";
  public static UPDATE_ABSENCE = "absence/update";
  public static DELETE_ABSENCE = "absence/delete/@absenceId";
  public static CREATE_ABSENCE = "absence/create";

  // Progress
  public static GET_SESSIONS_BY_GRADE = "session/getSessionsByGrade/@studentId";
  public static GET_SESSIONS_BY_LESSON =
    "session/getSessionsByLesson/@studentId";
  public static GET_AVAILABLE_LESSONS =
    "session/getAvailableLessons/@studentId/@gradeId";
  public static GET_PROGRESS_BY_LESSON = "session/getProgressByLesson";
  public static ADD_NEW_PROGRESS = "session/create";
  public static GET_ALL_REASON = "session/getAllReasons";
  public static EDIT_PROGRESS = "session/editProgress";
  public static GET_BOARD_COPY = "session/boardcopy";

  // Scores
  public static GET_SCORES = "scores/getAll/@studentId";
  public static UPDATE_SCORE = "scores/update";
  public static CREATE_SCORE = "scores/create";
}
