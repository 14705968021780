import { Component, OnInit } from "@angular/core";
import {
  ActionMessages,
  ErrorMessages,
} from "src/app/shared/constants/utility.constants";
import { MatDialogRef } from "@angular/material/dialog";
import { BulkAction } from "../../models/bulk-action";
import { ApiDataService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Student } from "../../models/student-base";

@Component({
  selector: "app-grade-conversion",
  templateUrl: "./grade-conversion.component.html",
})
export class GradeConversionComponent implements OnInit {
  selectedIds: number[];
  filteredStudentList: Student[];
  actionMessages = ActionMessages;
  public dialogDataInstance: any;
  selected1stGradeList: Student[];
  selected2ndGradeList: Student[];
  selected3rdGradeList: Student[];
  selected4thGradeList: Student[];
  selected5thGradeList: Student[];
  bulkActionBody: BulkAction = {};
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<GradeConversionComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    if (this.dialogDataInstance) {
      this.filteredStudentList = this.dialogDataInstance.studentList;
      this.getStudentsByGrade();
    }
  }

  getStudentsByGrade() {
    this.selected1stGradeList = this.filteredStudentList.filter(
      (student) => student.Grade === "1st"
    );
    this.selected2ndGradeList = this.filteredStudentList.filter(
      (student) => student.Grade === "2nd"
    );
    this.selected3rdGradeList = this.filteredStudentList.filter(
      (student) => student.Grade === "3rd"
    );
    this.selected4thGradeList = this.filteredStudentList.filter(
      (student) => student.Grade === "4th"
    );
    this.selected5thGradeList = this.filteredStudentList.filter(
      (student) => student.Grade === "5th"
    );
  }

  confirmAction() {
    this.loading = true;
    this.bulkActionBody.studentIds = this.filteredStudentList.map(
      (student) => student.id
    );
    this.bulkActionBody.id =
      this.dialogDataInstance.action === "Advance Grade" ? 5 : 6;
    this.apiService.bulkActionStudents(this.bulkActionBody).subscribe((res) => {
      if (res) {
        this.dialogRef.close(true);
      } else {
        this.toastrService.error(ErrorMessages.BULK_ACTION);
        this.dialogRef.close(false);
      }
      this.loading = false;
    });
  }
}
