import { NgModule } from '@angular/core';
import { ReportsRoutingModule } from './reports.routes';
import { StudentReportsComponent } from './student-reports/student-reports.component';
import { CommonModule } from '@angular/common';
import { SchoolReportsComponent } from './school-reports/school-reports.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsService } from './reports.service';
import { TutorReportsComponent } from './tutor-reports/tutor-reports.component';
import { DistrictReportsComponent } from './district-reports/district-reports.component';
import { ReportsAPIService } from './reports-api.service';
import { BasicColumnChartComponent } from './charts/basic-column-chart/basic-column-chart.component';
import { DoubleDonutChartComponent } from './charts/double-donut-chart/double-donut-chart.component';
import { ChartService } from './charts/charts.service';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { BasicHorizontalBarChartComponent } from './charts/basic-horizontal-bar-chart/basic-horizontal-bar-chart.component';
import { TutorSessionReportComponent } from './tutor-reports/tutor-session-report/tutor-session-report.component';

@NgModule({
    declarations: [
        StudentReportsComponent,
        SchoolReportsComponent,
        TutorReportsComponent,
        DistrictReportsComponent,
        BasicColumnChartComponent,
        DoubleDonutChartComponent,
        BasicHorizontalBarChartComponent,
        TutorSessionReportComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ReportsRoutingModule,
        ChartModule
    ],
    providers: [ReportsService, ChartService, ReportsAPIService,
        { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }],
    bootstrap: []
})
export class ReportsModule { }