import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormArray,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  SuccessMessages,
  ErrorMessages,
  ActionMessages,
  LessonPassageConstants,
} from "src/app/shared/constants/utility.constants";
import { ToastrService } from "ngx-toastr";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { PathRouteConstants } from "src/app/shared/constants/app-route.constants";
import { LessonConstants } from "../../constants/lesson-constants";
import { LessonsApiService } from "../../services/lessons-api.service";
import { Lesson } from "../../models/lesson";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ApiDataService } from "src/app/shared/services/api.service";
import { Grade } from "src/app/shared/models/grade";
import { editorConfig } from "../../constants/editor-constants";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { UtilityService } from "src/app/shared/services/utility.service";

@Component({
  selector: "app-update-lesson",
  templateUrl: "./update-lesson.component.html",
  styleUrls: ["./update-lesson.component.css"],
})

export class UpdateLessonComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  updateLessonForm: FormGroup;
  isEditMode: boolean = false;
  lessonId: number;
  editLesson: boolean;
  loading = false;
  editorContent: string[] = [];
  lessonToEdit: Lesson = {};
  primaryImages: string[] = [];
  secImages: string[] = [];
  terImages: string[] = [];
  quatImages: string[] = [];
  inputName: string = 'inputName';
  allGrades: Grade[];
  passageIds: Lesson[] = [];
  choosePassageDisabled: boolean = true;
  lessonPassageByGrade: Lesson[];
  lessonPassageByGradeStore: Lesson[];
  selectedPassageIds: Lesson[] = [];
  passageDetails: any = [];
  images: any[] = [];
  indexBasedImages: any[] = [];
  boardId: FormControl;
  primaryImageName = LessonConstants.PRIMARY_IMAGE_LITERAL;
  secondaryImageName = LessonConstants.SECONDARY_IMAGE_LITERAL;
  terImageName = LessonConstants.TERRITIARY_IMAGE_LITERAL;
  quatImageName = LessonConstants.QUATENARY_IMAGE_LITERAL;
  isChoosePassage: boolean = false;
  summary: string;
  hideDropdown: string = LessonPassageConstants.HIDE_DROPDOWN;
  showDropdown: string = LessonPassageConstants.SHOW_DROPDOWN;
  editorConfig = editorConfig;
  radioButtonItems: any = [
    { selectedOption: this.hideDropdown },
    { selectedOption: this.hideDropdown },
  ]
  constructor(
    private route: ActivatedRoute,
    private lessonsApiService: LessonsApiService,
    private toastrService: ToastrService,
    private navigationService: NavigationService,
    private sharedApiService: ApiDataService,
    private sharedDialogservice: SharedDialogService,
    private apiService: ApiDataService
  ) {
    this.updateLessonForm = new FormGroup({
      lesson_plan_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      summary: new FormControl(null, Validators.required),
      grade_id: new FormControl(null, Validators.required),
      miro_url: new FormControl("", [
        Validators.required,
        this.miroBoardValidator.bind(this),
      ]),
      lesson_plan_number: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?!0$)(\d\d{0,2}|1000)$/),
      ]),
      passage: new FormArray([])
    });
  }

  ngOnInit() {
    this.editActions();
    this.getGrades();
    if (!this.editLesson) {
      (this.updateLessonForm.get('passage') as FormArray).push(this.createElementGroup());
      (this.updateLessonForm.get('passage') as FormArray).push(this.createElementGroup());
    }
  }

  createElementGroup(): FormGroup {
    return new FormGroup({
      passage_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      word_count: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?!0$)(\d\d{0,2}|100000)$/),
      ]
      ),
      primary_image_path: new FormControl(""),
      sec_image_path: new FormControl(""),
      ter_image_path: new FormControl(""),
      quat_image_path: new FormControl(""),
      passage_summary: new FormControl("", [
        Validators.required
      ]),
    });
  }

  get passage(): FormArray {
    return this.updateLessonForm.get('passage') as FormArray;
  }

  getPassageControl(index: number): FormGroup {
    return (this.updateLessonForm.get('passage') as FormArray).at(index) as FormGroup;
  }

  editActions() {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.lessonId = +params.id;
        if (this.lessonId) {
          this.editLesson = true;
          this.getLesson();
        }
      });
  }

  getGrades() {
    this.sharedApiService
      .getAllGrades()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          const refractoredData = res.sort((a, b) => {
            if (a.grade_level < b.grade_level) return -1;
            if (a.grade_level > b.grade_level) return 1;
            return 0;
          });
          this.allGrades = refractoredData;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  onGradeSelectionChange(event: any, grade: any) {
    if (event.isUserInput) {
      this.choosePassageDisabled = false
      this.lessonsApiService
      .getPassagesByGrade({grade_id : grade?.id, search: ""})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res.length) {
            this.lessonPassageByGrade = res
            this.lessonPassageByGradeStore = res
            this.passageDetails = []
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }

  onPassageSelect(event: any, passage: any, index: number) {
    if (event.isUserInput) {
      if (this.selectedPassageIds.includes(passage?.passage_id)) {
        this.toastrService.error(ErrorMessages.DUPLICATE_PASSAGE_SELECTED);
        this.selectedPassageIds[index] = passage?.passage_id
        delete this.passageDetails[index]
        delete this.editorContent[index]
      } else {
        passage.passage_summary = passage.passage_summary || ""
        this.passageDetails[index] = passage
        this.selectedPassageIds[index] = passage?.passage_id
        this.editorContent[index] = passage?.passage_summary
        if (!passage?.primary_image_path && !passage?.sec_image_path && !passage?.ter_image_path && !passage?.quat_image_path) {
            this.indexBasedImages.push({ path: "assets/images/noimage.jpg" });
        } else {
        if (passage?.primary_image_path) {
          this.indexBasedImages.push({ path: passage?.primary_image_path });
        }
        if (passage?.sec_image_path) {
          this.indexBasedImages.push({ path: passage?.sec_image_path });
        }
        if (passage?.ter_image_path) {
          this.indexBasedImages.push({ path: passage?.ter_image_path });
        }
        if (passage?.quat_image_path) {
          this.indexBasedImages.push({ path: passage?.quat_image_path });
        }
      }
        this.images[index] = this.indexBasedImages
        this.indexBasedImages = []
  }
  }
}

 onRadioChange(index: number, option: string) {
    this.radioButtonItems[index].selectedOption = option
    delete this.passageDetails[index]
    delete this.selectedPassageIds[index]
    delete this.editorContent[index]
    delete this.primaryImages[index]
    delete this.secImages[index]
    delete this.terImages[index]
    delete this.quatImages[index]
    const formArray = this.updateLessonForm.get('passage') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    formGroup.get('passage_name').setValue("")
    formGroup.get('word_count').setValue("")
  }
  miroBoardValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const miroPattern = /^https:\/\/miro\.com\/app\/board\/[\w-]+=\/?$/;
    const isValid = miroPattern.test(value);

    if (!value || !isValid) {
      return { invalidMiroBoardLink: true };
    }
    return null;
  }
  
 validateEditorContent(value) {
  let content = value || '';
  const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "");
  const parser = new DOMParser();
  const decodedContent = parser.parseFromString(strippedContent, 'text/html').body?.textContent || "";
  if (decodedContent?.trim()?.length === 0) {
    return false;
  } else {
    return true;
  }
}
  getLesson() {
    this.loading = true;
    this.lessonsApiService
      .getLesson(this.lessonId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res) {
            const formArray = this.updateLessonForm.get('passage') as FormArray;
            formArray.clear(); 
            this.lessonToEdit = res;
            this.radioButtonItems = [
              { selectedOption: this.hideDropdown },
              { selectedOption: this.hideDropdown },
            ]
            this.setForm();
            this.loading = false;
          }
          setTimeout(() => {
            this.loading = false;
          }, 4000);
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  setForm() {
    try {
    (this.updateLessonForm.get('passage') as FormArray).push(this.createElementGroup());
    (this.updateLessonForm.get('passage') as FormArray).push(this.createElementGroup());
    this.lessonToEdit.passage?.forEach((elements, index) => {
        elements.passage_summary = elements?.summary ? elements?.summary : elements?.passage_summary || ''
        this.editorContent[index] = elements?.passage_summary
        this.passageIds.push(elements?.id)
        delete elements?.id
        delete elements?.summary
        delete elements?.created_at
        delete elements?.updated_at
        delete elements?.updated_by
        delete elements?.created_by
        delete elements?.is_deleted
    })

      this.updateLessonForm.patchValue({
        lesson_plan_name: this.lessonToEdit?.lesson_plan_name || "",
        summary: this.lessonToEdit?.summary || "",
        grade_id: this.lessonToEdit?.grade_id?.id || "",
        miro_url: this.lessonToEdit?.miro_url || "",
        lesson_plan_number: this.lessonToEdit?.lesson_plan_number || "",
        passage: this.lessonToEdit?.passage,
      });
    this.lessonToEdit?.passage?.forEach((elements, index) => {
      this.primaryImages[index] = elements?.primary_image_path
      this.secImages[index] = elements?.sec_image_path
      this.terImages[index] = elements?.ter_image_path
      this.quatImages[index] = elements?.quat_image_path
    })
  } catch (error) {
    console.log('ERROR: Error when setting form!')
  }
  }

  uploadImage(id: string) {
    document.getElementById(id).click();
  }

  async deleteImage(imageType: string, index: number): Promise<void> {
    const image = "image";
    this.sharedDialogservice
      .ConfirmAction({
        Title: "Delete image",
        Message: UtilityService.replaceText(
          ActionMessages.DELETE_CONFIRMATION,
          [{ text: "_name", data: image }]
        ),
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (subscribeRes) => {
        if (subscribeRes) {
          const formArray = this.updateLessonForm.get('passage') as FormArray;
          formArray.value[index][imageType] = ''
          if (imageType === this.primaryImageName) {
            delete this.primaryImages[index];
          }
          if (imageType === this.secondaryImageName) {
            delete this.secImages[index];
          }
          if (imageType === this.terImageName) {
            delete this.terImages[index];
          }
          if (imageType === this.quatImageName) {
            delete this.quatImages[index];
          }
      }
  })
}

onUpload(event: any, controlName: string, index: number): void {
  const file = event?.target?.files[0];
  if (file) {
    const isImage = file?.type?.startsWith('image/');
    const isMismatch = !isImage || !["image/png", "image/jpg", "image/jpeg"]?.includes(file.type);

  if (isMismatch) {
    this.toastrService.error(ErrorMessages.IMAGE_TYPE_MISMATCH);
    event.target.value = '';
  } else {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const readerResult = e.target.result;
      this.getPreviewImage(readerResult, file.size, controlName, index);
    };
    reader.readAsDataURL(file);
    event.target.value = '';
  }
  }
}
  imageTypeless(readerResult, imageType, index) {
    if (imageType === this.primaryImageName) {
      this.primaryImages[index] = readerResult;
    }
    if (imageType === this.secondaryImageName) {
      this.secImages[index] = readerResult;
    }
    if (imageType === this.terImageName) {
      this.terImages[index] = readerResult;
    }
    if (imageType === this.quatImageName) {
      this.quatImages[index] = readerResult;
    }
  }

getPreviewImage(readerResult: any, fileSize: number, imageType: string, index: number): void {
  const image = new Image();
  image.src = readerResult;
  image.onload = () => {
    if (fileSize > 5 * 1024 * 1024) { // 5MB limit
      this.toastrService.error(ErrorMessages.IMAGE_SIZE_EXCEEDED);
    } else {
      this.imageTypeless(readerResult, imageType, index);
    }
  };
  image.onerror = (error) => {
    console.error('Error: Image Loading Error', error);
  };
}

  replaceBase64String(imgURL: string) {
    return imgURL?.replace(/^data:image\/[a-z]+;base64,/, "")
  }

  async updateLesson(lesson) {
    const formData = this.updateLessonForm.value;
    formData.passage?.forEach((elements, index) => {
      elements.primary_image_path = this.replaceBase64String(this.primaryImages[index]);
      elements.sec_image_path = this.replaceBase64String(this.secImages[index]);
      elements.ter_image_path = this.replaceBase64String(this.terImages[index]);
      elements.quat_image_path = this.replaceBase64String(this.quatImages[index]);
    })
    this.loading = true;
    let formCompleted = true
    let passageSelected = true
    const passageArray = this.updateLessonForm.get('passage') as FormArray;
    const numberOfFields = passageArray.length;
    if (!this.editLesson) {
      this.createLessonDetails(lesson, formCompleted, passageSelected, numberOfFields)
    } else {
      this.saveLessonDetails(lesson, formCompleted);
    }
  }

  createLessonDetails(lesson, formCompleted, passageSelected, numberOfFields) {
  lesson.passage?.forEach((passageItems, index) => {
      if (!this.radioButtonItems.find((option) => option.selectedOption == this.showDropdown)) {
        if (this.updateLessonForm.invalid) {
          formCompleted = false
          this.loading = false;
        }
      } else if ((!this.passageDetails?.length && !this.passageDetails[index]?.passage_id) || 
            (!this.radioButtonItems.find((option) => option.selectedOption == this.hideDropdown) 
            && this.passageDetails?.length !== numberOfFields)) 
        {
          passageSelected = false
          this.loading = false;
      } else {
          const validateSummary = this.validateEditorContent(lesson?.summary)
          if (validateSummary) {
            const validatePassageSummary = this.validateEditorContent(passageItems?.passage_summary)
            if (!validatePassageSummary && this.radioButtonItems[index].selectedOption !== this.showDropdown) {formCompleted = false; this.loading = false;}
          } else {
            formCompleted = false
            this.loading = false;
          }
      }
    })
    this.uploadLessonDetails(lesson, formCompleted, passageSelected);
  }

  uploadLessonDetails(lesson, formCompleted, passageSelected) {
    if (formCompleted && passageSelected) {
      lesson.passage?.forEach((passages, index) => {
        passages.id = this.selectedPassageIds[index]
      })
      this.lessonsApiService
        .createLesson(lesson)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res) => {
            if (res?.statusCode === 200) {
              this.toastrService.success(SuccessMessages.CREATE_LESSON);
              this.navigateBack();
              this.loading = false;
            }
          },
          (error) => {
            if (error.status === 413) {
              this.toastrService.error(ErrorMessages.UNSUPPORTED_IMAGE_FOUND);
            } else {
              this.toastrService.error(error?.error?.ResponseMessage);
            }
            this.loading = false;
          }
        )
    } else if (!passageSelected) {
      this.toastrService.error(ErrorMessages.PASSAGE_NOT_SELECTED);
      this.loading = false
    } else {
      this.toastrService.error(ErrorMessages.FORM_INCOMPLETE);
      this.loading = false
    }
  }

  saveLessonDetails(lesson, formCompleted) {
  lesson.id = this.lessonId;
  if (this.updateLessonForm.valid) {
    lesson?.passage?.forEach((passages, index) => {
          const validateSummary = this.validateEditorContent(lesson?.summary)
          if (validateSummary) {
            const validatePassageSummary = this.validateEditorContent(passages?.passage_summary)
            if (!validatePassageSummary) {formCompleted = false; this.loading = false;}
          } else {
            formCompleted = false
            this.loading = false;
          }
      })
    } 
    this.uploadEditedLessonDetails(lesson, formCompleted);
  }

 uploadEditedLessonDetails(lesson, formCompleted) {
  if (this.updateLessonForm.valid && formCompleted) {
    lesson?.passage?.forEach((passages, index) => {
        passages.id = this.passageIds[index]
    })
    this.lessonsApiService
      .updateLesson(lesson)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res?.statusCode === 200) {
            this.toastrService.success(SuccessMessages.UPDATE_LESSON);
            this.navigateBack();
            this.loading = false;
          }
        },
        (errorRes) => {
          if (errorRes.status === 413) {
            this.toastrService.error(ErrorMessages.UNSUPPORTED_IMAGE_FOUND);
          } else {
            this.toastrService.error(errorRes?.error?.ResponseMessage);
          }
          this.loading = false;
        }
      );
  } else {
    this.toastrService.error(ErrorMessages.FORM_INCOMPLETE);
    this.loading = false;
  }
 }
  
  updateLessons(lesson) {
    try {
      lesson.id = this.lessonId;
      lesson.passage?.forEach((passages, index) => {
        passages.id = this.passageIds[index]
      })
      this.lessonsApiService
        .updateLesson(lesson)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res) => {
            if (res.statusCode === 200) {
              this.primaryImages = []
              this.secImages = []
              this.terImages = []
              this.quatImages = []
              const formArray = this.updateLessonForm.get('passage') as FormArray;
              formArray.clear(); 
              this.toastrService.success(
                SuccessMessages.UPDATE_LESSON_IMAGE
              );
              this.loading = false;
              this.getLesson();
            } else {
              this.toastrService.error(ErrorMessages.LESSON_DELETE);
            }
          },
          (error) => {
            this.toastrService.error(ErrorMessages.UNSUPPORTED_IMAGE_FOUND);
            this.loading = false;
          }
        );
      } catch (error) {
        console.group('Error deleting image')
      }
  }

  navigateBack() {
    this.loading = false;
    this.navigationService.navigateToPage([PathRouteConstants.LESSONS]);
  }

  validateNumber(e, length) {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode == 8
      )
    ) {
      return false;
    } else if (e.target.value.length == length) return false;
  }

  validateSpace(event: KeyboardEvent, controlName?: string): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const isSpace = event.key === ' ';

    if (isSpace) {
      const cursorPosition = inputElement.selectionStart;
      if (controlName === this.inputName) {
        if (cursorPosition === 0 || (value[cursorPosition - 1] === ' ' && cursorPosition > 0)) {
          event.preventDefault();
        } 
      } else if (cursorPosition === 0) {
          event.preventDefault();
    }
  }
  } 

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
