import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GradeConversionComponent } from "../modal-dialogs/grade-conversion/grade-conversion.component";
import { DeactivateStudentComponent } from "../modal-dialogs/deactivate-student/deactivate-student.component";
import { TutorBulkActionComponent } from "../modal-dialogs/tutor-bulk-action/tutor-bulk-action.component";
import { UpdateProgressAction } from "../modal-dialogs/update-progress/update-progress.component";
import { TutorEngagementAction } from "../modal-dialogs/tutor-engagement/tutor-engagement.component";
import { ActivateStudentComponent } from "../modal-dialogs/activate-student/activate-student.component";
import { AddNotesComponent } from "../modal-dialogs/add-notes/add-notes.component";
import { DeleteSchoolComponent } from "src/app/feature-modules/schools/delete-school/delete-school.component";
import { DeactivateTutorComponent } from "../modal-dialogs/deactivate-tutor/deactivate-tutor.component";
import { ConfirmActionComponent } from "../modal-dialogs/confirm-action/confirm-action.component";
import { DeleteFormsLinksComponent } from "src/app/feature-modules/forms/delete-forms-links/delete-forms-links.component";
import { DeleteAbsenceComponent } from "src/app/feature-modules/student-list/student-details/absence/delete-absence/delete-absence.component";
import { ImportStudentsComponent } from "src/app/feature-modules/student-list/import-students/import-students.component";
import { ImportScoresComponent } from "src/app/feature-modules/student-list/import-scores/import-scores.component";
import { CreateEditDistrictComponent } from "src/app/feature-modules/districts/create-edit-district/create-edit-district.component";
import { EditAbsenceComponent } from "src/app/feature-modules/student-list/student-details/absence/edit-absence/edit-absence.component";
import { ImportTutorComponent } from "src/app/feature-modules/tutor-list/import-tutor/import-tutor.component";

@Injectable()
export class SharedDialogService {
  constructor(private dialog: MatDialog) {}

  public ConfirmAction(data): Observable<any> {
    let dialogRef: MatDialogRef<ConfirmActionComponent>;
    dialogRef = this.dialog.open(ConfirmActionComponent, {
      disableClose: true,
    });
    dialogRef.componentInstance.dialogDataInstance = data;
    return dialogRef.afterClosed();
  }

  public GradeConversion(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<GradeConversionComponent>;
    dialogRef = this.dialog.open(GradeConversionComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public CreateUpdateDistrict(data): Observable<any> {
    let dialogRef: MatDialogRef<CreateEditDistrictComponent>;
    dialogRef = this.dialog.open(CreateEditDistrictComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = data;
    return dialogRef.afterClosed();
  }

  public DeactivateStudent(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<DeactivateStudentComponent>;
    dialogRef = this.dialog.open(DeactivateStudentComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }
  public DeactivateTutor(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<DeactivateTutorComponent>;
    dialogRef = this.dialog.open(DeactivateTutorComponent, {
      data: bulkActionData,
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }
  public TutorBulkAction(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<TutorBulkActionComponent>;
    dialogRef = this.dialog.open(TutorBulkActionComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public TutorEngagementAction(
  ): Observable<any> {
    let dialogRef: MatDialogRef<TutorEngagementAction>;
    dialogRef = this.dialog.open(TutorEngagementAction, {
      data: {
      },
    });
    return dialogRef.afterClosed();
  }


  public UpdateProgressAction(
  passages:any
  ): Observable<any> {
    let dialogRef: MatDialogRef<UpdateProgressAction>;
    dialogRef = this.dialog.open(UpdateProgressAction, {
      data: { passages },
    });
    dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      location.reload();
    });

    return dialogRef.afterClosed();
  }

  public AddNotes(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<AddNotesComponent>;
    dialogRef = this.dialog.open(AddNotesComponent, { disableClose: false });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public ActivateStudent(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<ActivateStudentComponent>;
    dialogRef = this.dialog.open(ActivateStudentComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public DeleteSchool(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<DeleteSchoolComponent>;
    dialogRef = this.dialog.open(DeleteSchoolComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }
  public ImportStudent(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<ImportStudentsComponent>;
    dialogRef = this.dialog.open(ImportStudentsComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }
  public ImportTutor(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<ImportTutorComponent>;
    dialogRef = this.dialog.open(ImportTutorComponent, { disableClose: false });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public CloseDialog() {
    return this.dialog.closeAll();
  }
  public UpdateAbsence(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<EditAbsenceComponent>;
    dialogRef = this.dialog.open(EditAbsenceComponent, { disableClose: false });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }
  public ImportScores(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<ImportScoresComponent>;
    dialogRef = this.dialog.open(ImportScoresComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public DeleteForm(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<DeleteFormsLinksComponent>;
    dialogRef = this.dialog.open(DeleteFormsLinksComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public DeleteAbsence(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<DeleteAbsenceComponent>;
    dialogRef = this.dialog.open(DeleteAbsenceComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }

  public ReactivateStudent(bulkActionData): Observable<any> {
    let dialogRef: MatDialogRef<ActivateStudentComponent>;
    dialogRef = this.dialog.open(ActivateStudentComponent, {
      disableClose: false,
    });
    dialogRef.componentInstance.dialogDataInstance = bulkActionData;
    return dialogRef.afterClosed();
  }
}
