import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorMessages } from "src/app/shared/constants/utility.constants";
import { School } from "src/app/shared/models/school";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentUtility } from "../../student-list/services/student-utility-services";

interface ImportTeacherModel {
  file?: File;
  school?: School[];
}
@Component({
  selector: "app-import-tutor",
  templateUrl: "./import-tutor.component.html",
  styleUrls: ["./import-tutor.component.css"],
})
export class ImportTutorComponent implements OnInit, OnDestroy {
  tutorForm: FormGroup;
  ngUnsubscribe = new Subject();
  dialogDataInstance: any;
  schoolList: School[];
  importPhase = 1;
  importTutorModal: ImportTeacherModel = {};
  tutorDetails: number;
  loading: boolean;
  IsFile: boolean = false;
  fileName: any;
  importSuccessful: boolean;
  schoolIds: number[] = [];
  schoolOptions: School[] = [];
  schoolControl: FormControl = new FormControl();
  selectedSchools: School[] = [];
  successReport: any;
  constructor(
    public dialogRef: MatDialogRef<ImportTutorComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {
    this.tutorForm = new FormGroup({
      schools: new FormControl("", [Validators.required]),
    });
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getSchools();
    this.setForm();
    this.getSchools();
    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }

  setForm() {
    this.tutorForm.patchValue({
      schools: this.schoolIds,
    });
  }

  getSchools() {
    this.loading = true;
    this.apiService
      .getSchoolList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.schoolList = res;
          this.schoolOptions = res;
          this.getDropDownValues();
          this.schoolList = res
            .filter((sch) => sch.school_name.replace(/\s/g, "") !== "")
            .sort((a, b) => {
              return a.school_name.localeCompare(b.school_name);
            });
        }
        this.loading = false;
      });
  }

  getDropDownValues() {
    this.loading = true;
    let commitments = this.apiService.getAllCommitments();
    let roles = this.apiService.getAllRoles();
    let preferences = this.apiService.getAllPreferences();

    forkJoin([commitments, roles, preferences]).subscribe((results) => {
      this.updateSelectedSchools(this.schoolIds);
      this.loading = false;
    });
  }

  schoolSelected(event) {
    let selectedValues = event.value;
    this.selectedSchools = [];
    this.updateSelectedSchools(selectedValues);
    this.importTutorModal.school =
      this.selectedSchools.length > 0 ? this.selectedSchools : null;
  }
  updateSelectedSchools(selectedValues) {
    selectedValues.forEach((element) => {
      this.selectedSchools.push(
        this.schoolList.find((ele) => ele.id == element)
      );
    });
  }
  schoolRemoved(id) {
    this.selectedSchools = this.selectedSchools.filter((sch) => sch.id !== id);
    this.schoolIds = this.schoolIds.filter((sch_id) => sch_id !== id);
  }

  uploadImage(image: string) {
    document.getElementById(image).click();
  }

  onUpload(event) {
    const file = event.target.files[0];
    this.importTutorModal.file = file;
    this.fileName = file.name;
    if (this.importTutorModal.file) {
      this.IsFile = true;
    } else {
      this.IsFile = false;
    }
  }

  resetForm() {
    this.importTutorModal = { file: null, school: null };
    this.fileName = "";
    this.importPhase = 1;
  }

  importTutors() {
    this.loading = true;
    this.apiService
      .importTutor(this.importTutorModal, this.selectedSchools)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.handleImportResponse(res);
        } else {
          this.importError();
        }
        this.loading = false;
      });
  }

  handleImportResponse(res) {
    this.successReport = res;
    let successfulImportCount: number = res.tutorsImported.imported.length;
    let successfulUpdatedCount: number = res.tutorsImported.updated.length;
    let successfulImportedNames: string = "";
    let successfulUpdatedNames: string = "";
    let errorMsg: string = "";
    this.tutorDetails =
      this.successReport.tutorsImported.imported.length ||
      this.successReport.tutorsImported.updated.length ||
      this.successReport.tutorsImported.failed.length;
    for (let importedTutors of res.tutorsImported.imported) {
      successfulImportedNames =
        successfulImportedNames +
        importedTutors.first_name +
        importedTutors.last_name +
        ",";
    }
    for (let updatedTutors of res.tutorsImported.updated) {
      successfulUpdatedNames =
        successfulUpdatedCount +
        updatedTutors.first_name +
        updatedTutors.last_name +
        ",";
    }

    for (let i = 0; i < 7; i++) {
      let failedTutorNames: string = "";
      if (res.validationConfig[i].count > 0) {
        for (let j = 0; j < res.validationConfig[i].count; j++) {
          failedTutorNames =
            failedTutorNames + res.validationConfig[i].tutors[j] + ",";
        }
        errorMsg =
          errorMsg +
          res.validationConfig[i].count.toString() +
          "tutor upload failed due to " +
          res.validationConfig[i].errorMsg +
          " Tutor names: " +
          failedTutorNames;
      }
    }
    let tempImportMsg: string =
      successfulImportCount.toString() + " tutors successfully imported";
    let tempUpdateMsg: string =
      successfulUpdatedCount.toString() + " tutors successfully updated";
    let tempErrorMsg: string =
      res.tutorsImported.failed.length.toString() +
      " tutors failed to import. Please fill all the required fields and also check for invalid input";

    if (successfulImportCount) {
      this.importPhase = 2;
      this.importSuccessful = true;
      this.toastrService.success(tempImportMsg);
    }
    if (successfulUpdatedCount) {
      this.importPhase = 2;
      this.importSuccessful = true;
      this.toastrService.success(tempUpdateMsg);
    }
    if (res.tutorsImported.failed.length) {
      this.toastrService.error(tempErrorMsg);
    }
  }
  importError() {
    this.importPhase = 2;
    this.importSuccessful = false;
    this.toastrService.error(ErrorMessages.IMPORT_STUDENT);
    this.loading = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
