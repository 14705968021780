import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppRouteConstants } from "src/app/shared/constants/app-route.constants";
import { District } from "src/app/shared/models/district";
import { ApiDataService } from "src/app/shared/services/api.service";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { UtilityService } from "src/app/shared/services/utility.service";
import { ListBase } from "src/app/shared/models/list-base";
import { States } from "src/app/shared/models/states";
import { NumberConstants } from "src/app/shared/constants/utility.constants";
import { AppUser } from "src/app/shared/models/app-user";
import { StudentConstants } from "../student-list/constants/student.constants";

@Component({
  selector: "app-districts",
  templateUrl: "./districts.component.html",
  styleUrls: ["./districts.component.css"],
})
export class DistrictsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  stateSelected = -1;
  districtList: District[];
  loading: boolean;
  basicLimit = NumberConstants.LIST_LIMIT;
  searchObj: ListBase = {
    search: "",
    offset: 0,
    limit: this.basicLimit,
    state: -1,
  };
  states: States[] = [];
  filteredListCount = { count: 0 };
  filteredDistrictList: District[] = [];
  districtId: number;
  filteredDistrictCount: number;
  loggedInUser: AppUser;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;

  constructor(
    private apiService: ApiDataService,
    private router: Router,
    private sharedDialogService: SharedDialogService
  ) {}

  ngOnInit() {
    this.getDistrictList();
    this.getStates();
    this.getUserInfo();
  }

  getDistrictList() {
    this.loading = true;
    this.apiService.getDistrictList(this.searchObj).subscribe((response) => {
      if (response) {
        if (response[1] !== this.filteredDistrictCount) {
          this.filteredDistrictList = [];
        }
        this.filteredDistrictList.push(...response[0]);
        this.filteredDistrictCount = response[1];
      }
      this.loading = false;
    });
  }
  getUserInfo() {
    this.loading = true;
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.loading = false;
      }
    });
  }
  getStates() {
    this.apiService.getAllStates().subscribe((response) => {
      if (response) {
        this.states = response;
        this.states = this.states.filter((data) => data.state_name != null);
        const obj = { id: -1, state_name: "All States" };
        this.states.push(obj);
        this.states.sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        });
      }
    });
  }
  onSearch() {
    this.filteredDistrictList = [];
    this.searchObj.offset = 0;
    this.getDistrictList();
  }

  onScroll() {
    this.searchObj.offset = this.searchObj.offset + this.basicLimit;
    this.getDistrictList();
  }

  onStateChange(event) {
    if (event.value) {
      this.filteredDistrictList = [];
      this.searchObj.offset = 0;
      this.searchObj.state = event.value;
      this.getDistrictList();
    }
  }

  openDialog(districtId) {
    this.sharedDialogService
      .CreateUpdateDistrict(districtId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.filteredDistrictList = [];
          this.searchObj.offset = 0;
          this.getDistrictList();
        }
      });
  }

  districtProfile(districtId) {
    this.router.navigate([
      "Features/" +
        UtilityService.replaceText(AppRouteConstants.DISTRICT_DETAIL, [
          { text: ":id", data: districtId },
        ]),
    ]);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
