import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PathRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { ErrorMessages, SuccessMessages } from 'src/app/shared/constants/utility.constants';
import { AppUser } from 'src/app/shared/models/app-user';
import { School } from 'src/app/shared/models/school';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { SharedDialogService } from 'src/app/shared/services/shared-dialog.service';
import { StudentConstants } from '../../student-list/constants/student.constants';

@Component({
  selector: 'app-school-details',
  templateUrl: './school-details.component.html',
  styleUrls: ['./school-details.component.css']
})
export class SchoolDetailsComponent implements OnInit {
  schoolId: number;
  school: School;
  loading: boolean;
  loggedInUser: AppUser;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiDataService, private sharedDialogservice: SharedDialogService, private router: Router,
    private toastrService: ToastrService) { }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.schoolId = +params.id;
      this.getSchool();
      this.getUserInfo();
    });
  }
  getUserInfo() {
    this.loading = true;
    this.apiService.getUserInfo().subscribe(res => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.loading = false;
      }
    })
  }
  getSchool() {
    this.loading = true;
    this.apiService.getSchool(this.schoolId).subscribe(res => {
      if (res.id) {
        this.school = res;
      }
      this.loading = false;
    })
  }
  deleteSchool(school) {
    this.sharedDialogservice.DeleteSchool({ school_name: school.school_name }).subscribe(res => {
      if (res) {
        this.apiService.deleteSchool(school.id, school).subscribe(response => {
          if (response) {
            this.toastrService.success(SuccessMessages.DELETE_SCHOOL);
            this.navigateToSchoolDirectory();
          }
          else {
            this.toastrService.error(ErrorMessages.SCHOOL_DELETE);
          }
        });
      }
    });
  }
  navigateToSchoolDirectory() {
    this.router.navigate([PathRouteConstants.SCHOOL_LIST]);
  }
}
