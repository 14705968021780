import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import { SchoolReportsComponent } from "./school-reports/school-reports.component";
import { StudentReportsComponent } from "./student-reports/student-reports.component";
import { TutorReportsComponent } from "./tutor-reports/tutor-reports.component";
import { DistrictReportsComponent } from "./district-reports/district-reports.component";

const routes: Routes = [
  {
    path: AppRouteConstants.DEFAULT,
    redirectTo: "/" + PathRouteConstants.SCHOOL_REPORTS,
    pathMatch: "full",
  },
  { path: AppRouteConstants.SCHOOL_REPORTS, component: SchoolReportsComponent },
  {
    path: AppRouteConstants.STUDENT_REPORTS,
    component: StudentReportsComponent,
  },
  { path: AppRouteConstants.TUTOR_REPORTS, component: TutorReportsComponent },
  {
    path: AppRouteConstants.DISTRICT_REPORTS,
    component: DistrictReportsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
