import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from "angularx-social-login";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  ErrorMessages,
  SuccessMessages,
} from "../shared/constants/utility.constants";
import { LoginBase } from "../shared/models/login-base";
import { ApiDataService } from "../shared/services/api.service";
import { AuthorizationService } from "../shared/services/authorization.service";
import { StorageService } from "../shared/services/storage.service";
import { TutorFilterService } from "../tutor-filter.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { AppRouteConstants } from "../shared/constants/app-route.constants";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginInfo: LoginBase = {};
  isSubmitted = false;
  loading: any;
  error: any;
  user: SocialUser;
  loggedIn: boolean;
  returnUrl: string;
  destroy$ = new Subject<any>();
  redirectURL: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private googleAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private authService: AuthorizationService,
    private toaster: ToastrService,
    private tutorFilterService: TutorFilterService,
    private apiService: ApiDataService
  ) {}

  ngOnInit() {
    localStorage.clear();
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
    //google login
    this.googleAuthService.authState
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        this.loggedIn = user != null;
      });
  }

  signInWithGoogle(): void {
    this.loading = true;
    this.googleAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((breakpoint) => {
        this.onGoogleLogin(breakpoint.email.trim());
      })
      .catch((err) => {
        console.log("error in sign in: ", err);
        this.loading = false;
      });
  }
  onGoogleLogin(email: string) {
    const data = {
      email: email,
    };
    let params = this.route.snapshot.queryParams;
    this.redirectURL = params["redirectURL"] ? params["redirectURL"] : "";
    this.authService
      .verifyLogedInUser(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.loading = true;
          if (result.token) {
            StorageService.setToken(result.token);
            StorageService.setUserId(result.userId);
            if (this.redirectURL === "/TutorUpdateProgress") {
              this.toaster.success(SuccessMessages.LOGIN);
              this.router.navigate(["TutorPage"]);
              this.loading = false;
            } else {
              this.toaster.success(SuccessMessages.LOGIN);
              this.router.navigateByUrl(this.redirectURL);
              this.loading = false;
            }
          }
        },
        (err) => {
          this.toaster.error(ErrorMessages.INVALID_CREDENTIALS);
          this.loading = false;
        }
      );
  }

  signOut(): void {
    this.googleAuthService.signOut();
  }

  refreshToken(): void {
    this.googleAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  checkResponse(res) {
    if (res) {
      localStorage.setItem("count", res.login_count)
      if (res.is_reset_password === 1 || res.login_count == 1) {
        this.router.navigate([AppRouteConstants.CHANGE_PASSWORD]);
        this.loading = false;
      } else {
        if (this.redirectURL === "/TutorUpdateProgress") {
          this.toaster.success(SuccessMessages.LOGIN);
          this.router.navigate(["TutorPage"]);
          this.loading = false;
        } else {
          this.toaster.success(SuccessMessages.LOGIN);
          this.router.navigateByUrl(this.redirectURL);
          this.loading = false;
        }
      }
    }
  }

  OnLogin() {
    let params = this.route.snapshot.queryParams;
    this.redirectURL = params["redirectURL"] ? params["redirectURL"] : "";
    this.loading = true;
    this.loginInfo.email = this.loginForm.get("email").value;
    this.loginInfo.password = this.loginForm.get("password").value;
    this.authService
      .login(this.loginInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.tutorFilterService.tutorId = result.userId;
          if (result.token) {
            StorageService.setToken(result.token);
            StorageService.setUserId(result.userId);
            this.apiService.getUserInfo().subscribe((res) => {
              this.checkResponse(res);
            });
          }
        },
        (err) => {
          if (err) {
            this.toaster.error(ErrorMessages.USER_NOT_FOUND);
          }
          this.loading = false;
        }
      );
  }

  getEnv(): string {
    const envt = environment.baseUrl;
    if (envt == "https://devapi.hugprodhost.com/") {
      return "Development Environment";
    } else if (envt == "http://localhost:3001/") {
      return "Local Environment";
    } else if (envt == "https://hugprodhost.com/") {
      return "";
    }
  }
}
