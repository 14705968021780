import { Component } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
})
export class ErrorComponent {
  constructor(public _location: Location) {}
}
