import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-forms-links',
  templateUrl: './delete-forms-links.component.html',
})
export class DeleteFormsLinksComponent implements OnInit {
  loading: boolean;
  public dialogDataInstance: any;
  constructor(public dialogRef: MatDialogRef<DeleteFormsLinksComponent>) { }

  ngOnInit(): void {
    this.loading = true;
  }

}

