import { Component } from "@angular/core";
import { NavigationService } from "src/app/shared/services/navigation.service";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import { Router } from "@angular/router";
import { AppUser } from "../../models/app-user";
import { ApiDataService } from "../../services/api.service";
import { StudentConstants } from "src/app/feature-modules/student-list/constants/student.constants";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent {
  route: string;
  pathRouteConstants = PathRouteConstants;
  loggedInUser: AppUser;
  loading: boolean;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;
  isTutor: boolean = false;

  constructor(
    private apiService: ApiDataService,
    private navigationService: NavigationService,
    public router: Router
  ) {
    this.getUserInfo();
  }
  getUserInfo() {
    this.loading = true;
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.isTutor = this.loggedInUser === this.tutorRoleId;
      }
      this.loading = false;
    });
  }
  navigateTo(tab) {
    if (tab === 1) {
      this.navigationService.navigateToPage([
        "Features/" + AppRouteConstants.STUDENT_LIST,
      ]);
    } else if (tab === 2) {
      this.navigationService.navigateToPage([
        "Features/" + AppRouteConstants.INACTIVE_STUDENT_LIST,
      ]);
    }
  }
}
