import { Injectable } from "@angular/core";

@Injectable()
export class StorageService {
  static getAppConfig() {
    return localStorage.getItem("APP_CONFIG")
      ? JSON.parse(localStorage.getItem("APP_CONFIG"))
      : 0;
  }

  static getToken() {
    return localStorage.getItem("token");
  }
  static clearToken() {
    localStorage.removeItem("token");
  }
  static setToken(token) {
    return localStorage.setItem("token", token);
  }
  static setUserId(userId) {
    localStorage.setItem("userId", userId);
  }
  static getUserId() {
    return localStorage.getItem("userId");
  }
  static clearUserId() {
    localStorage.removeItem("userId");
  }
}
