import { Pipe, PipeTransform } from "@angular/core";
import { Tutor } from "../models/tutor-base";

@Pipe({
  name: "volunteerFilter",
  pure: false,
})
export class VolunteerFilterPipe implements PipeTransform {
  transform(items: Tutor[], args: any[]): Tutor[] {
    const counter = args[1];
    const filter = args[0];
    let filteredList = items;
    if (!items || !filter) {
      return items;
    }
    const searchText = filter.SearchText.toLowerCase();
    if (searchText.length) {
      filteredList = items.filter(
        (item) =>
          (item.first_name + " " + item.last_name &&
            (item.first_name + " " + item.last_name)
              .toLowerCase()
              .indexOf(searchText) !== -1) ||
          (item.first_name &&
            item.first_name.toLowerCase().indexOf(searchText) !== -1) ||
          (item.last_name &&
            item.last_name.toLowerCase().indexOf(searchText) !== -1)
      );
    }
    if (counter) {
      counter.count = filteredList.length;
    }
    return filteredList;
  }
}
