import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary-dashboard',
  templateUrl: './primary-dashboard.component.html',
})
export class PrimaryDashboardComponent implements OnInit {
  loading:boolean
  ngOnInit(): void {
    this.loading = true
  }

}
