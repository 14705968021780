import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../../../shared/services/rest.service';
import { ApiUrlConstants } from '../../../shared/constants/api-url.constants';
import { UtilityService } from '../../../shared/services/utility.service';


@Injectable()
export class StudentsApiService extends RestService {
    constructor(injector: Injector) {
        super(injector);
    }

    getAbsences(studentId): Observable<any> {
        return this.get(UtilityService.replaceText(ApiUrlConstants.GET_ABSENCES, [{ text: '@studentId', data: studentId }]));
    }

    getSessionsByGrade(studentId): Observable<any> {
        return this.get(UtilityService.replaceText(ApiUrlConstants.GET_SESSIONS_BY_GRADE, [{ text: '@studentId', data: studentId }]));
    }

    getSessionsByLesson(studentId): Observable<any> {
        return this.get(UtilityService.replaceText(ApiUrlConstants.GET_SESSIONS_BY_LESSON, [{ text: '@studentId', data: studentId }]));
    }

    getAvailableLessons(studentId, gradeId): Observable<any> {
        return this.get(UtilityService.replaceText(ApiUrlConstants.GET_AVAILABLE_LESSONS, [{ text: '@studentId', data: studentId }, { text: '@gradeId', data: gradeId }]));
    }

    getScores(studentId): Observable<any> {
        return this.get(UtilityService.replaceText(ApiUrlConstants.GET_SCORES, [{ text: '@studentId', data: studentId }]));
    }

    // getAvailabs(studentId, gradeId): Observable<any> {
    //   return this.get(UtilityService.replaceText(ApiUrlConstants.GET_AVAILABLE_LESSONS,
    // [{ text: '@studentId', data: studentId }, { text: '@gradeId', data: gradeId }]));
    // }
}