import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonsRoutingModule } from './lessons.routes';
import { SharedModule } from 'src/app/shared/shared.module';
import { LessonListComponent } from './components/lesson-list/lesson-list.component';
import { LessonDetailsComponent } from './components/lesson-details/lesson-details.component';
import { UpdateLessonComponent } from './components/update-lesson/update-lesson.component';
import { LessonsApiService } from './services/lessons-api.service';
import { FormsModule } from "@angular/forms";
import { AngularEditorModule } from '@kolkov/angular-editor';
import {IvyCarouselModule} from 'angular-responsive-carousel';


@NgModule({
    declarations: [
        LessonListComponent,
        LessonDetailsComponent,
        UpdateLessonComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        LessonsRoutingModule,
        FormsModule,
        AngularEditorModule,
        IvyCarouselModule 
    ],
    providers: [
        LessonsApiService
    ],
    bootstrap: []
})
export class LessonModule { }
