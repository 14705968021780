import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  ActionMessages,
  ErrorMessages,
} from "../../constants/utility.constants";
import { BulkAction } from "../../models/bulk-action";
import { ApiDataService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { StudentConstants } from "src/app/feature-modules/student-list/constants/student.constants";

@Component({
  selector: "app-deactivate-student",
  templateUrl: "./deactivate-student.component.html",
})
export class DeactivateStudentComponent {
  public dialogDataInstance: any;
  inactiveStatus = StudentConstants.INACTIVE_STATUS;
  actionMessages = ActionMessages;
  bulkActionBody: BulkAction = {};
  loading: boolean;
  constructor(
    public dialogRef: MatDialogRef<DeactivateStudentComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {}

  confirmAction() {
    this.loading = true;
    if (this.dialogDataInstance.action === "Individual") {
      this.bulkActionBody.studentIds = [this.dialogDataInstance.studentList.id];
      this.bulkActionBody.id = 2;
      this.apiService
        .bulkActionStudents(this.bulkActionBody)
        .subscribe((res) => {
          this.resultActions(res);
        });
    } else {
      this.bulkActionBody.studentIds = this.dialogDataInstance.studentList.map(
        (student) => student.id
      );
      this.bulkActionBody.id =
        this.dialogDataInstance.action === "Deactivate" ? 2 : null;
      this.apiService
        .bulkActionStudents(this.bulkActionBody)
        .subscribe((res) => {
          this.resultActions(res);
        });
    }
  }

  resultActions(result) {
    if (result) {
      this.dialogRef.close(true);
    } else {
      this.toastrService.error(ErrorMessages.BULK_ACTION);
      this.dialogRef.close(false);
    }
    this.loading = false;
  }
}
