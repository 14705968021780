import { Component, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import {
  ActionMessages,
  NumberConstants,
  SuccessMessages,
  Titles,
} from "src/app/shared/constants/utility.constants";
import { AppUser } from "src/app/shared/models/app-user";
import { School } from "src/app/shared/models/school";
import { SearchBase } from "src/app/shared/models/search-base";
import { Student } from "src/app/shared/models/student-base";
import { StudentInfo } from "src/app/shared/models/studentinfo";
import { Tutor } from "src/app/shared/models/tutor-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SharedDataService } from "src/app/shared/services/shared-data.service";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { UtilityService } from "src/app/shared/services/utility.service";
import { StudenFilterService } from "src/app/studen-filter.service";
import { StudentConstants } from "./constants/student.constants";

@Component({
  selector: "app-student-list",
  templateUrl: "./student-list.component.html",
  styleUrls: ["./student-list.component.css"],
})
export class StudentListComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  levelSelected = this.studentFilterService.studentInfo.grade;
  selectedSchool = this.studentFilterService.studentInfo.school;
  schoolSelected: string;
  gradeSelected: string;
  studentList: Student[] = [];
  gradeList = StudentConstants.GRADES;
  bulkActions = StudentConstants.BULK_ACTIONS_ACTIVE;
  tutorBulkActions = StudentConstants.TUTOR_BULK_ACTIONS_ACTIVE;
  studentActions = StudentConstants.STUDENT_ACTIONS;
  schoolList: School[] = [];
  levelList: any;
  count: number;
  matTabLabels = StudentConstants.MAT_TAB_LABELS;
  loading: boolean;
  loadingList: boolean;
  selectedStudents: Student[] = [];
  searchObj: SearchBase = { SearchText: "", Status: "all" };
  filteredListCount = { count: 0 };
  entireListSelected: boolean;
  filteredStudentList: Student[] = [];
  tutorList: Tutor[] = [];
  selectedBulkAction: string;
  controlGroupStudents: Student[] = [];
  selectedIndex = 0;
  basicLimit = NumberConstants.LIST_LIMIT;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;
  loggedInUser: AppUser;
  id: number;
  studentInfo: StudentInfo;
  selectedLabel = 0;
  selectedStudentList = [];
  isTutor: boolean = false;
  constructor(
    private apiService: ApiDataService,
    private studentFilterService: StudenFilterService,
    private sharedDialogService: SharedDialogService,
    private toastrService: ToastrService,
    private sharedDataService: SharedDataService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    if (sessionStorage.getItem("tab")) {
      this.selectedLabel = parseInt(sessionStorage.getItem("tab"));
    }

    this.studentInfo = this.studentFilterService.studentInfo;
    this.studentInfo.isActive = 1;
    if (this.studentInfo.offset) {
      this.studentInfo.offset = 0;
    }
    this.getUserInfo();
    this.getSchools();
    this.getGradeLevels();

    this.sharedDataService.selectedTab = 0;
  }

  goToTutorUpdateProgress() {
    this.navigationService.navigateToPage([
      AppRouteConstants.TUTOR_UPDATE_PROGRESS,
    ]);
  }

  goToTutorMainPage() {
    this.navigationService.navigateToPage(["TutorPage"]);
  }
  maskLastName(fullName: string): string {
    const nameParts = fullName.split(" ");
    if (nameParts.length >= 2) {
      let lastName = nameParts[nameParts.length - 1];
      // Only show the first two letters of the last name
      const visibleLetters = Math.min(2, lastName.length);
      const maskedLastName = `${lastName.slice(0, visibleLetters)}${"*".repeat(
        Math.max(0, lastName.length - visibleLetters)
      )}`;
      nameParts[nameParts.length - 1] = maskedLastName;
      return nameParts.join(" ");
    } else {
      return fullName;
    }
  }

  getUserInfo() {
    this.loading = true;
    this.studentInfo.controlGroup = 0;
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.id = res.id;
        this.isTutor = this.loggedInUser === this.tutorRoleId;
      }
      if (this.isTutor) {
        this.getAssociatedStudentList();
      } else {
        this.getStudentList();
      }
    });
  }

  getStudentList(isRefreshRequired = false) {
    this.loading = true;
    this.apiService
      .getAllStudents(this.studentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        for (let value of res.student) {
          if (this.entireListSelected && !isRefreshRequired) {
            value.selected = true;
          }
          this.filteredStudentList.push(value);
        }
        this.selectedStudentList = this.filteredStudentList.filter(
          (students) => students.selected === true
        );
        if (res.count) {
          this.count = res.count;
        } else {
          this.count = this.filteredStudentList.length;
        }
        if (isRefreshRequired) {
          this.selectedStudents = [];
          this.entireListSelected = false;
        }
        UtilityService.sortArray(this.filteredStudentList);
        this.loading = false;
        this.loadingList = false;
      });
  }

  getAssociatedStudentList(isRefreshRequired = false) {
    this.loading = true;
    this.apiService
      .getAllAssociatedStudents(this.id, this.studentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        for (let value of res.student) {
          if (this.entireListSelected && !isRefreshRequired) {
            value.selected = true;
          }
          this.filteredStudentList.push(value);
        }
        this.selectedStudentList = this.filteredStudentList.filter(
          (student) => student.selected === true
        );
        if (res.count) {
          this.count = res.count;
        } else {
          this.count = this.filterAllStudents.length;
        }
        if (isRefreshRequired) {
          this.selectedStudents = [];
          this.entireListSelected = false;
        }
        UtilityService.sortArray(this.filteredStudentList);
        this.loading = false;
        this.loadingList = false;
      });
  }

  getSchools() {
    this.apiService.getSchoolList().subscribe((response) => {
      if (response) {
        this.schoolList = response;
        this.schoolList = this.schoolList.filter(
          (data) => data.school_name != null
        );

        this.schoolList = this.schoolList.sort((a, b) => {
          if (a.school_name < b.school_name) return -1;
          if (a.school_name > b.school_name) return 1;
          return 0;
        });
        this.selectedStudents = [];
      }
    });
  }

  getGradeLevels() {
    this.apiService.getAllGrades().subscribe((response) => {
      if (response) {
        this.levelList = response;
        this.levelList = this.levelList.filter(
          (data) => data.grade_level != null
        );
        const allLevel = { id: -1, grade_level: " All Grades" };
        this.levelList.push(allLevel);
        this.levelList = this.levelList.sort((a, b) => {
          if (a.grade_level < b.grade_level) return -1;
          if (a.grade_level > b.grade_level) return 1;
          return 0;
        });
        this.selectedStudents = [];
      }
    });
  }

  onSchoolChange(event) {
    if (event.value) {
      this.loadingList = true;
      this.filteredStudentList = [];
      this.studentInfo.offset = 0;
      this.studentInfo.school = event.value;
      this.studentFilterService.studentInfo.school = event.value;
      if (this.loggedInUser === this.tutorRoleId) {
        this.getAssociatedStudentList();
      } else {
        this.getStudentList();
      }
    }
  }

  onLevelChange(event) {
    if (event.value) {
      this.loadingList = true;
      this.filteredStudentList = [];
      this.selectedStudents = [];
      this.studentInfo.offset = 0;
      this.studentInfo.grade = event.value;
      this.studentFilterService.studentInfo.grade = event.value;
      if (this.loggedInUser === this.tutorRoleId) {
        this.getAssociatedStudentList();
      } else {
        this.getStudentList();
      }
    }
  }

  onScrollDown() {
    this.studentInfo.offset = this.studentInfo.offset + this.basicLimit;
    if (this.loggedInUser === this.tutorRoleId) {
      this.getAssociatedStudentList();
    } else {
      this.getStudentList();
    }
  }

  setListForTab(event) {
    this.loadingList = true;
    this.selectedLabel = event;
    sessionStorage.setItem("tab", event);
    this.selectedStudentList = [];
    this.selectedStudents = [];
    this.entireListSelected = false;
    this.studentInfo.controlGroup = event;
    this.studentInfo.offset = 0;
    this.filteredStudentList = [];
    if (this.loggedInUser === this.tutorRoleId) {
      this.getAssociatedStudentList();
    } else {
      this.getStudentList();
    }
  }

  onSearch() {
    this.loadingList = true;
    this.studentInfo.offset = 0;
    this.filteredStudentList = [];
    if (this.loggedInUser === this.tutorRoleId) {
      this.getAssociatedStudentList();
    } else {
      this.getStudentList();
    }
  }

  studentSelect(event, student) {
    if (event.target.checked) {
      student.selected = true;
      if (
        this.selectedStudents.findIndex(
          (selected) => selected.id === student.id
        ) === -1
      ) {
        this.selectedStudents.push(student);
      }
    } else {
      this.entireListSelected = false;
      const unCheckedStudentIndex = this.selectedStudents.findIndex(
        (stud) => stud.id === student.id
      );
      if (unCheckedStudentIndex !== -1) {
        this.selectedStudents[unCheckedStudentIndex].selected = false;
      }
    }
    this.selectedStudentList = this.selectedStudents.filter(
      (students) => students.selected === true
    );
    if (this.selectedStudentList.length === this.filteredStudentList.length) {
      this.entireListSelected = true;
    }
  }

  selectAllUser(event) {
    this.selectedStudents = [];
    this.entireListSelected = false;
    const checked = event.currentTarget ? event.currentTarget.checked : event;
    this.filteredStudentList.forEach((student) => {
      student.selected = checked;
    });
    if (checked) {
      this.entireListSelected = true;
      this.selectedStudents = this.filteredStudentList;
      this.selectedStudentList = this.filteredStudentList.filter(
        (student) => student.selected === true
      );
    }
  }

  onGradeChange(event) {
    this.gradeSelected = "";
    if (event.value !== StudentConstants.ALL_GRADES[0]) {
      this.gradeSelected = event.value.substring(0, 3);
    }
    this.filteredStudentList = this.filterAllStudents();
  }

  filterAllStudents() {
    let filteredStudents =
      this.selectedIndex === 0 ? this.studentList : this.controlGroupStudents;
    if (this.schoolSelected) {
      filteredStudents = filteredStudents.filter(
        (student) => student.school_name === this.schoolSelected
      );
    }
    if (this.gradeSelected) {
      filteredStudents = filteredStudents.filter(
        (student) => student.grade === this.gradeSelected
      );
    }
    return filteredStudents;
  }

  studentAction(event) {
    switch (event) {
      case this.studentActions[0]: {
        this.sharedDialogService
          .ImportStudent({
            action: this.selectedBulkAction,
            studentList: this.selectedStudents,
          })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res) {
              this.filteredStudentList = [];
              this.getStudentList();
            }
          });
        break;
      }
      case this.studentActions[1]: {
        this.navigationService.navigateToPage([
          PathRouteConstants.STUDENT_LIST +
            "/" +
            AppRouteConstants.STUDENT_CREATE,
        ]);
        break;
      }
      case this.studentActions[2]: {
        this.sharedDialogService
          .ImportScores({
            action: this.selectedBulkAction,
            studentList: this.selectedStudents,
          })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res) {
              this.filteredStudentList = [];
              this.getStudentList();
            }
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  studentProfile(studentId) {
    this.navigationService.navigateToPage([
      PathRouteConstants.STUDENT_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.STUDENT_DETAIL, [
          { text: ":id", data: studentId },
        ]),
    ]);
  }

  editStudent(studentId) {
    this.navigationService.navigateToPage([
      PathRouteConstants.STUDENT_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.STUDENT_UPDATE, [
          { text: ":id", data: studentId },
        ]),
    ]);
  }

  bulkAction() {
    switch (this.selectedBulkAction) {
      case this.bulkActions[0]:
      case this.bulkActions[1]: {
        this.sharedDialogService
          .GradeConversion({
            action: this.selectedBulkAction,
            studentList: this.selectedStudentList,
            totalStudentsCount: this.count,
          })
          .subscribe((res) => {
            this.bulkActionSuccessful(res);
          });
        break;
      }
      case this.bulkActions[2]: {
        this.sharedDialogService
          .DeactivateStudent({
            action: this.selectedBulkAction,
            title: Titles.DEACTIVATE_STUDENTS,
            message: ActionMessages.DEACTIVATE_STUDENTS,
            studentList: this.selectedStudentList,
            totalStudentsCount: this.count,
          })
          .subscribe((res) => {
            this.bulkActionSuccessful(res);
          });
        break;
      }
      case this.bulkActions[3]: {
        this.sharedDialogService
          .TutorBulkAction({
            action: this.selectedBulkAction,
            studentList: this.selectedStudentList,
            totalStudentsCount: this.count,
          })
          .subscribe((res) => {
            setTimeout(() => {
              if (res) {
                this.bulkActionSuccessful(res);
              }
            }, 2000);
          });
        break;
      }
      case this.bulkActions[4]: {
        this.sharedDialogService
          .AddNotes({
            action: this.selectedBulkAction,
            studentList: this.selectedStudentList,
            totalStudentsCount: this.count,
          })
          .subscribe((res) => {
            this.bulkActionSuccessful(res);
          });
        break;
      }
      default: {
        // statements;
        break;
      }
    }
  }

  bulkActionSuccessful(success) {
    if (success) {
      this.studentInfo.offset = 0;
      this.filteredStudentList = [];
      this.selectedBulkAction = "";
      this.loggedInUser === this.tutorRoleId
        ? this.getAssociatedStudentList(true)
        : this.getStudentList(true);
      this.toastrService.success(SuccessMessages.BULK_ACTION);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
