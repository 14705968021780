import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PathRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { District } from 'src/app/shared/models/district';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SharedDialogService } from 'src/app/shared/services/shared-dialog.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppUser } from 'src/app/shared/models/app-user';
import { StudentConstants } from '../../student-list/constants/student.constants';

@Component({
  selector: 'app-district-details',
  templateUrl: './district-details.component.html',
})
export class DistrictDetailsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  district: District;
  loading: boolean;
  reloadpage: any;
  districtId: number;
  loggedInUser: AppUser;
  tutorRoleId=StudentConstants.TUTOR_ROLE_ID;
  constructor(private apiService: ApiDataService, private route: ActivatedRoute, private toastrService: ToastrService,
    private sharedDialogService: SharedDialogService, private navigationService: NavigationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.districtId = +params.id;
        this.getUserInfo()
        this.getDistrict();
      });
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe(res => {
      if (res) {
        this.loggedInUser = res.role.id;
      }
    })
  }
  getDistrict() {
    this.loading = true;
    this.apiService.getDistrict(this.districtId).subscribe(res => {
      if (res) {
        this.district = res;
      }
      this.loading = false;
    });
  }

  openDialog(districtId) {
    this.sharedDialogService.CreateUpdateDistrict(districtId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res) {
          this.getDistrict();
        }
      });
  }

  navigateToList() {
    this.navigationService.navigateToPage([PathRouteConstants.DISTRICT_LIST]);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
