import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-update-progress",
  templateUrl: "./tutor-engagement.component.html",
  styleUrls: ["./tutor-engagement.component.css"],
})
export class TutorEngagementAction implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TutorEngagementAction>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

  confirmAction() {
    this.dialogRef.close();
  }

}
