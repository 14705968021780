import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordMatcher} from '../../validators/password-matcher';
import {AuthorizationService} from '../../shared/services/authorization.service';
import {ToastrService} from 'ngx-toastr';
import {AppRouteConstants} from '../../shared/constants/app-route.constants';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  newPasswordForm: FormGroup;
  submitted: boolean;
  hide: boolean;
  hideNewPassword: boolean;
  passwordStrength: number;
  loading: boolean;
  passwordCondition:boolean;
  showPasswordStrength:boolean;
  login_count:string;

  constructor(private auth: AuthorizationService, private toaster: ToastrService,private navigationService:NavigationService) { }

  ngOnInit(): void {
    this.login_count = localStorage.getItem('count')
    if(this.login_count == '1'){
    this.toaster.warning('Please change your account password to something unique and known only to you. This is an important step in maintaining the security of your account and the information you access.',
    null , {timeOut: 15000, closeButton: true} )}
    this.newPasswordForm = new FormGroup({
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    }, { validators: PasswordMatcher})
    
    this.passwordCondition=false;

    this.newPasswordForm.get('newPassword').valueChanges.subscribe(res =>{
      if(res){
        this.showPasswordStrength = true
      }
      else{
        this.showPasswordStrength = false
      }
    })

  
  }
  
  submit() {
    this.submitted = true;
    this.loading =true;
    const data = {
        password: this.newPasswordForm.get('confirmPassword').value
    }
    this.auth.changePassword(data).subscribe(res => {
      if (res) {
        this.toaster.success(res);
        this.loading = false;
        setTimeout(() => {
          this.auth.logout();
        }, 2000)
      }
    })
  }
  passwordConditions(){    
    if(this.passwordCondition===false){
      this.passwordCondition=true;
    }
 else{
  this.passwordCondition=false;
 }
  }
  
  navigateToLogin() {
    this.navigationService.navigateToPage([AppRouteConstants.LOGIN])
  }
  strengthCheck($event: number) {
    this.passwordStrength = $event;
  }
}
