import { AbstractControl, FormGroup } from "@angular/forms";

export function PasswordMatcher(group: FormGroup) {
  const password = group.controls.newPassword.value;
  const passwordConfirmation = group.controls.confirmPassword.value;
  return password === passwordConfirmation ? null : { passwordsNotEqual: true };
}
export function ValidateUrl(control: AbstractControl) {
  if (!control.value.startsWith("https") || !control.value.includes(".io")) {
    return { validUrl: true };
  }
  return null;
}
