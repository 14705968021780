import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PathRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { Forms } from 'src/app/shared/models/form';
import { SuccessMessages, REGEX } from 'src/app/shared/constants/utility.constants';
import { ToastrService } from 'ngx-toastr';
import { ListBase } from 'src/app/shared/models/list-base';
@Component({
  selector: 'app-create-edit-forms',
  templateUrl: './create-edit-forms.component.html',
  styleUrls: ['./create-edit-forms.component.css']
})
export class CreateEditFormsComponent implements OnInit {
  eventSignupId: number;
  eventSignupForm: FormGroup;
  loading: boolean;
  editEventSignup: boolean;
  eventSignupEdit: Forms;
  activeIcon = '';
  linkInfo: ListBase = { search: '', offset: 0, limit: 20 };
  count: number;

  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiDataService,
    private formBuilder: FormBuilder, private toaster: ToastrService) {
    this.eventSignupForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(/^(?=.*\S).+$/)]],
      description: ['', [Validators.required]],
      link: ['', [Validators.required, Validators.pattern(REGEX.LINK)]],
      sort_order: ['', Validators.required],
      icon: ['', Validators.required]
    });
  }
  ngOnInit() {
    this.loading = true;
    this.getAllEventSignups();
    this.route.params.subscribe(params => {
      this.eventSignupId = +params.id;
      if (this.eventSignupId) {
        this.editEventSignup = true;
        this.getEventSignup();
      }
    });
  }
  getAllEventSignups() {
    this.loading = true;
    this.apiService.getAppLinks(this.linkInfo).subscribe(eventSignups => {
      if (eventSignups[0].length > 0) {
        this.count = eventSignups[1];
        this.getNewAppLinkPosition(this.count);
      }
    });
  }
  getNewAppLinkPosition(appLinks) {
    this.eventSignupForm.controls.sort_order.setValue(appLinks + 1);
    this.loading = false;
  }

  getEventSignup() {
    this.apiService.getLink(this.eventSignupId).subscribe(res => {
      this.eventSignupEdit = res;
      this.setActiveIcon(this.eventSignupEdit.icon);
      this.setForm();
      this.loading = false;
    })

  }
  setForm() {
    this.loading = true;
    this.eventSignupForm = this.formBuilder.group({
      id: [this.eventSignupEdit.id],
      title: [this.eventSignupEdit.title, [Validators.required, Validators.pattern(/^(?=.*\S).+$/)]],
      description: [this.eventSignupEdit.description, [Validators.required]],
      link: [this.eventSignupEdit.link, [Validators.required, Validators.pattern(REGEX.LINK)]],
      sort_order: [this.eventSignupEdit.sort_order, Validators.required],
      icon: [this.eventSignupEdit.icon, Validators.required]
    });
    this.loading = false;
  }
  setActiveIcon(iconType: string) {
    this.eventSignupForm.controls.icon.setValue(iconType);
    this.activeIcon = iconType;
  }
  navigateToEventSignUp() {
    this.router.navigate([PathRouteConstants.FORMS]);
  }
  updateEventSignUp(eventSignup) {
    this.loading = true;
    if (!this.eventSignupId) {
      this.apiService.createLink(eventSignup).subscribe(res => {
        if (res) {
          this.toaster.success(SuccessMessages.CREATE_LINK);
          this.router.navigate([PathRouteConstants.FORMS]);
          this.loading = false;
        }
      }, err => {
        this.toaster.error(err?.error);
        this.loading = false;
      });
    } else {
      this.apiService.updateLink(eventSignup).subscribe(res => {
        if (res) {
          this.toaster.success(SuccessMessages.UPDATE_LINK);
          this.router.navigate([PathRouteConstants.FORMS]);
          this.loading = false;
        }
      }, err => {
        this.loading = false;
      }
      );
    }
  }
}