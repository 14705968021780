/*
 *Author Anju Krishnan
 */
import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "search",
  pure: false,
})
export class SearchPipe implements PipeTransform {
  test: any[];
  result: any[];
  filteredItems: any;
  scope = this;
  transform(
    items: any[],
    searchText: string,
    listOfFieldtoSeach: any[]
  ): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    if (searchText === "-1") {
      return items;
    }
    searchText = searchText.toLowerCase();
    const result = [];
    items.filter((it) => {
      if (listOfFieldtoSeach) {
        for (let searchword of listOfFieldtoSeach) {
          const fieldValue = searchword;
          let FieldValue = it[fieldValue];
          FieldValue = FieldValue.toString();
          FieldValue = FieldValue.toLowerCase();
          FieldValue = FieldValue.includes(searchText);
          if (FieldValue) {
            if (!result.some((item) => item.EndUserId === it.EndUserId)) {
              result.push(it);
            }
          }
        }
      } else {
        for (const key of it) {
          let FieldValue = it[key];
          FieldValue = FieldValue.toString();
          FieldValue = FieldValue.toLowerCase();
          FieldValue = FieldValue.includes(searchText);
          if (FieldValue) {
            if (!result.some((item) => item.EndUserId === it.EndUserId)) {
              result.push(it);
            }
          }
        }
      }
    });
    return result;
  }
}
