import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApiDataService } from "src/app/shared/services/api.service";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { AppRouteConstants } from "src/app/shared/constants/app-route.constants";
import { UtilityService } from "src/app/shared/services/utility.service";
import { Router } from "@angular/router";
import { Forms } from "src/app/shared/models/form";
import { ToastrService } from "ngx-toastr";
import {
  SuccessMessages,
  ErrorMessages,
  NumberConstants,
} from "src/app/shared/constants/utility.constants";
import { ListBase } from "src/app/shared/models/list-base";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppUser } from "src/app/shared/models/app-user";
import { StudentConstants } from "../student-list/constants/student.constants";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.css"],
})
export class FormsComponent implements OnInit, OnDestroy {
  loading: boolean;
  filteredListCount = { count: 0 };
  eventsSignUps: Forms[] = [];
  basicLimit = NumberConstants.LIST_LIMIT;
  linkInfo: ListBase = { search: "", offset: 0, limit: this.basicLimit };
  ngUnsubscribe = new Subject();
  count: number;
  loggedInUser: AppUser;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;

  constructor(
    private apiService: ApiDataService,
    private navigationService: NavigationService,
    private sharedDialogservice: SharedDialogService,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getAllLinks();
    this.getUserInfo();
  }
  getUserInfo() {
    this.loading = true;
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.loading = false;
      }
    });
  }
  getAllLinks() {
    this.apiService
      .getAppLinks(this.linkInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response) {
          this.eventsSignUps.push(...response[0]);
          this.count = response[1];
        }
        this.loading = false;
      });
  }
  onScroll() {
    this.linkInfo.offset = this.linkInfo.offset + this.basicLimit;
    this.getAllLinks();
  }
  onSearch() {
    this.eventsSignUps = [];
    this.linkInfo.offset = 0;
    this.getAllLinks();
  }

  detailEventSignup(id) {
    this.navigationService.navigateToPage([
      "Features/Events-SignUp/" +
        UtilityService.replaceText(AppRouteConstants.FORM_DETAILS, [
          { text: ":id", data: id },
        ]),
    ]);
  }

  updateEvent(id) {
    if (id) {
      this.navigationService.navigateToPage([
        "Features/Events-SignUp/" +
          UtilityService.replaceText(AppRouteConstants.UPDATE_FORM, [
            { text: ":id", data: id },
          ]),
      ]);
    } else {
      this.navigationService.navigateToPage([
        "Features/Events-SignUp/" + AppRouteConstants.CREATE_FORM,
      ]);
    }
  }

  deleteEvent(link) {
    this.sharedDialogservice
      .DeleteForm({ title: link.title })
      .subscribe((res) => {
        if (res) {
          this.apiService.deleteLink(link.id, link).subscribe((response) => {
            if (response) {
              this.toastrService.success(SuccessMessages.DELETE_LINK);
              this.eventsSignUps = this.eventsSignUps.filter(
                (l) => l.id !== link.id
              );
            } else {
              this.toastrService.error(ErrorMessages.SCHOOL_DELETE);
            }
          });
        }
      });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
