import { Injectable} from '@angular/core';
import {BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedDataService {
    private student = new BehaviorSubject<any>(0);

    editStudent = this.student.asObservable();
    selectedTab = 0;

    updateStudent(student) {
        this.student.next(student);
    }
    private district=new BehaviorSubject<any>(0);
    editDistrict=this.district.asObservable();
    updateDistrict(district){
        this.district.next(district);
    }
    
}