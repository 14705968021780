import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StudenFilterService } from "src/app/studen-filter.service";
import { TutorFilterService } from "src/app/tutor-filter.service";
import { AppRouteConstants } from "../../constants/app-route.constants";
import { NumberConstants } from "../../constants/utility.constants";
import { AppUser } from "../../models/app-user";
import { ApiDataService } from "../../services/api.service";
import { AuthorizationService } from "../../services/authorization.service";
import { NavigationService } from "../../services/navigation.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  NavigateToHome = false;
  currentUrl: string;
  loggedInUser: AppUser;
  showTooggleMenu = true;
  showChangePasswordMenu = false;
  constructor(
    public _authService: AuthorizationService,
    private apiService: ApiDataService,
    private router: Router,
    private studentFilterService: StudenFilterService,
    private tutorFilterService: TutorFilterService,
    private navigationService: NavigationService
  ) {
    this.getUserInfo();
  }
  ngOnInit() {
    this.currentUrl = this.router.url;
    this.showOrHideToggleMenu();
    this.hideChangePassword();
  }
  hideChangePassword() {
    this.showChangePasswordMenu =
      this.router.url == "/" + AppRouteConstants.TUTOR_UPDATE_PROGRESS
        ? false
        : true;
  }
  showOrHideToggleMenu() {
    if (
      this.router.url == "/" + AppRouteConstants.TUTOR_UPDATE_PROGRESS ||
      this.router.url == "/" + AppRouteConstants.TUTOR_PAGE ||
      this.showChangePasswordMenu
    ) {
      this.showTooggleMenu = false;
    }
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res;
      }
    });
  }
  togglemenu() {
    const element = document.getElementsByTagName("body");
    element[0].classList.toggle("has-sidebar");
  }
  changePassword() {
    this.router.navigate([AppRouteConstants.CHANGE_PASSWORD]);
  }

  // onLogoClick() {
  //   this.navigationService.navigateToPage([AppRouteConstants.TUTOR_PAGE]);
  // }
  onLogoClick() {
    const tempRoute = "/temporary-route"; // A temporary route
    this.navigationService.navigateToPageAsync([tempRoute]).then(() => {
      this.navigationService.navigateToPage([AppRouteConstants.TUTOR_PAGE]);
    });
  }

  goHome() {
    if (
      this.router.url == "/" + AppRouteConstants.TUTOR_UPDATE_PROGRESS ||
      this.router.url == "/" + AppRouteConstants.TUTOR_PAGE
    ) {
      this.router.navigate([AppRouteConstants.TUTOR_PAGE]);
    } else {
      this.router.navigate([AppRouteConstants.DEFAULT]);
    }
  }

  logOut() {
    this.studentFilterService.studentInfo = {
      school: -1,
      isActive: 1,
      grade: -1,
      searchTerm: "",
      limit: NumberConstants.LIST_LIMIT,
      offset: 0,
      controlGroup: 0,
    };
    this.tutorFilterService.tutorInfo = {
      school: -1,
      isActive: 1,
      commitmentFilter: -1,
      searchTerm: "",
      limit: NumberConstants.LIST_LIMIT,
      offset: 0,
    };
    if (
      this.router.url == "/" + AppRouteConstants.TUTOR_UPDATE_PROGRESS ||
      this.router.url == "/" + AppRouteConstants.TUTOR_PAGE
    ) {
      this._authService.logoutWithReturnUrl(
        "/" + AppRouteConstants.TUTOR_UPDATE_PROGRESS
      );
    } else {
      this._authService.logout();
    }
  }
}
