import { Component, OnInit,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { District } from 'src/app/shared/models/district';
import { School } from 'src/app/shared/models/school';
import { Tutor } from 'src/app/shared/models/tutor-base';
import { ReportsAPIService } from '../reports-api.service';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-tutor-reports',
  templateUrl: './tutor-reports.component.html',
  styleUrls: ['./tutor-reports.component.css']
})
export class TutorReportsComponent implements OnInit, AfterContentChecked {
  reportForm: FormGroup;
  loading: boolean;
  allDistricts: District[];
  selectedDistrict: District;
  availableSchools: School[] = [{ school_name: 'All Schools', id: 0 }];
  schoolYears = ['All Years'];
  tutorList: Tutor[] = [{ school_name: 'All Tutors', id: 0 }];
  filtersSelected: any;
  selectedReport: any;
  extraData: { data: any; description: string; }[];
  subTitleDonut: string;
  graphData: any;
  tutorCountTitle: string;
  selectedSchool: School;
  selectedYear: any;
  reportData: any;
  fullImageName: string;
  selectedTutor: Tutor;
  sessionTitle: string;
  sessionSubTitles: string[];
  tutorSchools: any;
  reportLoaded = false;
  constructor(private apiService: ApiDataService, private reportApiService: ReportsAPIService,
    private reportService: ReportsService,private cdr: ChangeDetectorRef ) {
    this.reportForm = new FormGroup({
      district: new FormControl('', Validators.required),
      school: new FormControl('', Validators.required),
      school_year: new FormControl('', Validators.required),
      tutor: new FormControl('', Validators.required)
    })
  }

  ngOnInit() {
    this.getAllDistricts();
  }
  ngAfterContentChecked(){
    this.cdr.detectChanges();
  }

  getAllDistricts() {
    this.loading = true;
    this.apiService.getAllDistricts().subscribe(res => {
      if (res.length > 0) {
        this.allDistricts = res;
        this.loading = false;
      }
    })
  }
  districtSelected(event) {
    this.loading = true;
    this.selectedDistrict = this.allDistricts.find(dis => dis.id === event.value);
    this.availableSchools = [{ school_name: 'All Schools', id: 0 }];
    this.schoolYears = ['All Years'];
    this.reportApiService.getSchoolsTutorsYearsByDistrict(event.value).subscribe(res => {
      if (res) {
        this.availableSchools.push(...res.schools);
        this.schoolYears.push(...res.schoolYears);
      }
      this.selectedSchool = { school_name: 'All Schools', id: 0 };
      this.reportForm.get('school').setValue(0);
      this.clearTutorAndYear();
      this.reportLoaded = false;
      this.loading = false;
    })
  }

  schoolSelected(event) {
    this.reportLoaded = false;
    this.selectedSchool = this.availableSchools.find(sch => sch.id === event.value);
    this.clearTutorAndYear();
    if (this.selectedSchool.id !== 0) {
      this.tutorList.push(...this.selectedSchool.app_users);
    }
  }

  tutorSelected(event) {
    this.reportLoaded = false;
    this.selectedTutor = this.tutorList.find(tutor => tutor.id === event.value);
    this.reportForm.get('school_year').setValue('All Years');
    this.selectedYear = 'All Years';
  }

  yearSelected() {
    this.reportLoaded = false;
    this.selectedYear = this.reportForm.get('school_year').value;
  }

  clearTutorAndYear() {
    this.tutorList = [{ tutor_name: 'All Tutors', id: 0 }];
    this.selectedTutor = { tutor_name: 'All Tutors', id: 0 };
    this.selectedYear = 'All Years';
    this.reportForm.get('school_year').setValue('All Years');
    this.reportForm.get('tutor').setValue(0);
  }

  renderReport(dataSelected) {
    this.loading = true;
    this.filtersSelected = dataSelected;
    this.reportApiService.getTutorReport(dataSelected).subscribe(report => {
      if (report) {
        this.reportLoaded = true;
        this.selectedReport = report;
        if (this.selectedReport) {
          this.parseDataForGraphs();
        }
      }
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  parseDataForGraphs() {
    this.parseTitles();
    const report = this.selectedReport;
    this.graphData = null;
    this.reportData = null;
    this.reportData = {
      data:
        [
          [{
            y: report.total_primary, name: 'Primary', color: '#1b3d6e', dataLabels: { enabled: false }
          },
          {
            y: report.total_substitute, name: 'Secondary', color: '#8e9eb5', dataLabels: { enabled: false }
          }],
          [{
            y: report.total_active, name: 'Active', color: '#00b2e2', dataLabels: { enabled: false }
          }, {
            y: report.total_inactive, name: 'Inactive', color: '#92dcf1', dataLabels: { enabled: false }
          }]
        ],
      sessions: {
        total_sessions: report.total_sessions,
        engagement_average: report.engagement_average,
        total_tutor_hours: report.total_tutor_hours,
        first_grade_sessions: report.first_grade_sessions,
        first_grade_session_hours: report.first_grade_session_hours,
        sec_grade_sessions: report.sec_grade_sessions,
        sec_grade_session_hours: report.sec_grade_session_hours,
        trd_grade_sessions: report.trd_grade_sessions,
        trd_grade_session_hours: report.trd_grade_session_hours,
        student_lists: report.student_lists
      },
      extraData: [
        { data: report.total_primary, description: 'Primary Tutors' },
        { data: report.total_substitute, description: 'Secondary Tutors' },
        { data: report.total_active, description: 'Active Tutors' },
      //  { data: report.total_inactive, description: 'Inactive Tutors' }
      ]
    };
    this.tutorSchools = report.student_lists;
  }

  parseTitles() {
    this.tutorCountTitle = `${this.selectedDistrict.district_name} - ${this.selectedSchool.school_name}<br>${this.selectedYear}<br>Tutor Count`;
    this.sessionTitle = `${this.selectedDistrict.district_name} - ${this.selectedSchool.school_name}`;
    this.sessionSubTitles = [this.selectedYear, 'Tutor Sessions and Engagement'];
    this.subTitleDonut = `<h1 style="text-align:center"><b>${this.selectedReport.total_tutors}</b></h1>Total Tutors`;
    this.fullImageName = `tutorreport_${this.selectedSchool.school_name === 'All Schools' ? 'allschools' : this.selectedSchool.school_name.replace(' ', '_')}`;

  }

  exportAllReports() {
    if (this.selectedReport) {
      this.reportService.takeSnapshot(document.getElementById('allCharts'), this.fullImageName);
    }
  }
  exportIndividualReport(graphIdentifier) {
    switch (graphIdentifier) {
      case 'count':
        this.reportService.takeSnapshot(document.getElementById('donutReport'),
          `${this.fullImageName}_${graphIdentifier}`);
        break;
      case 'sessions':
        this.reportService.takeSnapshot(document.getElementById('sessionReport'),
          `${this.fullImageName}_${graphIdentifier}`);
        break;
      case 'tutorsessions':
        this.reportService.takeSnapshot(document.getElementById('tutorSessionReport'),
          `${this.fullImageName}_${graphIdentifier}`)
    }
  }
}
