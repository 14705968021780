import { Component, Input, ElementRef, ViewChild, AfterViewInit, SimpleChanges, OnChanges, ChangeDetectorRef, OnInit } from '@angular/core';
import { Chart } from 'highcharts';
import { ChartOptions } from '../../report-models/chart';

@Component({
  selector: 'app-basic-horizontal-bar-chart',
  templateUrl: './basic-horizontal-bar-chart.component.html',
})
export class BasicHorizontalBarChartComponent implements AfterViewInit, OnChanges, OnInit {

  @Input() extraData: any;
  @Input() chartData: any;
  @Input() title: any;
  @Input() categories: any;
  @Input() legendData: any;
  @Input() tickMax = 50;
  @Input() stepSize = 10;
  defaultData: any = [[0,0,0], [0,0,0]] 

  @ViewChild('horizontalBarChart') horizontalBarChart: ElementRef;
  chart: Chart;
  options = ChartOptions.horizontalBarChartOptions;

  constructor(private cdr: ChangeDetectorRef){}
  ngOnInit(): void {
    console.log(this.extraData)
    this.renderChart();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.renderChart();
  }

  renderChart() {
    this.options = ChartOptions.horizontalBarChartOptions;
    if(!this.chartData){
      this.chartData = this.defaultData
    }  
    this.options.series = [{
      name: this.legendData[0], showInLegend: true, color: '#00b2e2',
      data: this.chartData[0] 
    },
    {
      name: this.legendData[1], showInLegend: true, color: '#1b3d6e',
      data: this.chartData[1] 
    }];
    this.options.xAxis.categories = this.categories;
    this.options.title.text = this.title;
    this.options.yAxis.max = this.tickMax;
    this.options.yAxis.tickInterval = this.stepSize;

    if (this.horizontalBarChart) {
      this.chart = new Chart(this.horizontalBarChart.nativeElement, this.options as any);
      this.chart.update({
        chart: {
          width: 486
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderChart();
  }

}
