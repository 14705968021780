import { Component, AfterViewInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ChartOptions } from '../../report-models/chart';
import { Chart } from 'highcharts';

@Component({
  selector: 'app-double-donut-chart',
  templateUrl: './double-donut-chart.component.html',
})
export class DoubleDonutChartComponent implements AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() chartData: any;
  @Input() subTitle: any;
  @Input() extraData: any;
  @Input() gain: any;
  @ViewChild('donutChart') donutChart: ElementRef;

  chart: Chart;
  options = ChartOptions.DonutChartOptions;

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {
    this.options = ChartOptions.DonutChartOptions;
    this.options.title.text = this.title;
    this.options.subtitle.text = this.subTitle;
    if (this.gain > 0) {
      // tslint:disable-next-line:object-literal-key-quotes
      this.options.subtitle.style = { 'color': 'green' };
    } else {
      // tslint:disable-next-line:object-literal-key-quotes
      this.options.subtitle.style = { 'color': 'red' };
    }
    this.options.series[0].data = this.chartData[0];
    this.options.series[1].data = this.chartData[1];
    if (this.donutChart) {
      this.chart = new Chart(this.donutChart.nativeElement, this.options as any);
      this.chart.update({
        chart: {
          width: 486
        }
      });
      this.chart = new Chart(this.donutChart.nativeElement, this.options as any);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderChart();
  }
}
