import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: '[strictNumber]'
})
export class StrictNumber {

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        var charCode = (event.which) ? event.which : event.keyCode
        if (charCode > 31
            && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
}