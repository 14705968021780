import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-increment-input",
  templateUrl: "./increment-input.component.html",
})
export class IncrementInputComponent {
  @Output() onSuggest: EventEmitter<any> = new EventEmitter();
  @Input() formGrp: FormGroup;
  @Input() inputName = "";

  _value: number = 0;
  _step: number = 1;
  _min: number = 0;
  _max: number = Infinity;
  _wrap: boolean = false;
  color: string = "default";
  disableButton = false;

  @Input("value")
  set inputValue(_value: number) {
    this._value = this.parseNumber(_value);
  }
  @Input()
  set enableField(enable: boolean) {
    this._value = 0;
    if (this.formGrp) {
      if (enable) {
        this.formGrp?.controls[this.inputName].enable();
        this.disableButton = false;
      } else {
        this.formGrp?.controls[this.inputName].disable();
        this.disableButton = true;
      }
    }
  }
  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  @Input() hideButtons: boolean;
  @Input()
  set min(_min: number) {
    this._min = this.parseNumber(_min);
  }

  @Input()
  set max(_max: number) {
    this._max = this.parseNumber(_max);
    if (this.formGrp) {
      if (this._max) {
        this.formGrp.controls[this.inputName].enable();
        this.disableButton = false;
      } else {
        this.formGrp.controls[this.inputName].disable();
        this.disableButton = true;
      }
    }
  }

  @Input()
  set wrap(_wrap: boolean) {
    this._wrap = this.parseBoolean(_wrap);
  }
  private parseNumber(num: any): number {
    return +num;
  }

  private parseBoolean(bool: any): boolean {
    return !!bool;
  }

  inputEntered() {
    if (this._value < this._min) {
      if (!this.hideButtons) {
        this._value = 0;
      }
    }
    this.onSuggest.emit(this._value);
  }

  incrementValue(step: number = 1): void {
    let inputValue = Number(this._value) + step;
    if (this._wrap) {
      inputValue = this.wrappedValue(inputValue);
    }
    this._value = inputValue;
    this.onSuggest.emit(this._value);
  }
  onKeyDown(event) {
    if (
      event.keyCode === 40 &&
      this.formGrp.controls[this.inputName].value <= 0
    ) {
      return false;
    }
  }
  private wrappedValue(inputValue): number {
    if (inputValue > this._max) {
      return this._min + inputValue - this._max;
    }
    if (inputValue < this._min) {
      if (this._max === Infinity) {
        return 0;
      }
      return this._max + inputValue;
    }

    return inputValue;
  }

  shouldDisableDecrement(inputValue: number): boolean {
    return !this._wrap && inputValue <= this._min;
  }

  shouldDisableIncrement(inputValue: number): boolean {
    return !this._wrap && inputValue >= this._max;
  }
}
