import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { SchoolsComponent } from './schools.component';
import { SchoolDetailsComponent } from './school-details/school-details.component';
import { CreateEditSchoolComponent } from './create-edit-school/create-edit-school.component';


const routes: Routes = [
    {
        path: AppRouteConstants.DEFAULT, component: SchoolsComponent, canActivate: [AuthGuardService]},
        { path: AppRouteConstants.SCHOOL_DETAILS, component: SchoolDetailsComponent },
        { path:AppRouteConstants.CREATE_SCHOOL, component: CreateEditSchoolComponent },
        { path:AppRouteConstants.UPDATE_SCHOOL, component: CreateEditSchoolComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SchoolsRoutingModule { }