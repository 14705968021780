import { SchoolFilterService } from './school-filter.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { School } from 'src/app/shared/models/school';
import { SharedDialogService } from 'src/app/shared/services/shared-dialog.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { AppRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { SuccessMessages, ErrorMessages, NumberConstants } from 'src/app/shared/constants/utility.constants';
import { SchoolBase } from 'src/app/shared/models/schoolbase';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppUser } from 'src/app/shared/models/app-user';
import { StudentConstants } from '../student-list/constants/student.constants';
import { TutorFilterService } from 'src/app/tutor-filter.service';
import { StudenFilterService } from 'src/app/studen-filter.service';


@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css']
})
export class SchoolsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  loading: boolean;
  schools: School[] = [];
  basicLimit = NumberConstants.LIST_LIMIT;
  schoolInfo: SchoolBase;
  count: number;
  school: School;
  filteredListCount: number;
  filteredSchool: School[] = [];
  loggedInUser: AppUser;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;


  constructor(private apiService: ApiDataService, private navigationService: NavigationService,
    private sharedDialogservice: SharedDialogService,
    private toastrService: ToastrService,
    private schoolFilterService: SchoolFilterService,
    private tutorFilterService: TutorFilterService,
    private studentFilterService: StudenFilterService
    ) { }

  ngOnInit() {
    this.loading = true;
    this.schoolInfo = this.schoolFilterService.schoolInfo;
    this.getSchools()
    this.getUserInfo()
  }

  getSchools() {
    this.loading = true;
    this.apiService.getSchools(this.schoolInfo).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response) {
        this.schools.push(...response[0]);
        this.count = response[1];
        this.loading = false;
      }
    });
  }
  getUserInfo() {
    this.loading = true;
    this.apiService.getUserInfo().subscribe(res => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.loading = false;
      }
    })
  }
  onScroll() {
    this.schoolInfo.offset = this.schoolInfo.offset + NumberConstants.LIST_LIMIT;
    this.getSchools();
  }
  onSearch() {
    this.loading = true;
    this.schools = [];
    this.schoolFilterService.schoolInfo.search = this.schoolInfo.search;
    this.schoolInfo.offset = 0;
    this.getSchools();
  }

  schoolDetails(id) {
    this.schoolInfo.offset = 0;
    this.navigationService.navigateToPage(['Features/SchoolDirectory/' + UtilityService.replaceText(AppRouteConstants.SCHOOL_DETAILS,
      [{ text: ':id', data: id }])]);
  }
  updateSchool(id) {
    this.schoolInfo.offset = 0;
    if (id) {
      this.navigationService.navigateToPage(['Features/SchoolDirectory/' + UtilityService.replaceText(AppRouteConstants.UPDATE_SCHOOL,
        [{ text: ':id', data: id }])]);
    } else {
      this.navigationService.navigateToPage(['Features/SchoolDirectory/' + AppRouteConstants.CREATE_SCHOOL])
    }
  }

  deleteSchool(school) {
    this.sharedDialogservice.DeleteSchool({ school_name: school.school_name }).subscribe(res => {
      if (res) {
        this.apiService.deleteSchool(school.id, school).subscribe(response => {
          if (response) {
            if(this.tutorFilterService.tutorInfo.school == school.id){
              this.tutorFilterService.tutorInfo.school = -1;
            }
            if(this.studentFilterService.studentInfo.school === school.id){
              this.studentFilterService.studentInfo.school = -1;
            }
            this.toastrService.success(SuccessMessages.DELETE_SCHOOL);
            this.schools = this.schools.filter(s => s.id !== school.id);
            this.count = this.schools.length;
          }
          else {
            this.toastrService.error(ErrorMessages.SCHOOL_DELETE);
          }
        });
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.schoolInfo.limit = 25;
    this.schoolInfo.offset = 0;
    this.schoolInfo.search = '';
  }
}