import { Pipe, PipeTransform } from '@angular/core';
import {Forms} from '../models/form';

@Pipe({
    name: 'formFilter',
    pure: false
})

export class FormFilterPipe implements PipeTransform {
    transform(items: Forms[], args: any[]): any[] {
        const counter = args[1];
        const filter = args[0];
        let filteredList = items;
        if (!items || !filter) {
            return items;
        }
        const searchText = filter.SearchText.toLowerCase();
        if (searchText.length) {
            filteredList = items.filter(form => (form.title.toLowerCase().indexOf(searchText) !== -1));
        }

        if (counter) {
            counter.count = filteredList.length;
        }
        return filteredList;
    }
}
