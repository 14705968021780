import { Injectable } from '@angular/core';

@Injectable()
export class ChartService {
    formAcumenData(acumenData) {
        return {
            subtitleAcumen: `<h1 style="text-align:center"><b>${Math.round(acumenData.second_attempt) - Math.round(acumenData.first_attempt)} %</b></h1>Average Gain`,
            acumenData: [[{
                y: Math.round(acumenData.first_attempt), name: 'Avg. 1st Attempt', color: '#1b3d6e',
                dataLabels: {
                    formatter() {
                        return this.y > 0 ? `${this.y} %` : null;
                    },
                    color: 'white', style: { fontSize: '12px' }, distance: -15
                }
            },
            {
                y: 100 - (Math.round(acumenData.first_attempt)), name: '', showInLegend: false, color: '#ffffff',
                dataLabels: { enabled: false }
            }],
            [{
                y: Math.round(acumenData.second_attempt), name: 'Avg. 2nd Attempt', color: '#00b2e2',
                dataLabels: {
                    formatter() {
                        return this.y > 0 ? `${this.y} %` : null;
                    },
                    color: 'white', style: { fontSize: '12px' }, distance: -20
                }
            },
            {
                y: 100 - (Math.round(acumenData.second_attempt)), name: '', showInLegend: false, color: '#ffffff',
                dataLabels: { enabled: false }
            }]]
        };
    }
}