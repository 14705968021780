import { Injectable } from "@angular/core";
import { TutorInfo } from "src/app/shared/models/tutor-info";
import { NumberConstants } from "./shared/constants/utility.constants";

@Injectable({
  providedIn: "root",
})
export class TutorFilterService {
  tutorInfo: TutorInfo = {
    school: -1,
    isActive: 1,
    commitmentFilter: -1,
    searchTerm: "",
    limit: NumberConstants.LIST_LIMIT,
    offset: 0,
  };
  tutorId: number;
}
