// Constants used

export class SuccessMessages {
  public static PASSWORD =
    "Password changed Successfully. Please check your corresponding email for the new temporary password.";
  //public static PASSWORD = 'Password changed Sunccessfully.We have sent an email to @email with new password.'
  public static LOGIN = "Login successful";
  public static BULK_ACTION = "Changes saved";
  public static CREATE_STUDENT = "Student added successfully";
  public static UPDATE_STUDENT = "Student updated successfully";
  public static DEACTIVATE_STUDENT = "Student deactivated successfully";
  public static DELETE_LESSON = "Lesson plan deleted successfully";
  public static CREATE_LESSON = "Lesson plan added successfully";
  public static UPDATE_LESSON = "Lesson plan updated successfully";
  public static UPDATE_LESSON_IMAGE = "Image deleted successfully";
  public static CREATE_DISTRICT = "District added successfully";
  public static UPDATE_DISTRICT = "District updated successfully";
  public static CREATE_TUTOR = "Tutor added successfully";
  public static UPDATE_TUTOR = "Tutor updated successfully";
  public static ASSIGN_TUTOR = "Assigned schools successfully";
  public static DELETE_SCHOOL = "School deleted successfully";
  public static CREATE_SCHOOL = "School added successfully";
  public static UPDATE_SCHOOL = "School updated successfully";
  public static ASSOCIATE_TUTORS = "Tutors associated successfully";
  public static REMOVE_TUTOR = "Tutor removed successfully";
  public static CHANGE_PRIMARY_TUTOR = "Lead Tutor changed successfully";
  public static DELETE_LINK = "Link deleted successfully";
  public static CREATE_LINK = "Link added successfully";
  public static UPDATE_LINK = "Link updated successfully";
  public static IMPORT_STUDENT = "Students imported successfully";
  public static UPDATE_IMPORT_STUDENT =
    "Imported students updated successfully";
  public static IMPORT_TUTOR = "Tutors imported successfully";
  public static IMPORT_SCORES = "Scores imported successfully";
  public static UPDATE_ABSENCE = "Absence updated successfully";
  public static DELETE_ABSENCE = "Absence deleted successfully";
  public static UPDATE_PROGRESS = "Progress updated successfully";
  public static EDIT_PROGRESS = "Progress edited successfully";
  public static UPDATE_SCORES = "Score updated successfully";
  public static REACTIVATE_STUDENT = "Student reactivated successfully";
}

export class ErrorMessages {
  public static ERROR = "Invalid email or password";
  public static USER_NOT_FOUND = "User not found";
  public static INVALID_CREDENTIALS = "Invalid credentials";
  public static BULK_ACTION = "Could not make changes";
  public static STUDENT_CREATE = "Could not create";
  public static STUDENT_UPDATE = "Could not update";
  public static STUDENT_EXISTS = "Student Already Exists";
  public static ASSIGN_TUTOR = "Error in Assigning schools";
  public static DISTRICT_UPDATE = "Could not update";
  public static LESSON_DELETE = "Could not delete";
  public static LESSON_UPDATE = "Could not update";
  public static RUN_REPORT = "Could not load report";
  public static CONTROL_REAL_DATA_MISMATCH =
    "Control data and real data should be the same length";
  public static SCHOOL_DELETE = "Could not delete";
  public static SCHOOL_CREATE = "Could not create";
  public static SCHOOL_UPDATE = "Could not update";
  public static LINK_DELETE = "Could not delete";
  public static LINK_CREATE = "Could not update";
  public static LINK_UPDATE = "Could not update";
  public static IMPORT_STUDENT = "Could not import students";
  public static IMPORT_SCORES = "Could not import scores";
  public static IMPORT_TUTORS = "Could not import following students:";
  public static ABSENCE_DELETE = "Unable to delete absence.";
  public static SOMETHING_WENT_WRONG = "Something went wrong!";
  public static PAGINATION = "Could not move to previous tab";
  public static STUDENT_NOT_FOUND = "Sorry, the student is not found";
  public static IMAGE_SIZE_EXCEEDED = "Image size cannot exceed 5MB";
  public static IMAGE_TYPE_MISMATCH = "Unsupported image format. Image type must match the specifications!";
  public static FORM_INCOMPLETE = "Please fill in all required fields";
  public static PASSAGE_NOT_SELECTED = "Please choose any passage";
  public static DUPLICATE_PASSAGE_SELECTED = "This passage is already selected. Please choose another one!";
  public static UNSUPPORTED_IMAGE_FOUND = "Sorry for the inconvenience. We ran into an unexpected error. Please ensure the uploaded image(s) & other datas are valid!";
}

export class InformationMessages {
  public static MAX_TUTOR_LIMIT =
    "You can only assign @num_tutors tutors for the corresponding school.";
}

export class ActionMessages {
  public static ADVANCE_GRADE = "The following students cannot be advanced:";
  public static ADVANCE_2ND_GRADE =
    "The following students will be advanced to 2nd grade:";
  public static ADVANCE_3RD_GRADE =
    "The following students will be advanced to 3rd grade:";
  public static ADVANCE_5TH_GRADE =
    "The following students will be graduated and made inactive:";
  public static REVERT_1ST_GRADE = "The following students cannot be reverted:";
  public static REVERT_2ND_GRADE =
    "The following students will be reverted to 1st grade:";
  public static REVERT_3RD_GRADE =
    "The following students will be reverted to 2nd grade:";
  public static REVERT_4TH_GRADE =
    "The following students will be reverted to 3rd grade:";
  public static REVERT_5TH_GRADE =
    "The following students will be reverted to 4th grade:";
  public static ASSIGN_TEACHER =
    "These students will be moved to the above teachers class:";
  public static ASSIGN_TUTOR =
    "These students will be assigned to the selected tutor:";
  public static CHANGE_ACCEPTANCE =
    "Are you sure you would like to make these changes?";
  public static MAKE_ACTIVE = "The following students will be made active:";
  public static ADD_NOTES =
    "The entered notes will be applied to these students:";
  public static DEACTIVATE_STUDENTS =
    "Why are these students being made inactive?";
  public static DEACTIVATE_STUDENT = "Why is _studName being made inactive?";
  public static ACTIVATE_STUDENTS =
    "The following students will be made active:";
  public static REACTIVATE_STUDENT =
    "Are you sure you want to reactivate _studName?";
  public static REMOVE_TUTOR_ASSOC =
    "Are you sure you would like to remove association of the student to this tutor?";
  public static CHANGE_PRIMARY_TUTOR =
    "Are you sure you want to change this tutor to primary position?";
  public static DELETE_CONFIRMATION = "Are you sure you want to delete _name?";
}

export class Titles {
  public static REMOVE_TUTOR_ASSOC = "REMOVE TUTOR ASSOCIATION";
  public static CHANGE_PRIMARY_TUTOR = "CHANGE PRIMARY TUTOR";
  public static MAKE_STUDENT_INACTIVE = "Make Student Inactive?";
  public static DEACTIVATE_STUDENTS = "Deactivate Students";
  public static REACTIVATE_STUDENTS = "Make Student active?";
  public static ACTIVATE_STUDENTS = "Activate Students";
}

export class REGEX {
  public static get PHONENO(): RegExp {
    return /((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/;
  }
  public static EMAIL =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static LINK = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
}

export class MASK {
  public static TEXT_MASK = "0000/0000";
}

export class NumberConstants {
  public static LIST_LIMIT = 25;
  public static LESSONS_LIMIT = 64;
}

export class LessonPassageConstants {
  public static HIDE_DROPDOWN = "hideDropdown";
  public static SHOW_DROPDOWN = "showDropdown";
}
