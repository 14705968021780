import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Lesson } from "src/app/feature-modules/lessons/models/lesson";
import { Reason } from "src/app/feature-modules/student-list/models/reason";
import { SuccessMessages } from "src/app/shared/constants/utility.constants";
import { Tutor } from "src/app/shared/models/tutor-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentUtility } from "../../../services/student-utility-services";
import { forkJoin } from "rxjs";
import { Absence } from "../../../models/absence";
import { StudentsApiService } from "../../../services/students-api.service";
import { LessonsApiService } from "src/app/feature-modules/lessons/services/lessons-api.service";

@Component({
  selector: "app-edit-absence",
  templateUrl: "./edit-absence.component.html",
})
export class EditAbsenceComponent implements OnInit {
  public dialogDataInstance: any;
  tutors: Tutor[] = [];
  tutorOptions: Tutor[] = [];
  lessons: Lesson[] = [];
  lessonOptions: Lesson[] = [];
  reasons: Reason[] = [];
  tutorControl: FormControl = new FormControl();
  lessonControl: FormControl = new FormControl();
  reasonControl: FormControl = new FormControl();
  absenceForm: FormGroup;
  loading: boolean;
  data: any;
  absence: Absence = {};
  showError = false;
  constructor(
    private apiService: ApiDataService,
    public dialogRef: MatDialogRef<EditAbsenceComponent>,
    private toastrService: ToastrService,
    private studentApiService: StudentsApiService,
    private lessonsApiService: LessonsApiService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.tutorControl.valueChanges.subscribe((value) => {
      this.tutorOptions = StudentUtility.filterOptions(this.tutors, value, [
        "first_name",
      ]);
    });
    this.lessonControl.valueChanges.subscribe((value) => {
      this.lessonOptions = StudentUtility.filterOptions(this.lessons, value, [
        "lesson_plan_name",
      ]);
      this.showError = false;
    });
    this.absence = this.dialogDataInstance.absence;
    this.initializeForm();
    this.getData();
  }
  checkForSelectedLesson() {
    this.showError =
      this.lessonOptions.findIndex(
        (option) => option.id === this.absenceForm.controls["lesson"].value
      ) === -1;
  }

  initializeForm() {
    this.absenceForm = new FormGroup({
      id: new FormControl(this.absence.id, [Validators.required]),
      student: new FormControl(this.absence.student_id, [Validators.required]),
      absence_date: new FormControl(this.absence.absence_date, [
        Validators.required,
      ]),
      volunteer: new FormControl(this.absence.volunteer_id, [
        Validators.required,
      ]),
      lesson: new FormControl(this.absence.lesson_id, [Validators.required]),
      reason_for_absence: new FormControl(this.absence.reason_id, [
        Validators.required,
      ]),
    });
  }

  getData() {
    this.loading = true;
    const body = {
      grade_id: this.dialogDataInstance.student.gradeIddd,
      search: "",
    };
    const lessonsRes = this.lessonsApiService.getLessonByGrade(body);
    const tutorsRes = this.apiService.getVolunteerNames();
    const reasonRes = this.apiService.getAllReason();
    forkJoin([lessonsRes, tutorsRes, reasonRes]).subscribe((joinedRes) => {
      if (joinedRes && joinedRes.length) {
        this.lessons = joinedRes[0] && joinedRes[0].length ? joinedRes[0] : [];
        this.lessonOptions = this.lessons;
        this.tutors = joinedRes[1] && joinedRes[1].length ? joinedRes[1] : [];
        this.tutorOptions = this.tutors;
        this.reasons = joinedRes[2] && joinedRes[1].length ? joinedRes[2] : [];
        this.loading = false;
        this.checkForSelectedLesson();
      }
    });
  }

  updateAbsence(absence) {
    this.loading = true;
    this.apiService.updateAbsence(absence).subscribe(
      (res) => {
        if (res.id > 0) {
          this.toastrService.success(SuccessMessages.UPDATE_ABSENCE);
          this.dialogRef.close(res);
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.dialogRef.close();
      }
    );
  }
}
