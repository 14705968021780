import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RestService } from "src/app/shared/services/rest.service";
import { Observable } from "rxjs";
import { ApiUrlConstants } from "src/app/shared/constants/api-url.constants";
import { UtilityService } from "src/app/shared/services/utility.service";

@Injectable()
export class ReportsAPIService extends RestService {
  // constructor to inject base url from local storage
  constructor(injector: Injector, private _http: HttpClient) {
    super(injector);
  }

  // function to set headers
  setHeaders() {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  getSchoolReport(form): Observable<any> {
    const { school, school_year } = form;
    const body = {
      school,
      school_year,
    };
    return this.postWithHeaders(
      ApiUrlConstants.SCHOOL_REPORT,
      body,
      this.setHeaders()
    );
  }
  // tslint:disable-next-line:variable-name
  getStudentsBySchoolAndYear(
    school: string,
    school_year: string
  ): Observable<any> {
    const body = {
      school,
      school_year,
    };
    return this.postWithHeaders(
      ApiUrlConstants.GET_STUDENTS_BY_SCHOOL_AND_YEAR,
      body,
      this.setHeaders()
    );
  }

  getStudentReport(student): Observable<any> {
    const body = {
      student,
    };
    return this.postWithHeaders(
      ApiUrlConstants.STUDENT_REPORT,
      body,
      this.setHeaders()
    );
  }

  getTutorReport(filtersSelected): Observable<any> {
    const body = {
      district: filtersSelected.district,
      school: filtersSelected.school,
      tutor: filtersSelected.tutor,
      school_year:
        filtersSelected.school_year === "All Years"
          ? 0
          : filtersSelected.school_year,
    };
    return this.postWithHeaders(
      ApiUrlConstants.TUTOR_REPORT,
      body,
      this.setHeaders()
    );
  }

  getDistrictReport(body: any): Observable<any> {
    return this.postWithHeaders(
      ApiUrlConstants.DISTRICT_REPORT,
      body,
      this.setHeaders()
    );
  }

  getSchoolsAndYearsByDistrict(districtId): Observable<any> {
    return this.getWithHeaders(
      UtilityService.replaceText(
        ApiUrlConstants.GET_SCHOOLS_YEARS_BY_DISTRICT,
        [{ text: "@districtId", data: districtId }]
      ),
      this.setHeaders()
    );
  }

  getSchoolsTutorsYearsByDistrict(districtId): Observable<any> {
    return this.getWithHeaders(
      UtilityService.replaceText(
        ApiUrlConstants.GET_SCHOOLS_YEARS_TUTORS_BY_DISTRICT,
        [{ text: "@districtId", data: districtId }]
      ),
      this.setHeaders()
    );
  }
}
