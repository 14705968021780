import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiUrlConstants } from '../constants/api-url.constants';
import { AppRouteConstants } from '../constants/app-route.constants';
import { MessageService } from './message.service';

@Injectable(
)
export class AuthorizationService {
  errorMessage: any;
  constructor(private _http: HttpClient, private route: ActivatedRoute, private router: Router, private messageService: MessageService) {
  }
  
  logoutWithReturnUrl(ReturnUrl) {
    localStorage.clear();
    this.router.navigate([AppRouteConstants.LOGIN], { queryParams: { 'redirectURL': ReturnUrl }});
  }
  logout() {
    localStorage.clear();
    this.router.navigate([AppRouteConstants.LOGIN]);
  }
  login(loginInfo): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set("Access-Control-Allow-Origin", "*");
    return this._http.post(environment.baseUrl + ApiUrlConstants.LOGIN_URL, loginInfo,
      { headers }).pipe(map(res => res),
        catchError(response => {
          this.messageService.error(response.error.ResponseMessage)
          return throwError(response && response.status ? response._body : response);
        }));
  }
  forgetpassword(userInfo): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(environment.baseUrl + ApiUrlConstants.FORGOT_PASSWORD, userInfo,
      { headers, responseType: 'text' }).pipe(map(res => res),
        catchError(response => {
          this.messageService.error(response.error.ResponseMessage)
          return throwError(response && response.status ? response : 'Something Went Wrong');
        }));
  }

  changePassword(data) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(environment.baseUrl + ApiUrlConstants.CHANGE_PASSWORD, data,
      { headers, responseType: 'text' }).pipe(map(res => res),
        catchError(response => {
          this.messageService.error(response.error.ResponseMessage)
          return throwError(response && response.status ? response : 'Something Went Wrong');
        }));
  }
  verifyLogedInUser(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(environment.baseUrl + ApiUrlConstants.VERIFY_USER, data,
      { headers }).pipe(map(res => res),
        catchError(response => {
          this.messageService.error(response.error.ResponseMessage)
          return throwError(response && response.status ? response : 'Something Went Wrong');
        }));
  }
}
