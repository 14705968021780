import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { SchoolsComponent } from './schools.component';
import { SchoolsRoutingModule } from './schools.routes';
import { SchoolDetailsComponent } from './school-details/school-details.component';
import { SchoolFilterPipe } from 'src/app/shared/pipes/school-filter.pipe';
import { DistrictFilterPipe } from 'src/app/shared/pipes/district-filter.pipe';
import { CreateEditSchoolComponent } from './create-edit-school/create-edit-school.component';
import { DeleteSchoolComponent } from './delete-school/delete-school.component';
import { TextMaskModule } from 'angular2-text-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
    declarations: [
        SchoolsComponent,
        SchoolDetailsComponent,
        SchoolFilterPipe,
        CreateEditSchoolComponent,
        DeleteSchoolComponent,
    ],
    imports: [
        TextMaskModule,
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        SchoolsRoutingModule,
        ToastrModule.forRoot(),InfiniteScrollModule,
    ],
    providers: [SchoolFilterPipe,DistrictFilterPipe],
    bootstrap: []
})
export class SchoolModule { }