import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppRouteConstants, PathRouteConstants } from "src/app/shared/constants/app-route.constants";
import { School } from "src/app/shared/models/school";
import { ApiDataService } from "src/app/shared/services/api.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { StudentUtility } from "../student-list/services/student-utility-services";
import { Router } from "@angular/router";
import { Tutor } from "src/app/shared/models/tutor-base";
import { AuthorizationService } from "src/app/shared/services/authorization.service";
import { ToastrService } from "ngx-toastr";
import {
  ErrorMessages,
  SuccessMessages,
} from "src/app/shared/constants/utility.constants";
@Component({
  selector: "app-tutor-page",
  templateUrl: "./tutor-page.component.html",
  styleUrls: ["./tutor-page.component.css"],
})
export class TutorPageComponent implements OnInit {
  showModal: boolean = false;
  currentSchoolsList: Array<string> = [];
  availableSchoolsList: Array<string> = [];
  loggedInUser: any;
  schoolIds: number[] = [];
  schoolControl: FormControl = new FormControl();
  schoolList: any;
  schoolOptions: School[] = [];
  selectedSchools: School[] = [];
  commitmentLevels: string[];
  roles: any;
  preferences: string[];
  loading = true;
  volunteerId: number;
  editTutor: boolean;
  tutorToEdit: Tutor = {};
  active: number;
  tutorForm: FormGroup;
  currentUrl: string;
  previousSelectedSchool: number[] = [];
  involvedSchoolsID: number[] = [];
  schoolsToUpdate: School[] = [];

  constructor(
    private toastrService: ToastrService,
    private navigationService: NavigationService,
    private apiService: ApiDataService,
    private router: Router,
    public _authService: AuthorizationService,
    private toastr: ToastrService
  ) {
    this.tutorForm = new FormGroup({
      schools: new FormControl("", [Validators.required]),
    });
  }

  goToTutorUpdateProgress() {
    this.navigationService.navigateToPage([
      AppRouteConstants.TUTOR_UPDATE_PROGRESS,
    ]);
  }

  goToStudentDirectory() {
    this.navigationService.navigateToPage([PathRouteConstants.STUDENT_LIST]);
  }

  showOrHideListModal() {
    this.loading = true;
    if (this.showModal === true) {
      this.schoolList = [];
      this.schoolOptions = [];
      this.selectedSchools = [];
      this.previousSelectedSchool = [];
      this.showModal = false;
      this.loading = false;
    } else {
      this.showModal = true;
      this.getVolunteer();
    }
  }

  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.volunteerId = res.id;
        this.loggedInUser = res;
        this.loading = false;
      }
    });
  }

  checkInvolved(id) {
    if (!this.involvedSchoolsID.includes(id)) {
      this.involvedSchoolsID.push(id);
    }
  }

  schoolSelected(event) {
    this.previousSelectedSchool.sort((a, b) => a - b);
    let selectedValues = event.value;
    selectedValues.sort((a, b) => a - b);
    let currentSchoolId = this.compareArrays(
      this.previousSelectedSchool,
      selectedValues
    );
    if (selectedValues.includes(currentSchoolId)) {
      this.updateAssociation(currentSchoolId, 1);
    } else {
      this.updateAssociation(currentSchoolId, -1);
    }
    this.checkInvolved(currentSchoolId);
    this.previousSelectedSchool = selectedValues;
    this.selectedSchools = [];
    this.newSchoolList(selectedValues);
  }

  updateAssociation(id, value) {
    for (let school of this.schoolList) {
      if (school.id === id) {
        school.associated_tutor_count += value;
        break;
      }
    }
  }

  compareArrays(arr1, arr2) {
    let missing = [];
    for (let element of arr1) {
      if (!arr2.includes(element)) {
        missing.push(element);
      }
    }

    for (let element of arr2) {
      if (!arr1.includes(element)) {
        missing.push(element);
      }
    }

    return missing[0];
  }

  newSchoolList(selectedValues) {
    selectedValues.forEach((element) => {
      this.selectedSchools.push(
        this.schoolList.find((ele) => ele.id == element)
      );
    });
    this.loading = false;
  }

  sortSchools(arr) {
    arr.sort(
      (a, b) =>
        b.tutor_limit -
        b.associated_tutor_count -
        (a.tutor_limit - a.associated_tutor_count)
    );
    return arr;
  }

  getSchools() {
    this.apiService.getSchoolList().subscribe((res) => {
      const newRes = this.sortSchools(res);
      this.schoolList = newRes;
      this.schoolOptions = newRes;
      this.newSchoolList(this.schoolIds);
    });
  }

  navigateToList(message) {
    this.toastrService.success(message);
    this.showOrHideListModal();
  }

  updateVolunteer(volunteer) {
    this.loading = true;
    this.involvedSchoolsID.forEach((id) => {
      const school = this.schoolList.find((s) => s.id === id);
      if (school) {
        this.schoolsToUpdate.push(school);
      }
    });
    this.apiService.updateVolunteer(this.volunteerId, volunteer).subscribe(
      (res) => {
        if (res == null) {
          this.apiService
            .updateTutorSlot(this.schoolsToUpdate)
            .subscribe((response) => {
              if (response == null) {
                this.schoolList = [];
                this.selectedSchools = [];
                this.previousSelectedSchool = [];
                this.toastr.success(SuccessMessages.ASSIGN_TUTOR);
                this.showOrHideListModal();
                this.loading = false;
              }
            });
        }
      },
      (err) => {
        this.toastr.error(ErrorMessages.ASSIGN_TUTOR);
        this.loading = false;
      }
    );
  }

  getVolunteer() {
    this.loading = true;
    this.apiService.getVolunteer(this.volunteerId).subscribe((res) => {
      if (res) {
        this.tutorToEdit = res.user;
        this.schoolIds = res.schoolIds;
        this.previousSelectedSchool = this.schoolIds;
        this.active = res.user.is_active;
        this.getSchools();
        this.setForm();
      }
    });
  }

  schoolRemoved(id) {
    this.selectedSchools = this.selectedSchools.filter((sch) => sch.id !== id);
    this.schoolIds = this.schoolIds.filter((sch_id) => sch_id !== id);
    this.previousSelectedSchool = this.previousSelectedSchool.filter(
      (x) => x !== id
    );
    this.checkInvolved(id);
    this.updateAssociation(id, -1);
  }

  setForm() {
    this.tutorForm.patchValue({
      schools: this.schoolIds,
    });
  }

  onLogout() {
    this._authService.logoutWithReturnUrl(this.currentUrl);
  }

  ngOnInit(): void {
    this.loading = true;
    this.currentUrl = this.router.url;
    this.getUserInfo();

    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }
}
