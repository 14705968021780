import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { District } from 'src/app/shared/models/district';
import { Student } from 'src/app/shared/models/student-base';
import { ReportsAPIService } from '../reports-api.service';
import { ReportsService } from '../reports.service';
import { ErrorMessages } from 'src/app/shared/constants/utility.constants';
import { ToastrService } from 'ngx-toastr';
import { ChartOptions } from '../report-models/chart';
import { ChartService } from '../charts/charts.service';

@Component({
  selector: 'app-student-reports',
  templateUrl: './student-reports.component.html',
  styleUrls: ['./student-reports.component.css']
})
export class StudentReportsComponent implements OnInit {
  reportForm: FormGroup;
  loading: boolean;
  loadingSchool: boolean;
  loadingYears: boolean;
  loadingStudents: boolean;
  allDistricts: District[];
  selectedDistrict: District;
  schools: any[];
  schoolYears: string[];
  selectedSchool: string;
  selectedYear: string;
  studentList: Student[];
  filtersSelected: any;
  selectedReport: any;
  studentSelected: any;
  currentGrade = 'fifth_grade';
  graphData: any;
  acumenData: any;
  titleDRA: string;
  titleRIT: string;
  titleRank: string;
  titleAcumen: string;
  fullImageName: string;
  subtitleAcumen: string;
  id: number;
  reportLoaded: boolean;
  averageGain: number;
  constructor(private apiService: ApiDataService, private reportAPIService: ReportsAPIService,
    private reportsService: ReportsService, private toastrService: ToastrService, private chartService: ChartService,
    private cdref: ChangeDetectorRef) {
    this.reportForm = new FormGroup({
      district: new FormControl('', Validators.required),
      school: new FormControl('', Validators.required),
      school_year: new FormControl('', Validators.required),
      student: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.getAllDistricts();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();   
  }

  getAllDistricts() {
    this.loading = true;
    this.apiService.getAllDistricts().subscribe(res => {
      if (res) {
        this.allDistricts = res;
        this.loading = false;
      }
    })
  }

  districtSelected(event) {
    this.loadingSchool = true;
    this.id = event.value;
    this.reportForm.get('school').setValue('');
    this.reportForm.get('school_year').setValue('');
    this.reportForm.get('student').setValue('');
    this.selectedSchool = ''
    this.reportLoaded = false;
    this.reportAPIService.getSchoolsAndYearsByDistrict(this.id).subscribe(res => {
      if (res) {
        this.schools = res.schools;
        this.schoolYears = res.schoolYears;
        this.studentList = [];

      }
      this.reportForm.get('school_year').disable();
      this.loadingSchool = false
    })
  }

  schoolSelected() {
    this.reportLoaded = false;
    this.loadingYears = true
    this.studentList = [];
    this.reportForm.get('school_year').enable();
    this.reportForm.get('school_year').setValue('');
    this.reportForm.get('student').setValue('');
    this.loadingYears = false

  }

  yearSelected() {
    this.loading = true;
    this.loadingStudents = true;
    this.reportLoaded = false;
    this.selectedSchool = this.reportForm.get('school').value
    this.selectedYear = this.reportForm.get('school_year').value;
    this.reportForm.get('student').setValue('');
    if (this.selectedSchool !== '' && this.selectedYear !== '') {
      this.loading = true;
      this.studentList = [];
      this.reportAPIService.getStudentsBySchoolAndYear(this.selectedSchool, this.selectedYear).subscribe(res => {
        if (res) {
          this.studentList = res;
          this.loadingStudents = false;
        }
      })
      this.loading = false;
    }
  }

  setListForTab(event) {
    switch (event) {
      case 0:
        this.currentGrade = 'fifth_grade';
        this.parseDataForGrade();
        break;
      case 1:
        this.currentGrade = 'second_grade';
        this.parseDataForGrade();
        break;
      case 2:
        this.currentGrade = 'third_grade';
        this.parseDataForGrade();
        break;
      // case 3:
      //   this.currentGrade = 'fifth_grade';
      //   this.parseDataForGrade();
      //   break;
    }
  }

  renderReport(dataSelected) {
    this.studentSelected = this.reportForm.get('student').value;
    this.filtersSelected = dataSelected;
    this.loading = true;
    this.reportAPIService.getStudentReport(this.studentSelected).subscribe(report => {
      if (report) {
        this.selectedReport = report;
        this.reportLoaded = true;
        if (this.selectedReport) {
          this.parseDataForGrade();
        }
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      this.toastrService.error(ErrorMessages.RUN_REPORT);
    }
    );
  }

  parseDataForGrade() {
    this.parseTitles();
    const currentGradeData = this.selectedReport[this.currentGrade];
    const benchMarkData = ChartOptions.BENCHMARK_DATA[this.currentGrade];
    this.graphData = null;
    this.graphData = {
      dra: {
        data: this.parseControlData([currentGradeData.dra.fall, currentGradeData.dra.winter, currentGradeData.dra.spring],
          [benchMarkData.dra.fall, benchMarkData.dra.winter, benchMarkData.dra.spring]),
        extraData: [{ data: currentGradeData.dra.mid_year_gain, description: 'Midyear Gain' },
        { data: currentGradeData.dra.end_year_gain, description: 'End of Year Gain' }]
      },
      rit: {
        data: this.parseControlData([currentGradeData.rit.fall, currentGradeData.rit.winter, currentGradeData.rit.spring],
          [benchMarkData.rit.fall, benchMarkData.rit.winter, benchMarkData.rit.spring]),
        extraData: [{ data: currentGradeData.rit.mid_year_gain, description: 'Midyear Gain' },
        { data: currentGradeData.rit.end_year_gain, description: 'End of Year Gain' }]
      },
      rank: {
        data: this.parseControlData([currentGradeData.rank.fall, currentGradeData.rank.winter, currentGradeData.rank.spring],
          [benchMarkData.rank.fall, benchMarkData.rank.winter, benchMarkData.rank.spring]),
        extraData: [{ data: currentGradeData.rank.mid_year_gain, description: 'Midyear Gain' },
        { data: currentGradeData.rank.end_year_gain, description: 'End of Year Gain' }]
      }
    }
    this.acumenData = this.chartService.formAcumenData(currentGradeData.acumen).acumenData;
    this.subtitleAcumen = this.chartService.formAcumenData(currentGradeData.acumen).subtitleAcumen;
    this.averageGain = Math.round(currentGradeData.acumen.second_attempt) - Math.round(currentGradeData.acumen.first_attempt)
    this.loading = false;
  }

  parseTitles() {
    const currentGradeTitle = this.currentGrade === 'fifth_grade' ? '1st Grade' : this.currentGrade === 'second_grade' ? '2nd Grade' : this.currentGrade === 'third_grade' ? '3rd Grade' : '5th Grade';
    const nameYearGradeCombined = `${this.selectedReport.name}<br>${this.filtersSelected.school_year}<br>${currentGradeTitle}`;
    this.titleDRA = `DRA Averages - ${nameYearGradeCombined}`;
    this.titleRIT = `RIT Averages - ${nameYearGradeCombined}`;
    this.titleRank = `Percentile Averages - ${nameYearGradeCombined}`;
    this.titleAcumen = `H.U.G Acumen - ${nameYearGradeCombined}`;
    this.fullImageName = `${this.selectedReport.name.replace(' ', '_')}_${this.filtersSelected.school_year.replace('/', '_')}_${currentGradeTitle}`;
  }

  parseControlData(data, controlData) {
    let finalControlData = [];

    if (data.length !== controlData.length) {
      this.toastrService.error(ErrorMessages.CONTROL_REAL_DATA_MISMATCH);
    }
    else finalControlData = controlData.map((ctrlData, i) => {
      if (data[i] === 0 || data[i] === null) return 0;
      else return ctrlData;
    });

    return [data, finalControlData];
  }

  exportAllReports() {
    if (this.selectedReport) {
      this.reportsService.takeSnapshot(document.getElementById('allGraphs'), this.fullImageName);
    } 

  }

  exportIndividualReport(graphIdentifier) {
    switch (graphIdentifier) {
      case 'DRA':
        this.reportsService.takeSnapshot(
          document.getElementById('draChart'),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
      case 'RIT':
        this.reportsService.takeSnapshot(
          document.getElementById('ritChart'),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
      case 'Rank':
        this.reportsService.takeSnapshot(
          document.getElementById('rankChart'),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
      case 'Acumen':
        this.reportsService.takeSnapshot(
          document.getElementById('acumenChart'),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
    }
  }

 
}
