import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  ErrorMessages,
  SuccessMessages,
} from "src/app/shared/constants/utility.constants";
import { ImportModel } from "src/app/shared/models/import";
import { ImportReportModel } from "src/app/shared/models/import-report";
import { School } from "src/app/shared/models/school";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentUtility } from "../services/student-utility-services";

@Component({
  selector: "app-import-scores",
  templateUrl: "./import-scores.component.html",
})
export class ImportScoresComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  dialogDataInstance: any;
  importPhase = 1;
  importScoresForm: FormGroup;
  importScoreModal: ImportModel = {};
  loading: boolean;
  IsFile: boolean = false;
  schoolList: School[];
  schoolOptions: School[];
  schoolControl: FormControl = new FormControl();
  successReport: ImportReportModel;
  fileName: string;
  importSuccessful: boolean;
  scoreDetails: any;
  constructor(
    public dialogRef: MatDialogRef<ImportScoresComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.importScoresForm = new FormGroup({
      fileInput: new FormControl(""),
      school: new FormControl(""),
    });
    this.getSchools();
    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }
  getSchools() {
    this.loading = true;
    this.apiService
      .getSchoolList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.schoolList = res;
          this.schoolOptions = res;
          this.schoolList = res
            .filter((sch) => sch.school_name.replace(/\s/g, "") !== "")
            .sort((a, b) => {
              return a.school_name.localeCompare(b.school_name);
            });
        }
        this.loading = false;
      });
  }

  uploadImage(image: string) {
    document.getElementById(image).click();
  }

  onUpload(event) {
    const file = event.target.files[0];
    this.importScoreModal.file = file;
    this.fileName = file.name;
    if (this.importScoreModal.file) {
      this.IsFile = true;
    } else {
      this.IsFile = false;
    }
  }

  schoolSelected(event) {
    this.importScoreModal.school = event.value;
  }

  resetForm() {
    this.importScoreModal = { file: null, school: null };
    this.fileName = "";
    this.importPhase = 1;
  }

  importScores() {
    this.loading = true;

    this.apiService
      .importScores(this.importScoreModal)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res) {
            this.successReport = res;
            this.importSuccessful = true;
            this.importPhase = 2;
            this.scoreDetails =
              this.successReport.imported.length ||
              this.successReport.failed.length ||
              this.successReport.updated.length;
            this.toastrService.success(SuccessMessages.IMPORT_SCORES);
          } else {
            this.importError();
          }
          this.loading = false;
        },
        (error) => {
          if (error.error.ResponseMessage === ErrorMessages.STUDENT_NOT_FOUND) {
            this.errorNotFound();
          } else {
            this.importError();
          }
        }
      );
  }

  importError() {
    this.importPhase = 2;
    this.importSuccessful = false;
    this.toastrService.error(ErrorMessages.IMPORT_SCORES);
    this.loading = false;
  }

  errorNotFound() {
    this.loading = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
