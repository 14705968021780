import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppRouteConstants } from '../constants/app-route.constants';

@Injectable()
export class NavigationService {

  constructor(private _router: Router) {
  }

  navigateToPage(route, params = {}) {
    this._router.navigate(route);
  }

  navigateToPageWithData(route, tab) {
    this._router.navigate(route, {state: {data: {id: tab}}});
  }

  navigateRelativeToParent(route, raltiveTo) {
    this._router.navigate(route, { relativeTo: raltiveTo });
  }

  navigateToUserPage() {
        this.navigateToPage([AppRouteConstants.STUDENT_LIST]);
  }

  navigateToLogin() {
     this.navigateToPage([AppRouteConstants.LOGIN]);
  }

}
