import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessageService {

  constructor(private toastr: ToastrService) { }

  success(msg: string, title?: string,config?: any): void {
    setTimeout(() => this.toastr.success(msg,title,config));
  }

  error(msg: string, config?: any): void {
    setTimeout(() => this.toastr.error(msg));
  }

  info(msg: string){
    this.toastr.info(msg)
  }

  warning(msg: string){
    this.toastr.warning(msg)
  }

}
