import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'H.U.G';
  constructor(private toaster: ToastrService){}
  ngOnInit(){
  this.toaster.toastrConfig.preventDuplicates = true;
  this.toaster.toastrConfig.timeOut = 1500;
  console.log('Dev deployment -', new Date().toLocaleDateString());  
  }  
}
