import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { AppRouteConstants } from "./shared/constants/app-route.constants";
import { PrimaryDashboardComponent } from "./feature-modules/primary-dashboard/primary-dashboard.component";
import { ErrorComponent } from "./shared/components/error/error.component";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { DistrictsComponent } from "./feature-modules/districts/districts.component";
import { DistrictDetailsComponent } from "./feature-modules/districts/district-details/district-details.component";
import { CreateEditTutorComponent } from "./feature-modules/tutor-list/create-edit-tutor/create-edit-tutor.component";
import { InactiveStudentListComponent } from "./feature-modules/student-list/inactive-student-list/inactive-student-list.component";
import { TutorDetailsComponent } from "./feature-modules/tutor-list/tutor-details/tutor-details.component";
import { StudentDetailsComponent } from "./feature-modules/student-list/student-details/student-details.component";
import { CreateEditStudentComponent } from "./feature-modules/student-list/create-edit-student/create-edit-student.component";
import { InactiveTutorListComponent } from "./feature-modules/tutor-list/inactive-tutor-list/inactive-tutor-list.component";
import { ChangePasswordComponent } from "./feature-modules/change-password/change-password.component";
import { TutorDashboardComponent } from "./feature-modules/tutor-dashboard/tutor-dashboard.component";
import { TutorDashboardAbsentComponent } from "./feature-modules/tutor-dashboard-absent/tutor-dashboard-absent.component";
import { TutorPageComponent } from "./feature-modules/tutor-page/tutor-page.component";
import { SubstituteTutorComponent } from "./feature-modules/substitute-tutor/substitute-tutor.component";

const routes: Routes = [
  { path: AppRouteConstants.LOGIN, component: LoginComponent },
  {
    path: AppRouteConstants.CHANGE_PASSWORD,
    component: ChangePasswordComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: AppRouteConstants.FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
  },
  {
    path: AppRouteConstants.UPDATE_PROGRESS,
    component: TutorDashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: AppRouteConstants.TUTOR_UPDATE_PROGRESS,
    component: TutorDashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "TutorPage",
    component: TutorPageComponent,
    canActivate: [AuthGuardService],
  },
  { path: "Tutor-absent", component: TutorDashboardAbsentComponent },
  {
    path: AppRouteConstants.DEFAULT,
    redirectTo: "/Features/StudentDirectory",
    pathMatch: "full",
  },
  {
    path: AppRouteConstants.PRIMARY_DASHBOARD,
    component: PrimaryDashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: AppRouteConstants.DEFAULT,
        redirectTo: "/Features/StudentDirectory",
        pathMatch: "full",
      },
      {
        path: "AssignSubstitute",
        component: SubstituteTutorComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.STUDENT_LIST,
        loadChildren: () =>
          import("./feature-modules/student-list/student.module").then(
            (s) => s.StudentModule
          ),
      },
      {
        path: AppRouteConstants.INACTIVE_STUDENT_LIST,
        component: InactiveStudentListComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.INACTIVE_STUDENT_DETAIL,
        component: StudentDetailsComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.INACTIVE_STUDENT_UPDATE,
        component: CreateEditStudentComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.DISTRICT_LIST,
        component: DistrictsComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.DISTRICT_DETAIL,
        component: DistrictDetailsComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.TUTOR_LIST,
        loadChildren: () =>
          import("./feature-modules/tutor-list/tutor.module").then(
            (t) => t.TutorModule
          ),
      },
      {
        path: AppRouteConstants.INACTIVE_TUTOR_LIST,
        component: InactiveTutorListComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.INACTIVE_TUTOR_DETAIL,
        component: TutorDetailsComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.INACTIVE_TUTOR_UPDATE,
        component: CreateEditTutorComponent,
        pathMatch: "full",
      },
      {
        path: AppRouteConstants.SCHOOLS_LIST,
        loadChildren: () =>
          import("./feature-modules/schools/schools.module").then(
            (s) => s.SchoolModule
          ),
      },
      {
        path: AppRouteConstants.LESSONS,
        loadChildren: () =>
          import("./feature-modules/lessons/lessons.module").then(
            (l) => l.LessonModule
          ),
      },
      {
        path: AppRouteConstants.REPORTS,
        loadChildren: () =>
          import("./feature-modules/reports/reports.module").then(
            (l) => l.ReportsModule
          ),
      },
      {
        path: AppRouteConstants.FORMS,
        loadChildren: () =>
          import("./feature-modules/forms/forms.module").then(
            (l) => l.FormLinkModule
          ),
      },
    ],
  },
  { path: AppRouteConstants.ANONYMOUS, component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
