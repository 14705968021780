import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsComponent } from './forms.component';
import { FormsRoutingModule } from './forms.routes';
import { FormFilterPipe } from 'src/app/shared/pipes/form-filter.pipe';
import { DeleteFormsLinksComponent } from './delete-forms-links/delete-forms-links.component';
import { CreateEditFormsComponent } from './create-edit-forms/create-edit-forms.component';
import { FormDetailsComponent } from './form-details/form-details.component';



@NgModule({
    declarations: [
        FormsComponent,
        FormFilterPipe,
        DeleteFormsLinksComponent,
        CreateEditFormsComponent,
        FormDetailsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        FormsRoutingModule,
        ToastrModule.forRoot()
    ],
    providers: [FormFilterPipe],
    bootstrap: []
})
export class FormLinkModule { }