import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { SchoolReport } from './report-models/school-report';
import { GradeReport } from './report-models/grade-report';
import * as _ from 'lodash';
import { StudentReport } from './report-models/student-report';
import { TutorReport } from './report-models/tutor-report';
import { DistrictReport } from './report-models/distict-report';

@Injectable()
export class ReportsService {
    formatSchoolReport(report) {
        const formattedReport: SchoolReport = {
            district: report.district ? report.district : '',
            school: report.school_name ? report.school_name : '',
            state: report.state ? report.state : '',
            school_year: report.school_year ? report.school_year : '',
            second_grade: report.second_g_averages ? this.formatGradeReport(report.second_g_averages,
                report.students[0].second_graders) : null,
            second_grade_control: report.second_g_control_averages ? this.formatGradeReport(report.second_g_control_averages) : null,
            third_grade: report.third_g_averages ? this.formatGradeReport(report.third_g_averages,
                report.students[1].third_graders) : null,
            third_grade_control: report.third_g_control_averages ? this.formatGradeReport(report.third_g_control_averages) : null,
            fifth_grade: report.fifth_g_averages ? this.formatGradeReport(report.fifth_g_averages,
                report.students[2].fifth_graders) : null,
            fifth_grade_control: report.fifth_g_control_averages ? this.formatGradeReport(report.fifth_g_control_averages) : null
        };
        return formattedReport;
    }

    formatStudentReport(report) {
        const formattedReport: StudentReport = {
            id: report.id ? report.id : 0,
            name: report.name ? report.name : '',
            grade: report.grade ? report.grade : '',
            second_grade: report.second_grade ? this.formatGradeReport({ ...report.second_grade, ...report.acumen }) : null,
            third_grade: report.third_grade ? this.formatGradeReport({ ...report.third_grade, ...report.acumen }) : null,
            fifth_grade: report.fifth_grade ? this.formatGradeReport({ ...report.fifth_grade, ...report.acumen }) : null,
        }
        return formattedReport;
    }

    formatTutorReport(report) {
        const formattedReport: TutorReport = {
            district_name: report.district_name ? report.district_name : '',
            school_name: report.school_name ? report.school_name : '',
            student_lists: report.student_lists ? report.student_lists : '',

            total_tutors: report.total_tutors ? report.total_tutors : 0,
            total_active: report.total_active ? report.total_active : 0,
            total_inactive: report.total_inactive ? report.total_inactive : 0,
            total_substitute: report.total_substitute ? report.total_substitute : 0,
            total_primary: report.total_primary ? report.total_primary : 0,
            total_tutor_hours: report.total_tutor_hours ? report.total_tutor_hours : 0,
            sec_grade_sessions: report.sec_grade_sessions ? report.sec_grade_sessions : 0,
            sec_grade_session_hours: report.sec_grade_session_hours ? report.sec_grade_session_hours : 0,
            trd_grade_sessions: report.trd_grade_sessions ? report.trd_grade_sessions : 0,
            trd_grade_session_hours: report.trd_grade_session_hours ? report.trd_grade_session_hours : 0,

            total_sessions: report.total_sessions ? report.total_sessions : 0,
            engagement_average: report.engagement_average ? report.engagement_average : 0
        }
        return formattedReport;
    }

    formatGradeReportDra(averages) {
        const gradeReport: GradeReport = {};
        let mid_year_gain_variable: number = 0;
        let end_year_gain_variable: number = 0;
        let fall_variable: number = 0;
        let winter_variable: number = 0;
        let spring_variable: number = 0;

        if (averages.mid_year_dra_gain_average) {
            mid_year_gain_variable = averages.mid_year_dra_gain_average
        } else if (averages.dra) {
            mid_year_gain_variable = averages.dra.mid_year_gain
        } 

        if (averages.end_year_dra_gain_average) {
            end_year_gain_variable = averages.end_year_dra_gain_average
        } else if (averages.dra) {
            end_year_gain_variable = averages.dra.end_year_gain
        } 
        
        if (averages.fall_dra_average) {
            fall_variable = averages.fall_dra_average
        } else if (averages.dra) {
            fall_variable = averages.dra.fall
        }

        if (averages.winter_dra_average) {
            winter_variable = averages.winter_dra_average
        } else if (averages.dra) {
            winter_variable = averages.dra.winter
        } 
        
        if (averages.spring_dra_average) {
            spring_variable = averages.spring_dra_average
        } else if (averages.dra) {
            spring_variable = averages.dra.spring
        } 

        gradeReport.dra = {
            mid_year_gain: mid_year_gain_variable,
            end_year_gain: end_year_gain_variable,
            fall: fall_variable,
            winter: winter_variable,
            spring: spring_variable
        }; 
        return gradeReport
    }
    formatGradeReportAcumen(averages) {
        const gradeReport: GradeReport = {};
        let first_attempt_variable: number = 0;
        let second_attempt_variable: number = 0;

        if (averages.hug_first_attempt_average) {
            first_attempt_variable = averages.hug_first_attempt_average
        } else if (averages.average_first_attempt) {
            first_attempt_variable = averages.average_first_attempt
        } 

        if (averages.hug_second_attempt_average) {
            second_attempt_variable = averages.hug_second_attempt_average
        } else if (averages.average_second_attempt) {
            second_attempt_variable = averages.average_second_attempt
        } 

        gradeReport.acumen = {
            gain_average: averages.hug_gain_average ? averages.hug_gain_average : 0,
            first_attempt: first_attempt_variable,
            second_attempt: second_attempt_variable
        };
        return gradeReport
    }
    formatGradeReportLexile(averages) {
        const gradeReport: GradeReport = {};
        gradeReport.lexile = {
            fall: averages.fall_lexile_average ? averages.fall_lexile_average : null,
            winter: averages.winter_lexile_average ? averages.winter_lexile_average : null,
            spring: averages.spring_lexile_average ? averages.spring_lexile_average : null
        };
        return gradeReport
    }
    formatGradeReportRit(averages) {
        const gradeReport: GradeReport = {};
        let mid_year_rit_gain_average_variable: number = 0;
        let end_year_rit_gain_average_variable: number = 0;
        let fall_rit_gain_average_variable: number = 0;
        let winter_rit_gain_average_variable: number = 0;
        let spring_rit_gain_average_variable: number = 0;

        if (averages.mid_year_rit_gain_average) {
            mid_year_rit_gain_average_variable = averages.mid_year_rit_gain_average
        } else if (averages.rit) {
            mid_year_rit_gain_average_variable = averages.rit.mid_year_gain
        } 

        if (averages.end_year_rit_gain_average) {
            end_year_rit_gain_average_variable = averages.end_year_rit_gain_average
        } else if (averages.rit) {
            end_year_rit_gain_average_variable = averages.rit.end_year_gain
        }
        
        if (averages.fall_rit_average) {
            fall_rit_gain_average_variable = averages.fall_rit_gain_average
        } else if (averages.rit) {
            fall_rit_gain_average_variable = averages.rit.fall
        }

        if (averages.winter_rit_average) {
            winter_rit_gain_average_variable = averages.winter_rit_average
        } else if (averages.rit) {
            winter_rit_gain_average_variable = averages.rit.winter
        } 

        if (averages.spring_rit_average) {
            spring_rit_gain_average_variable = averages.spring_rit_average
        } else if (averages.rit) {
            spring_rit_gain_average_variable = averages.rit.spring
        } 

        gradeReport.rit = {
            mid_year_gain: mid_year_rit_gain_average_variable,
            end_year_gain: end_year_rit_gain_average_variable,
            fall: fall_rit_gain_average_variable,
            winter: winter_rit_gain_average_variable,
            spring: spring_rit_gain_average_variable
        };
        return gradeReport
    }
    formatGradeReportRank(averages) {
        const gradeReport: GradeReport = {};
        let mid_year_rank_gain_average_variable: number = 0;
        let end_year_rank_gain_average_variable: number = 0;
        let fall_rank_gain_average_variable: number = 0;
        let winter_rank_gain_average_variable: number = 0;
        let spring_rank_gain_average_variable: number = 0;

        if (averages.mid_year_rank_gain_average) {
            mid_year_rank_gain_average_variable = averages.mid_year_rank_gain_average
        } else if (averages.rank) {
            mid_year_rank_gain_average_variable = averages.rank.mid_year_gain
        } 

        if (averages.end_year_rank_gain_average) {
            end_year_rank_gain_average_variable = averages.end_year_rank_gain_average
        } else if (averages.rank) {
            end_year_rank_gain_average_variable = averages.rank.end_year_gain
        }
        
        if (averages.fall_rank_average) {
            fall_rank_gain_average_variable = averages.end_year_rank_gain_average
        } else if (averages.rank) {
            fall_rank_gain_average_variable = averages.rank.fall
        }
        
        if ( averages.winter_rank_average) {
            winter_rank_gain_average_variable =  averages.winter_rank_average
        } else if (averages.rank) {
            winter_rank_gain_average_variable = averages.rank.winter
        } 

        if (averages.spring_rank_average) {
            spring_rank_gain_average_variable = averages.spring_rank_average
        } else if (averages.rank) {
            spring_rank_gain_average_variable = averages.rank.spring
        } 

        gradeReport.rank = {
            mid_year_gain: mid_year_rank_gain_average_variable,
            end_year_gain: end_year_rank_gain_average_variable,
            fall: fall_rank_gain_average_variable,
            winter: winter_rank_gain_average_variable,
            spring: spring_rank_gain_average_variable
        };
        return gradeReport
    }
    formatGradeReportStudent(averages, students) {
        const gradeReport: GradeReport = {};
        gradeReport.students = students ? students : [];
        gradeReport.school_year = averages.school_year ? averages.school_year : '';

        gradeReport.isBlank = _.sum(_.values({ ...gradeReport.dra }, { ...gradeReport.acumen },
            { ...gradeReport.rit }, { ...gradeReport.rank })) === 0;
        return gradeReport
    }

    formatGradeReport(averages, students?) {
        const gradeReport: GradeReport = {};
        this.formatGradeReportDra(averages);
        this.formatGradeReportAcumen(averages);
        this.formatGradeReportLexile(averages);
        this.formatGradeReportRit(averages);
        this.formatGradeReportRank(averages);
        this.formatGradeReportStudent(averages, students);
        return gradeReport;
    }

    takeSnapshot(node, filename) {
         html2canvas(node).then(canvas => {
            const data = canvas.toDataURL();
            const downloadLink = document.createElement('a');
            downloadLink.setAttribute('download', `${filename}.png`);
            downloadLink.setAttribute('href', data);
            downloadLink.click();
        }); 
    }

    formatDistrictReport(report) {
        const formattedReport: DistrictReport = {
            district: report.district ? report.district : '',
            state: report.state ? report.state : '',
            school_year: report.school_year ? report.school_year : '',
            num_of_schools: report.num_of_schools,
            num_second_graders: report.num_sec_graders,
            num_third_graders: report.num_trd_graders,
            second_grade: report.sec_g_averages ? this.formatGradeReportForDistrict(report.sec_g_averages) : null,
            third_grade: report.trd_g_averages ? this.formatGradeReportForDistrict(report.trd_g_averages) : null,
            fifth_grade: report.fth_g_averages ? this.formatGradeReportForDistrict(report.fth_g_averages) : null,
        };
        formattedReport.grade_list = [];
        if (formattedReport.second_grade) formattedReport.grade_list.push(formattedReport.second_grade);
        if (formattedReport.third_grade) formattedReport.grade_list.push(formattedReport.third_grade);
        if (formattedReport.fifth_grade) formattedReport.grade_list.push(formattedReport.fifth_grade);
        return formattedReport;
    }

    formatGradeReportForDistrict(averages) {
        const gradeReport: GradeReport = {};
        gradeReport.dra = {
            mid_year_gain: averages.mid_year_dra ? averages.mid_year_dra : 0,
            end_year_gain: averages.end_year_dra ? averages.end_year_dra : 0,
        };
        gradeReport.acumen = {
            gain_average: averages.hug_gain ? averages.hug_gain : 0,
        };
        gradeReport.rit = {
            mid_year_gain: averages.mid_year_rit ? averages.mid_year_rit : 0,
            end_year_gain: averages.end_year_rit ? averages.end_year_rit : 0,
        };
        gradeReport.rank = {
            mid_year_gain: averages.mid_year_rank ? averages.mid_year_rank : 0,
            end_year_gain: averages.end_year_rank ? averages.end_year_rank : 0,
        };
        gradeReport.isBlank = _.sum(_.values({ ...gradeReport.dra }, { ...gradeReport.acumen },
            { ...gradeReport.rit }, { ...gradeReport.rank })) === 0;
        return gradeReport;
    }
}
