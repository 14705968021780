import { Component, Input, ViewChild, ElementRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions } from '../../report-models/chart';
import { Chart } from 'highcharts';

@Component({
  selector: 'app-basic-column-chart',
  templateUrl: './basic-column-chart.component.html',
})
export class BasicColumnChartComponent implements AfterViewInit, OnChanges {
  @Input() extraData: any;
  @Input() chartData: any;
  @Input() title: any;
  @Input() categories: any;
  @Input() legendData: any;
  @Input() tickMax = 50;
  @Input() stepSize = 10;

  @ViewChild('doubleBarChart') doubleBarChart: ElementRef;
  chart: Chart;
  options = ChartOptions.barChartOptions;

  ngAfterViewInit() {
    this.renderChart();
  }

  renderChart() {
    this.options = ChartOptions.barChartOptions
    this.options.series = [{
      name: this.legendData[0], showInLegend: true, color: '#1b3d6e', type: 'column',
      data: this.chartData[0]
    },
    {
      name: this.legendData[1], showInLegend: true, color: '#00b2e2', type: 'column',
      data: this.chartData[1]
    }];
    this.options.xAxis.categories = this.categories;
    this.options.title.text = this.title;
    this.options.yAxis.max = this.tickMax;
    this.options.yAxis.tickInterval = this.stepSize;

    if (this.doubleBarChart) {
      this.chart = new Chart(this.doubleBarChart.nativeElement, this.options as any);
      this.chart.update({
        chart: {
          width: 486
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderChart();
  }

}
