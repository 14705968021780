import { Component, OnInit } from "@angular/core";
import { ApiDataService } from "../../services/api.service";
import { MatDialogRef } from "@angular/material/dialog";
import {
  ActionMessages,
  ErrorMessages,
} from "../../constants/utility.constants";
import { BulkAction } from "../../models/bulk-action";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-tutor-bulk-action",
  templateUrl: "./tutor-bulk-action.component.html",
})
export class TutorBulkActionComponent implements OnInit {
  public dialogDataInstance: any;
  actionMessages = ActionMessages;
  teacherName: string;
  bulkActionBody: BulkAction = {};
  loading: boolean = false;
  tutorBulkActionForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<TutorBulkActionComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.tutorBulkActionForm = new FormGroup({
      tutorName: new FormControl(""),
    });
  }

  confirmAction() {
    this.loading = true;
    this.bulkActionBody.id =
      this.dialogDataInstance.action === "Edit Teacher" ? 4 : null;
    this.bulkActionBody.studentIds = this.dialogDataInstance.studentList.map(
      (student) => student.id
    );
    this.bulkActionBody.teacher = this.teacherName;
    this.apiService.bulkActionStudents(this.bulkActionBody).subscribe((res) => {
      if (res) {
        this.dialogRef.close(true);
      } else {
        this.toastrService.error(ErrorMessages.BULK_ACTION);
        this.dialogRef.close(false);
      }
      this.loading = false;
    });
  }
  onCancel() {
    this.dialogRef.close();
    this.loading = false;
  }
}
