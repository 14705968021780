// Routes configured in the app
export class AppRouteConstants {
  public static DEFAULT = "";
  public static LOGIN = "Login";
  public static FORGOT_PASSWORD = "ForgotPassword";
  public static PRIMARY_DASHBOARD = "Features";
  public static CHANGE_PASSWORD = "Change-Password";
  public static STUDENT_LIST = "StudentDirectory";
  public static INACTIVE_STUDENT_LIST = "InactiveStudentDirectory";
  public static INACTIVE_STUDENT_DETAIL = "InactiveStudentDirectory/Detail/:id";
  public static INACTIVE_STUDENT_UPDATE = "InactiveStudentDirectory/Edit/:id";
  public static STUDENT_DETAIL = "Detail/:id";
  public static STUDENT_CREATE = "Create";
  public static STUDENT_UPDATE = "Edit/:id";
  public static EDIT_PROGRESS = "Edit-Progress/:id";
  public static EDIT_SCORES = "Edit-Scores/:id";
  public static UPDATE_PROGRESS = "UpdateProgress";
  public static TUTOR_UPDATE_PROGRESS = "TutorUpdateProgress";
  public static TUTOR_PAGE = "TutorPage";

  public static LESSONS = "Lessons";
  public static LESSON_DETAILS = "LessonDirectory/Detail/:id";
  public static LESSON_CREATE = "LessonDirectory/Create";
  public static LESSON_UPDATE = "LessonDirectory/Edit/:id";

  public static DISTRICT_LIST = "DistrictDirectory";
  public static DISTRICT_DETAIL = "DistrictDirectory/Detail/:id";
  public static DISTRICT_CREATE = "DistrictDirectory/Create";
  public static DISTRICT_UPDATE = "DistrictDirectory/Edit/:id";

  public static SCHOOLS_LIST = "SchoolDirectory";
  public static SCHOOL_DETAILS = "Detail/:id";
  public static CREATE_SCHOOL = "Create";
  public static UPDATE_SCHOOL = "Edit/:id";

  public static SUBSTITUTE_TUTOR = "AssignSubstitute";

  public static REPORTS = "Reports";
  public static SCHOOL_REPORTS = "Schools";
  public static STUDENT_REPORTS = "Students";
  public static TUTOR_REPORTS = "Tutors";
  public static DISTRICT_REPORTS = "Districts";

  public static FORMS = "Events-SignUp";
  public static CREATE_FORM = "Create";
  public static UPDATE_FORM = "Edit/:id";
  public static FORM_DETAILS = "Detail/:id";
  public static ANONYMOUS = "**";
  public static TUTOR_LIST = "TutorDirectory";
  public static CREATE_TUTOR = "Create";
  public static UPDATE_TUTOR = "Edit/:id";
  public static TUTOR_DETAILS = "Detail/:id";
  public static INACTIVE_TUTOR_LIST = "InactiveTutorDirectory";
  public static INACTIVE_TUTOR_DETAIL = "InactiveTutorDirectory/Detail/:id";
  public static INACTIVE_TUTOR_UPDATE = "InactiveTutorDirectory/Edit/:id";

  public static PROGRESS_UPDATE = "UpdateStudentProgress/:id/:lessonId/:grade";
  public static PROGRESS_UPDATE_WITH_SCHOOL_ID = "UpdateStudentProgress/:id/:lessonId/:grade/:schoolId";
  public static SCORES_UPDATE = "UpdateStudentScores/:id";
}
export class PathRouteConstants {
  public static FEATURES = "Features/";
  public static STUDENT_LIST = "Features/StudentDirectory";
  public static INACTIVE_STUDENT_LIST = "Features/InactiveStudentDirectory";
  public static LESSONS = "Features/Lessons/";
  public static DISTRICT_LIST = "Features/DistrictDirectory";
  public static SUBSTITUTE_TUTOR = "Features/AssignSubstitute";
  public static SCHOOL_LIST = "Features/SchoolDirectory";
  public static SCHOOL_REPORTS = "Features/Reports/Schools";
  public static TUTOR_LIST = "Features/TutorDirectory";
  public static FORMS = "Features/Events-SignUp";
  public static INACTIVE_TUTOR_LIST = "Features/InactiveTutorDirectory";
  public static UPDATE_PROGRESS = "Tutor";
}
