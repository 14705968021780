import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { FormsComponent } from './forms.component';
import { CreateEditFormsComponent } from './create-edit-forms/create-edit-forms.component';
import { FormDetailsComponent } from './form-details/form-details.component';




const routes: Routes = [
    {
        path: AppRouteConstants.DEFAULT, component: FormsComponent, canActivate: [AuthGuardService]},
        { path: AppRouteConstants.CREATE_FORM, component: CreateEditFormsComponent},
        { path: AppRouteConstants.UPDATE_FORM, component: CreateEditFormsComponent},
        { path: AppRouteConstants.FORM_DETAILS, component: FormDetailsComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FormsRoutingModule { }