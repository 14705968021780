import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TutorDetailsComponent } from "./tutor-details/tutor-details.component";
import { CreateEditTutorComponent } from "./create-edit-tutor/create-edit-tutor.component";
import { InactiveTutorListComponent } from "./inactive-tutor-list/inactive-tutor-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "src/app/shared/angular-material.module";
import { SharedModule } from "src/app/shared/shared.module";
import { TutorListComponent } from "./tutor-list.component";
import { TutorRoutingModule } from "./tutor.routes";
import { ImportTutorComponent } from "./import-tutor/import-tutor.component";

@NgModule({
  declarations: [
    TutorDetailsComponent,
    CreateEditTutorComponent,

    InactiveTutorListComponent,
    TutorListComponent,
    ImportTutorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    TutorRoutingModule,
  ],
  providers: [],
  bootstrap: [],
})
export class TutorModule {}
