import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirm-action",
  templateUrl: "./confirm-action.component.html",
})
export class ConfirmActionComponent {
  public dialogDataInstance: any;
  constructor(public dialogRef: MatDialogRef<ConfirmActionComponent>) {}
}
