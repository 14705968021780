import { Injectable } from "@angular/core";
import { NumberConstants } from "src/app/shared/constants/utility.constants";
import { SchoolBase } from "src/app/shared/models/schoolbase";

@Injectable({
  providedIn: "root",
})
export class SchoolFilterService {
  schoolInfo: SchoolBase = {
    search: "",
    offset: 0,
    limit: NumberConstants.LIST_LIMIT,
  };
}
