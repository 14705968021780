import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiDataService } from "src/app/shared/services/api.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { District } from "src/app/shared/models/district";
import { PathRouteConstants } from "src/app/shared/constants/app-route.constants";
import { SearchBase } from "src/app/shared/models/search-base";
import { School } from "src/app/shared/models/school";
import {
  SuccessMessages,
  ErrorMessages,
  REGEX,
  NumberConstants,
} from "src/app/shared/constants/utility.constants";
import { ToastrService } from "ngx-toastr";
import { States } from "src/app/shared/models/states";
import { ListBase } from "src/app/shared/models/list-base";
import { StudentUtility } from "../../student-list/services/student-utility-services";

@Component({
  selector: "app-create-edit-school",
  templateUrl: "./create-edit-school.component.html",
  styleUrls: ["./create-edit-school.component.css"],
})
export class CreateEditSchoolComponent implements OnInit {
  schoolId: number;
  schoolForm: FormGroup;
  loading: boolean;
  districtControl: FormControl = new FormControl();
  districts: District[] = [];
  filteredDistricts: District[];
  editSchool: boolean;
  schoolToEdit: School = {};
  states: States[] = [];
  schoolList: string[] = [];
  searchObj: SearchBase = { SearchText: "" };
  filteredListCount = { count: 0 };
  adoptedValue: boolean;
  adopted = [
    { display: "Yes", value: 1 },
    { display: "No", value: 0 },
  ];
  basicLimit = NumberConstants.LIST_LIMIT;
  searchObj1: ListBase = {
    search: "",
    offset: 0,
    limit: this.basicLimit,
    state: -1,
  };
  slotLimit: number = 20;
  minimumSlotValue: number;

  association: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiDataService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getDistrictDetails();
    this.getStates();
    this.route.params.subscribe((params) => {
      this.schoolId = +params.id;
      if (this.schoolId) {
        this.editSchool = true;
        this.getSchool();
      }
    });
    this.districtControl.valueChanges.subscribe((value) => {
      this.filteredDistricts = StudentUtility.filterOptions(
        this.districts,
        value,
        ["district_name"]
      );
    });
    // this.schoolForm.get('is_adopted').valueChanges.subscribe(condition => {
    //   if (condition === 1) {
    //     // this.schoolForm.controls['association_limit'].setValidators([Validators.required, Validators.max(1000), Validators.min(0)])
    //     this.schoolForm.controls['association_limit'].setValidators([Validators.required])

    //   } else {
    //     this.schoolForm.controls['association_limit'].clearValidators();
    //   }
    //   this.schoolForm.controls['association_limit'].updateValueAndValidity();
    // })
  }
  createForm() {
    this.schoolForm = this.formBuilder.group({
      uuid: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      school_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      school_address: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      school_city: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      stateId: new FormControl(null, Validators.required),
      school_zip: new FormControl(null, Validators.required),
      school_phone: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/
        ),
      ]),
      principal_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      vice_principal_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      clarity_person_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      clarity_person_email: new FormControl(null, [
        Validators.required,
        Validators.pattern(REGEX.EMAIL),
      ]),
      clarity_person_phone: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/
        ),
      ]),
      notes: new FormControl(null),
      districtId: new FormControl(null, Validators.required),
      is_adopted: new FormControl(null, Validators.required),
      association_limit: new FormControl(0, [
        Validators.pattern(/^(\d\d{0,2}|1000)$/),
      ]),
      slot_limit: new FormControl(20, [
        Validators.required,
        Validators.pattern(/^(\d\d{0,2}|1000)$/),
        Validators.min(this.minimumSlotValue),
      ]),
    });
  }
  change() {
    console.log(this.schoolForm);
  }
  getDistrictDetails() {
    this.loading = true;
    this.apiService.getAllDistricts().subscribe((response) => {
      if (response.length > 0) {
        this.districts = response;
        this.filteredDistricts = response;
        this.districts = this.districts.filter(
          (data) => data.district_name != null
        );
      }
      this.loading = false;
    });
  }
  getStates() {
    this.apiService.getAllStates().subscribe((response) => {
      if (response.length > 0) {
        this.states = response;
        this.states.sort(function (a, b) {
          if (a.state_name < b.state_name) {
            return -1;
          }
          if (a.state_name > b.state_name) {
            return 1;
          }
          return 0;
        });
      }
      this.loading = false;
    });
  }
  getSchool() {
    this.apiService.getSchool(this.schoolId).subscribe((res) => {
      if (res.id) {
        this.schoolToEdit = res;
        this.association = Boolean(this.schoolToEdit.is_adopted);
        console.log("adopate", typeof this.schoolToEdit.is_adopted);
        if (this.association === true) {
          this.adoptedValue = true;
          console.log("true state");
        } else {
          this.adoptedValue = false;
          console.log("false state");
        }
        this.minimumSlotValue = res.associated_tutor_count;
        this.createForm();
        this.setForm();
      }
    });
  }

  setForm() {
    this.schoolForm.get("uuid").patchValue(this.schoolToEdit.uuid);
    this.schoolForm
      .get("school_name")
      .patchValue(this.schoolToEdit.school_name);
    this.schoolForm
      .get("school_address")
      .patchValue(this.schoolToEdit.school_address);
    this.schoolForm
      .get("school_city")
      .patchValue(this.schoolToEdit.school_city);
    this.schoolForm.get("stateId").patchValue(this.schoolToEdit.state.id);
    this.schoolForm.get("school_zip").patchValue(this.schoolToEdit.school_zip);
    this.schoolForm
      .get("school_phone")
      .patchValue(this.schoolToEdit.school_phone);
    this.schoolForm
      .get("principal_name")
      .patchValue(this.schoolToEdit.principal_name);
    this.schoolForm
      .get("vice_principal_name")
      .patchValue(this.schoolToEdit.vice_principal_name);
    this.schoolForm
      .get("clarity_person_name")
      .patchValue(this.schoolToEdit.clarity_person_name);
    this.schoolForm
      .get("clarity_person_email")
      .patchValue(this.schoolToEdit.clarity_person_email);
    this.schoolForm
      .get("clarity_person_phone")
      .patchValue(this.schoolToEdit.clarity_person_phone);
    this.schoolForm.get("districtId").patchValue(this.schoolToEdit.district.id);
    this.schoolForm.get("notes").patchValue(this.schoolToEdit.notes);
    this.schoolForm.get("is_adopted").patchValue(this.schoolToEdit.is_adopted);
    this.schoolForm
      .get("association_limit")
      .patchValue(this.schoolToEdit.association_limit);
    this.schoolForm.get("slot_limit").patchValue(this.schoolToEdit.tutor_limit);
  }

  navigateToSchoolDirectory() {
    this.router.navigate([PathRouteConstants.SCHOOL_LIST]);
  }
  get f() {
    return this.schoolForm.controls;
  }

  updateSchool(school: School) {
    this.loading = true;
    if (!school.association_limit) {
      school.association_limit = 0;
    }
    if (!this.schoolId) {
      this.apiService.createSchool(school).subscribe(
        (res) => {
          if (res.id) {
            this.toastr.success(SuccessMessages.CREATE_SCHOOL);
            this.router.navigate([PathRouteConstants.SCHOOL_LIST]);
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    } else {
      school.id = this.schoolToEdit.id;
      this.apiService.updateSchool(school).subscribe(
        (res) => {
          if (res) {
            this.toastr.success(SuccessMessages.UPDATE_SCHOOL);
            this.router.navigate([PathRouteConstants.SCHOOL_LIST]);
          }
        },
        (err) => {
          this.toastr.error(ErrorMessages.SCHOOL_UPDATE);
          this.loading = false;
        }
      );
    }
  }
  checkForNumberValidation(event) {
    if (event && event.charCode && event.charCode === 46) {
      return false;
    }
  }
  isadopted(event) {
    this.adoptedValue = event.value;
    if (event.value === 0) {
      this.schoolForm.controls.association_limit.setValue(0);
      this.schoolForm.controls["association_limit"].clearValidators();
    }
  }
}
