import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-update-progress",
  templateUrl: "./update-progress.component.html",
  styleUrls: ["./update-progress.component.css"],
})
export class UpdateProgressAction implements OnInit {
  passages: any;
  totalWords: number;

  constructor(
    public dialogRef: MatDialogRef<UpdateProgressAction>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.passages = this.data.passages;
  }

  confirmAction() {
    location.reload();
  }

  getAcumenData1(passage: any): number {
    const percentOne = Math.round((passage.words_missed_first / passage.word_count) * 100);
    return 100 - percentOne;
  }

  getAcumenData2(passage: any): number {
    const percentTwo = Math.round((passage.words_missed_second / passage.word_count) * 100);
    return 100 - percentTwo;
  }
}
