import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApiDataService } from "src/app/shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ErrorMessages } from "src/app/shared/constants/utility.constants";
import { ImportModel } from "src/app/shared/models/import";
import { School } from "src/app/shared/models/school";
import { MatDialogRef } from "@angular/material/dialog";
import { StudentConstants } from "../constants/student.constants";
import { ImportReportModel } from "src/app/shared/models/import-report";
import { FormControl } from "@angular/forms";
import { StudentUtility } from "../services/student-utility-services";

@Component({
  selector: "app-import-students",
  templateUrl: "./import-students.component.html",
})
export class ImportStudentsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  dialogDataInstance: any;
  schoolList: School[];
  schoolOptions: School[];
  importPhase = 1;
  schoolControl: FormControl = new FormControl();
  importStudentModal: ImportModel = {};
  blankTemplate = StudentConstants.STUDENT_BLANK_TEMPLATE_LINK;
  loading: boolean;
  successReport: ImportReportModel;
  studentDetails: number;
  fileName: any;
  importSuccessful: boolean;
  constructor(
    public dialogRef: MatDialogRef<ImportStudentsComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getSchools();
    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }

  getSchools() {
    this.loading = true;
    this.apiService
      .getSchoolList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.schoolList = res;
          this.schoolOptions = res;
          this.schoolList = res
            .filter((sch) => sch.school_name.replace(/\s/g, "") !== "")
            .sort((a, b) => {
              return a.school_name.localeCompare(b.school_name);
            });
        }
        this.loading = false;
      });
  }

  uploadImage(image: string) {
    document.getElementById(image).click();
  }

  onUpload(event) {
    const file = event.target.files[0];
    this.importStudentModal.file = file;
    this.fileName = file.name;
  }

  schoolSelected(event) {
    this.importStudentModal.school = event.value;
  }

  resetForm() {
    this.importStudentModal = { file: null, school: null };
    this.fileName = "";
    this.importPhase = 1;
  }

  importStudents() {
    this.loading = true;
    this.apiService
      .importStudents(this.importStudentModal)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res) {
            this.successReport = res;
            this.importSuccessful = true;
            this.studentDetails =
              this.successReport.imported.length ||
              this.successReport.updated.length ||
              this.successReport.failed.length;
            this.importPhase = 2;
            let successfulImportCount = res.imported.length;
            let successfulUpdatedCount = res.updated.length;
            let failedCount = res.failed.length;
            let successfulImportedMessage =
              successfulImportCount.toString() +
              " students successfully imported";
            let successfulUpdatedMessage =
              successfulUpdatedCount.toString() +
              " students successfully updated";
            let errorMessage =
              failedCount.toString() +
              " students failed to import. Please fill all the required fields and also check for invalid input";

            if (successfulImportCount !== 0) {
              this.toastrService.success(successfulImportedMessage);
            }
            if (successfulUpdatedCount !== 0) {
              this.toastrService.success(successfulUpdatedMessage);
            }
            if (failedCount !== 0) {
              this.toastrService.error(errorMessage);
            }
          } else {
            this.importError();
          }
          this.loading = false;
        },
        (err) => {
          this.importError();
        }
      );
  }

  importError() {
    this.importPhase = 2;
    this.importSuccessful = false;
    this.toastrService.error(ErrorMessages.IMPORT_STUDENT);
    this.loading = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
