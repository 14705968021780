import { Pipe, PipeTransform } from '@angular/core';
import { Student } from '../models/student-base';

@Pipe({
    name: 'studentFilter',
    pure: false
})

export class StudentFilterPipe implements PipeTransform {
    transform(items: Student[], args: any[]): Student[] {
        const counter = args[1];
        const filter = args[0];
        let filteredList = items;
        if (!items || !filter) {
            return items;
        }
        if (filter.Status !== 'all') {
            filteredList = filteredList.filter(item => item.WhyInactive.toLowerCase() === filter.Status.toLowerCase() || item.WhyInactive === '');
        }

        if (counter) {
            counter.count = filteredList.length;
        }
        return filteredList;
    }
}
