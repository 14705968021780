import { Pipe, PipeTransform } from '@angular/core';
import { District } from '../models/district';

@Pipe({
    name: 'districtFilter',
    pure: false
})

export class DistrictFilterPipe implements PipeTransform {
    transform(items: District[], args: any[]): District[] {
        const counter = args[1];
        const filter = args[0];
        let filteredList = items;
        if (!items || !filter) {
            return items;
        }
        const searchText = filter.SearchText.toLowerCase();
        if (searchText.length) {
            filteredList = items.filter(item =>
                 (item.district_name && item.district_name.toLowerCase().indexOf(searchText) !== -1));
        }

        if (counter) {
            counter.count = filteredList.length;
        }
        return filteredList;
    }
}
