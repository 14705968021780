import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  ActionMessages,
  ErrorMessages,
} from "../../constants/utility.constants";
import { BulkAction } from "../../models/bulk-action";
import { ApiDataService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-activate-student",
  templateUrl: "./activate-student.component.html",
})
export class ActivateStudentComponent implements OnInit {
  public dialogDataInstance: any;
  loading: boolean;
  actionMessages = ActionMessages;
  bulkActionBody: BulkAction = {};
  isIndividual: boolean;
  constructor(
    public dialogRef: MatDialogRef<ActivateStudentComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.isIndividual =
      this.dialogDataInstance.action === "Individual" ? true : false;
  }

  confirmAction() {
    this.loading = true;
    if (this.isIndividual) {
      this.bulkActionBody.studentIds = [this.dialogDataInstance.studentList.id];
      this.bulkActionBody.id = 1;
      this.apiService
        .bulkActionStudents(this.bulkActionBody)
        .subscribe((res) => {
          if (res) {
            this.dialogRef.close(true);
          } else {
            this.toastrService.error(ErrorMessages.BULK_ACTION);
            this.dialogRef.close(false);
          }
          this.loading = false;
        });
    } else {
      this.bulkActionBody.id = 1;
      this.bulkActionBody.studentIds = this.dialogDataInstance.studentList.map(
        (student) => student.id
      );
      this.apiService
        .bulkActionStudents(this.bulkActionBody)
        .subscribe((res) => {
          if (res) {
            this.dialogRef.close(true);
          } else {
            this.toastrService.error(ErrorMessages.BULK_ACTION);
            this.dialogRef.close(false);
          }
          this.loading = false;
        });
    }
  }
}
