import { FilterPipe } from "src/app/shared/pipes/common-filter";

export class StudentUtility {
  public static getSmiley(mood: number) {
    let imageUrl = "/assets/smileys/";
    switch (Math.round(mood)) {
      case 1:
        imageUrl += "very-unhappy";
        break;
      case 2:
        imageUrl += "unhappy";
        break;
      case 3:
        imageUrl += "neutral";
        break;
      case 4:
        imageUrl += "happy";
        break;
      case 5:
      case 6:
        imageUrl += "very-happy";
        break;
      default:
        imageUrl += "neutral";
        break;
    }
    imageUrl += "-dark.svg";
    return imageUrl;
  }

  public static getColor(name) {
    switch (name) {
      case "green":
        // green
        return "#008000";
      case "orange":
        // orange
        return "#FD5900";
      case "yellow":
        // yellow
        return "#FFC100";
      case "light blue":
        // light blue
        return "#00B6E0";
      case "red":
        // red
        return "#FF0000";
    }
  }

  public static filterOptions(item: any[], searchString: string, arg: any[]) {
    if (this.isNullOrEmpty(searchString)) {
      return item;
    } else {
      return new FilterPipe().transform(item, [searchString, arg]);
    }
  }

  public static isNullOrEmpty(val: any) {
    return !val || val === undefined || val.toString().trim().length === 0;
  }

  public static removeItemFromArray(arr: any[], prop: string, value: any) {
    const index = Array.isArray(arr)
      ? arr.findIndex((item) => item[prop] === value)
      : -1;
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  public static getFullName(firstName, secondName, lastName) {
    const fName = firstName ? firstName : "";
    const sName = secondName ? secondName : "";
    const lName = lastName ? lastName : "";
    return `${fName} ${sName} ${lName}`;
  }
}
