import { Component } from "@angular/core";
import { BulkAction } from "../../models/bulk-action";
import { ApiDataService } from "../../services/api.service";
import { MatDialogRef } from "@angular/material/dialog";
import {
  ActionMessages,
  ErrorMessages,
} from "../../constants/utility.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-notes",
  templateUrl: "./add-notes.component.html",
})
export class AddNotesComponent {
  bulkActionBody: BulkAction = {};
  public dialogDataInstance: any;
  actionMessages = ActionMessages;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddNotesComponent>,
    private apiService: ApiDataService,
    private toastrService: ToastrService
  ) {}

  confirmAction() {
    this.loading = true;
    this.bulkActionBody.id =
      this.dialogDataInstance.action === "Add Notes" ? 3 : null;
    this.bulkActionBody.studentIds = this.dialogDataInstance.studentList.map(
      (student) => student.id
    );
    this.apiService.bulkActionStudents(this.bulkActionBody).subscribe((res) => {
      if (res) {
        this.dialogRef.close(true);
      } else {
        this.toastrService.error(ErrorMessages.BULK_ACTION);
        this.dialogRef.close(false);
      }
      this.loading = false;
    });
  }

  notesInput() {
    if (this.bulkActionBody.notes && !this.bulkActionBody.notes.trim()) {
      this.bulkActionBody.notes = "";
    }
  }
}
