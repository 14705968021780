import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiDataService } from "src/app/shared/services/api.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { Tutor } from "src/app/shared/models/tutor-base";
import { SearchBase } from "src/app/shared/models/search-base";
import { Student } from "src/app/shared/models/student-base";
import { ToastrService } from "ngx-toastr";
import {
  SuccessMessages,
  MASK,
  ErrorMessages,
} from "src/app/shared/constants/utility.constants";
import { PathRouteConstants } from "src/app/shared/constants/app-route.constants";
import { Location } from "@angular/common";
import { School } from "src/app/shared/models/school";
import { StudentConstants } from "../constants/student.constants";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { StudentUtility } from "../services/student-utility-services";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FilesDeleteConfirmationComponent } from "../../files-delete-confirmation/files-delete-confirmation.component";

@Component({
  selector: "app-create-edit-student",
  templateUrl: "./create-edit-student.component.html",
  styleUrls: ["./create-edit-student.component.css"],
})
export class CreateEditStudentComponent implements OnInit, OnDestroy {
  showFileInput: boolean = false;
  selectedFile: File | null = null; // Stores the selected file
  fileError: string | null = null;
  selectedFileBase64: string | null = null;
  ngUnsubscribe = new Subject();
  studentId: number;
  studentToEdit: Student;
  allVolunteers: Tutor[];
  studentForm: FormGroup;
  editStudent: boolean;
  schoolList: School[];
  loading: boolean;
  searchObj: SearchBase = { SearchText: "" };
  grades: any;
  zones: any;
  pairingGroups = StudentConstants.PAIRING_GROUP;
  skillLevels = StudentConstants.SKILL_LEVELS;
  subsVolunteers: Tutor[] = [];
  filteredListCount = { count: 0 };
  selectedPrimaryVolunteers: Tutor[] = [];
  studentIds: number[] = [];
  textMask = MASK.TEXT_MASK;
  tutorList: any;
  schoolControl: FormControl = new FormControl();
  schoolOptions: School[];
  preferences: any;
  active: number;
  readingLevel = [
    { display: "Yes", value: 1 },
    { display: "No", value: 0 },
  ];
  pattern = "d{4}/d{4}";
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiDataService,
    private toastrService: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    public _location: Location
  ) {
    this.initializeForm();
  }

  initializeForm() {
    this.studentForm = new FormGroup({
      first_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      second_name: new FormControl(""),
      last_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      class_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=.*\S).+$/),
      ]),
      school: new FormControl("", Validators.required),
      grade: new FormControl("", Validators.required),
      zone: new FormControl(""),
      second_grade_year: new FormControl(""),
      third_grade_year: new FormControl(""),
      fifth_grade_year: new FormControl(""),
      other_information: new FormControl(""),
      control_group: new FormControl(false),
      preference: new FormControl("", Validators.required),
      notes: new FormControl(""),
      is_reading_level: new FormControl(0),
    });
  }

  ngOnInit() {
    this.loading = true;
    this.editParamsSubscribe();
    this.getSchools();
    this.getPreferences();
    this.getGrades();
    this.getZones();
    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }
  onFileChange(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;

      // Validate file type
      if (fileType !== StudentConstants.PDF_FILE_TYPE) {
        this.fileError = StudentConstants.FILE_TYPE_ERROR;
        this.selectedFile = null; // Clear the selected file
        event.target.value = ""; // Clear the input value
      } else {
        this.fileError = null; // Clear the error message if valid
        this.selectedFile = file;
        this.convertFileToBase64(file);
      }
    }
  }

  // Convert the file to base64
  convertFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.selectedFileBase64 = reader.result as string;
    };
  }

  toggleFileInput(): void {
    this.showFileInput = !this.showFileInput;
  }

  deleteConsentForm(fileId: number): void {
    const dialogRef = this.dialog.open(FilesDeleteConfirmationComponent, {
      data: {
        title: StudentConstants.DEL_MODAL_TITLE,
        description: StudentConstants.DEL_MODAL_DEC,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteConsentForm(fileId).subscribe(
          (response) => {
            // Success: Remove the file from the `files` array
            this.studentToEdit.files = this.studentToEdit.files.filter(
              (file) => file.id !== fileId
            );
            this.toastrService.success(response.message);
          },
          (error) => {
            alert(StudentConstants.DEL_FILE_FAILED_ALERT);
          }
        );
      }
    });
  }

  getFileNameFromUrl(fileUrl: string): string {
    try {
      // Extract the file name from the URL
      const urlParts = fileUrl.split("/");
      return decodeURIComponent(urlParts[urlParts.length - 1]);
    } catch {
      return StudentConstants.FILE_TYPE_ERR;
    }
  }

  editParamsSubscribe() {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.studentId = +params.id;
        if (this.studentId) {
          this.editStudent = true;
          this.getStudent();
        }
      });
  }

  getStudent() {
    this.apiService
      .getStudent(this.studentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.studentToEdit = res;
          this.tutorList = res?.tutor;
          this.active = res.is_active;
          this.setForm();
        }
        this.loading = false;
      });
  }

  setForm() {
    this.studentForm.setValue({
      first_name: this.studentToEdit.first_name,
      second_name: this.studentToEdit.second_name,
      last_name: this.studentToEdit.last_name,
      class_name: this.studentToEdit.class_name,
      school: this.studentToEdit.school.id,
      grade: this.studentToEdit.grade.id,
      zone: this.studentToEdit.zone.id,
      second_grade_year: this.studentToEdit.second_grade_year,
      third_grade_year: this.studentToEdit.third_grade_year,
      fifth_grade_year: this.studentToEdit.fifth_grade_year,
      other_information: this.studentToEdit.other_information,
      control_group: this.studentToEdit.control_group,
      preference: this.studentToEdit.preference.id,
      notes: this.studentToEdit.notes,
      is_reading_level: this.studentToEdit.is_reading_level,
    });
    this.isControlCheck();
  }

  getSchools() {
    this.apiService
      .getSchoolList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.length > 0) {
          this.schoolList = res;
          this.schoolOptions = res;
          if (!this.studentId) {
            this.loading = false;
          }
        }
      });
  }
  getPreferences() {
    this.apiService.getAllPreferences().subscribe((res) => {
      this.preferences = res;
    });
  }
  getGrades() {
    this.apiService.getAllGrades().subscribe((res) => {
      this.grades = res;
      this.grades = this.grades.sort((a, b) => {
        if (a.grade_level < b.grade_level) return -1;
        if (a.grade_level > b.grade_level) return 1;
        return 0;
      });
    });
  }
  getZones() {
    this.apiService.getAllZones().subscribe((res) => {
      this.zones = res;
    });
  }
  isControlCheck() {
    if (this.studentForm.get("grade").value === "5th") {
      this.studentForm.get("control_group").setValue(false);
      this.studentForm.get("control_group").disable();
    } else {
      this.studentForm.get("control_group").enable();
    }
  }
  updateStudent(student) {
    // Trim and format fields
    student.first_name = student.first_name.trim();
    student.second_name = student.second_name
      ? student.second_name.trim()
      : null;
    student.last_name = student.last_name.trim();
    student.class_name = student.class_name.trim();
    student.second_grade_year = student.second_grade_year
      ? student.second_grade_year.slice(0, 4) +
        "/" +
        student.second_grade_year.slice(4)
      : "";
    student.third_grade_year = student.third_grade_year
      ? student.third_grade_year.slice(0, 4) +
        "/" +
        student.third_grade_year.slice(4)
      : "";
    student.fifth_grade_year = student.fifth_grade_year
      ? student.fifth_grade_year.slice(0, 4) +
        "/" +
        student.fifth_grade_year.slice(4)
      : "";
    student["control_group"] = student["control_group"] ? 1 : 0;

    // Add school_name to the payload if found
    const selectedSchool = this.schoolOptions.find(
      (school) => school.id === student.school
    );
    const studentData = {
      ...student,
      school_name: selectedSchool ? selectedSchool.school_name.trim() : null,
    };

    this.loading = true;

    // Handle file (convert to binary)
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.selectedFile); // Convert file to ArrayBuffer

      reader.onloadend = () => {
        const binaryData = Array.from(
          new Uint8Array(reader.result as ArrayBuffer)
        );
        studentData.file = binaryData; // Attach binary data
        studentData.file_name = this.selectedFile.name; // Attach file name
        this.saveStudent(studentData);
      };

      reader.onerror = () => {
        this.loading = false;
        this.toastrService.error("Failed to process the file.");
      };
    } else {
      this.saveStudent(studentData); // Save student without file
    }
  }

  // Helper method to save student
  private saveStudent(studentData) {
    if (!this.studentId) {
      this.apiService
        .createStudent(studentData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res) => {
            if (res) {
              this.toastrService.success(SuccessMessages.CREATE_STUDENT);
              this.router.navigate([PathRouteConstants.STUDENT_LIST]);
            }
          },
          (err) => {
            this.loading = false;
            this.toastrService.error("Failed to create student.");
          }
        );
    } else {
      this.apiService
        .updateStudent(this.studentId, studentData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res) => {
            if (res) {
              this.navigateBack(SuccessMessages.UPDATE_STUDENT, "success");
            }
          },
          (err) => {
            this.loading = false;
            this.navigateBack(ErrorMessages.STUDENT_UPDATE, "error");
          }
        );
    }
  }

  navigateBack(message, status) {
    if (status === "success") {
      this.toastrService.success(message);
      if (this.active) {
        this.router.navigate([PathRouteConstants.STUDENT_LIST]);
      } else {
        this.router.navigate([PathRouteConstants.INACTIVE_STUDENT_LIST]);
      }
    } else {
      this.toastrService.error(message);
    }
    this.loading = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
