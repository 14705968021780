import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LessonListComponent } from './components/lesson-list/lesson-list.component';
import { LessonDetailsComponent } from './components/lesson-details/lesson-details.component';
import { UpdateLessonComponent } from './components/update-lesson/update-lesson.component';
import { AppRouteConstants } from 'src/app/shared/constants/app-route.constants';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';


const routes: Routes = [
    {
        path: AppRouteConstants.DEFAULT, component: LessonListComponent, pathMatch: 'full', canActivate: [AuthGuardService]
    },
    { path: AppRouteConstants.LESSON_DETAILS, component: LessonDetailsComponent },
    { path: AppRouteConstants.LESSON_CREATE, component: UpdateLessonComponent },
    { path: AppRouteConstants.LESSON_UPDATE, component: UpdateLessonComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LessonsRoutingModule { }


