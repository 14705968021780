import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { StudentConstants } from "../student-list/constants/student.constants";
import { FormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil, delay, debounceTime } from "rxjs/operators";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentInfo } from "src/app/shared/models/studentinfo";
import { Student } from "src/app/shared/models/student-base";
import { AppUser } from "src/app/shared/models/app-user";
import { StudenFilterService } from "src/app/studen-filter.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-substitute-tutor",
  templateUrl: "./substitute-tutor.component.html",
  styleUrls: ["./substitute-tutor.component.css"],
})
export class SubstituteTutorComponent implements OnInit {
  private studentSearchSubject = new Subject<string>();
  private tutorSearchSubject = new Subject<string>();
  private ngUnsubscribe = new Subject();
  entireListSelected: boolean;
  filteredStudentList: Student[] = [];
  selectedStudentList = [];
  selectedTutorList = [];
  count: number;
  loadingList: boolean;
  loading: boolean;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;
  selectedStudent: any;
  selectedTutor: any;
  selectedStudents: Student[] = [];
  loggedInUser: AppUser;
  id: number;
  isTutor: boolean = false;
  studentInfo: StudentInfo;
  levelSelected = this.studentFilterService.studentInfo.grade;

  filteredStudents: Observable<any[]>;
  filteredTutors: Observable<any[]>;
  searchQuery: string = "";
  searchQueryTutor: string = "";
  selections: any[] = [];
  isLoading = true;
  hasData: boolean = false;

  constructor(
    private apiService: ApiDataService,
    private studentFilterService: StudenFilterService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastrService: ToastrService
  ) {}
  studentControl = new FormControl();
  tutorControl = new FormControl();
  showNoDataMessage: boolean = false;
  showNoDataMessageTutor: boolean = false;

  ngOnInit() {
    this.studentSearchSubject
      .pipe(
        debounceTime(400), // Wait for 400ms after the last emission
        takeUntil(this.ngUnsubscribe) // Cleanup subscription on component destroy
      )
      .subscribe((searchQuery) => {
        this.getStudentList(searchQuery);
      });

    this.tutorSearchSubject
      .pipe(debounceTime(400), takeUntil(this.ngUnsubscribe))
      .subscribe((searchQuery) => {
        this.getTutorList(searchQuery);
      });
    this.studentInfo = this.studentFilterService.studentInfo;
    this.studentInfo.isActive = 1;
    this.loadSubstitutions();
    this.getUserInfo();
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.id = res.id;
        this.isTutor = this.loggedInUser === this.tutorRoleId;
      }
    });
  }
  onStudentSearch(searchQuery: string) {
    if (searchQuery.length >= 3 || searchQuery.length === 0) {
      this.studentSearchSubject.next(searchQuery);
    } else {
      this.selectedStudentList = [];
    }
  }

  onTutorSearch(searchQuery: string) {
    if (searchQuery.length >= 3 || searchQuery.length === 0) {
      this.tutorSearchSubject.next(searchQuery);
    } else {
      this.selectedTutorList = [];
    }
  }
  loadSubstitutions() {
    this.isLoading = true;
    this.apiService.getSubstitutions().subscribe(
      (response) => {
        this.selections = response;
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      },
      (error) => {
        console.error("Error loading substitutions:", error);
        this.isLoading = false;
      }
    );
  }
  clearInputTutor() {
    this.tutorControl.setValue("");
    this.selectedTutor = null;
  }
  clearInputStudent() {
    this.studentControl.setValue("");
    this.selectedStudent = null;
  }
  onStudentEnter(event: KeyboardEvent) {
    event.preventDefault();
    const inputValue = (event.target as HTMLInputElement).value.trim();

    // Check if input value length is at least two characters
    if (inputValue.length >= 2) {
      this.getStudentList(inputValue);
    }
  }
  onTutorSearchEnter(event: KeyboardEvent) {
    event.preventDefault();
    const inputValue = (event.target as HTMLInputElement).value.trim();

    // Check if input value length is at least two characters
    if (inputValue.length >= 2) {
      this.getTutorList(inputValue);
    }
  }

  onStudentSelected(event: any) {
    this.selectedStudent = event.option.value;
  }

  onTutorSelected(event: any) {
    this.selectedTutor = event.option.value;
  }

  addSelection() {
    this.isLoading = true;
    if (this.selectedStudent && this.selectedTutor) {
      this.apiService
        .addSubstitution(
          this.selectedStudent.student_id,
          this.selectedTutor.tutor_id
        )
        .subscribe(
          (response) => {
            // Here you can fetch the updated list of substitutions and update your UI accordingly
            this.selections = response;
            this.changeDetectorRef.detectChanges();
            this.toastrService.success("Substitution added successfully");
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
            this.clearInputTutor();
            this.clearInputStudent();
          },
          (error) => {
            console.error("Error adding substitution:");
            if (error.status === 400) {
              // Assuming the error response has a message property
              this.toastrService.error(error.error.message);
              this.isLoading = false;
            } else {
              // Handle other status codes or generic error message
              this.toastrService.error(
                "An error occurred while adding the substitution."
              );
            }
          }
        );
    } else {
      this.toastrService.error("Please select both a student and a tutor");
      this.isLoading = false;
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.id === o2?.id;
  }
  getStudentList(searchQuery: string) {
    if (searchQuery.length > 0) {
      const firstName = searchQuery.match(/^\w+/)[0];
      this.apiService.getStudentforSubstitute(firstName).subscribe((res) => {
        this.selectedStudentList = res;
        this.showNoDataMessage = this.selectedStudentList.length === 0;
      });
    } else {
      // Optionally, clear the selectedStudentList when the input is cleared
      this.selectedStudentList = [];
    }
  }
  getTutorList(searchQueryTutor: string, event?: KeyboardEvent) {
    if (searchQueryTutor.length > 0) {
      const firstName = searchQueryTutor.match(/^\w+/)[0];
      this.apiService
        .getTutorforSubstitute(firstName) // Add parentheses here to call the method
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.selectedTutorList = res;
          this.showNoDataMessageTutor = this.selectedTutorList.length === 0;
        });
    } else {
      this.selectedTutorList = [];
    }
  }

  onCloseButtonClick(rowId: number): void {
    // Here you can implement additional logic, such as removing the substitution from the table
  }
  onDeleteButtonClick(
    id: number,
    studentName: string,
    tutorName: string
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        studentName: studentName,
        tutorName: tutorName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService
          .deleteSubstitution(id)
          .pipe(
            delay(200) // Delay for 100 milliseconds
          )
          .subscribe((response) => {
            this.loadSubstitutions();
            this.toastrService.success(response.message);
            this.changeDetectorRef.detectChanges();
          });
      }
    });
  }

  displayStudentName(student: any): string {
    return student && student.student_name ? student.student_name : "";
  }

  displayTutorName(tutor: any): string {
    return tutor && tutor.tutor_name ? tutor.tutor_name : "";
  }
}
