import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PathRouteConstants } from "src/app/shared/constants/app-route.constants";
import { SuccessMessages } from "src/app/shared/constants/utility.constants";
import { Tutor } from "src/app/shared/models/tutor-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { environment } from "src/environments/environment";
import { School } from "src/app/shared/models/school";
import { StudentUtility } from "../../student-list/services/student-utility-services";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-create-edit-tutor",
  templateUrl: "./create-edit-tutor.component.html",
  styleUrls: ["./create-edit-tutor.component.css"],
})
export class CreateEditTutorComponent implements OnInit {
  volunteerId: number;
  tutorToEdit: Tutor = {};
  tutorForm: FormGroup;
  editTutor: boolean;
  schoolList: any;
  loading: boolean;
  active: number;
  fileSelected: any;
  imageData: any;
  teachingExperience: false;
  counselorExperience: false;
  specialist: false;
  checkBox: false;
  codeOfEthics: false;
  backgroundCheck: false;
  selectedSchools: School[] = [];
  pairingGroups = [
    { display: "0", value: 0 },
    { display: "*", value: 1 },
    { display: "**", value: 2 },
    { display: "***", value: 3 },
    { display: "****", value: 4 },
  ];
  filteredListCount = { count: 0 };
  tutorIds: number[] = [];
  imageSizeExceededError: boolean;
  commitmentLevels: string[];
  preferences: string[];
  roles: any;
  schoolIds: number[] = [];
  schoolOptions: School[] = [];
  schoolControl: FormControl = new FormControl();
  public mask = [
    /[1-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  previousSelectedSchool: number[] = [];
  involvedSchoolsID: number[] = [];
  schoolsToUpdate: School[] = [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiDataService,
    private toastrService: ToastrService,
    public _location: Location,
    private router: Router
  ) {
    this.tutorForm = new FormGroup({
      profile_image_path: new FormControl(""),
      first_name: new FormControl("", [Validators.required]),
      second_name: new FormControl(""),
      last_name: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^((?!.*\.{2})[^<>()[\]\\.,;:\s#@"]+(\.[^<>()[\]\\.,;:\s@"]+)*|(".+"))([\w.]+)@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      commitment_level: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      second_phone: new FormControl(""),
      is_background_checked: new FormControl(""),
      is_code_of_ethics: new FormControl(""),
      is_teacher_experience: new FormControl(""),
      is_counselor_experience: new FormControl(""),
      is_deleted: new FormControl(0),
      emergency_contact_first_name: new FormControl(""),
      emergency_contact_last_name: new FormControl(""),
      emergency_contact_number: new FormControl("", [
        Validators.pattern(
          /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/
        ),
      ]),
      schools: new FormControl("", [Validators.required]),
      note: new FormControl(""),
      is_specialist: new FormControl(""),
      can_share_phone: new FormControl(""),
      preference: new FormControl("", [Validators.required]),
      role: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe((params) => {
      this.volunteerId = params.id;
      if (this.volunteerId) {
        this.editTutor = true;
        this.getVolunteer();
      }
    });
    this.getSchools();
    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }

  getDropDownValues() {
    let commitments = this.apiService.getAllCommitments();
    let roles = this.apiService.getAllRoles();
    let preferences = this.apiService.getAllPreferences();

    forkJoin([commitments, roles, preferences]).subscribe((results) => {
      this.commitmentLevels = results[0];
      this.roles = results[1];
      this.preferences = results[2];
      this.newSchoolList(this.schoolIds);
      this.loading = false;
    });
  }

  sortSchools(arr) {
    arr.sort(
      (a, b) =>
        b.tutor_limit -
        b.associated_tutor_count -
        (a.tutor_limit - a.associated_tutor_count)
    );
    return arr;
  }
  getSchools() {
    this.apiService.getSchoolList().subscribe((res) => {
      const newRes = this.sortSchools(res);
      this.schoolList = newRes;
      this.schoolOptions = newRes;
      this.getDropDownValues();
    });
  }
  getVolunteer() {
    this.loading = true;
    this.apiService.getVolunteer(this.volunteerId).subscribe((res) => {
      if (res) {
        this.tutorToEdit = res.user;
        this.schoolIds = res.schoolIds;
        this.previousSelectedSchool = this.schoolIds;
        this.active = res.user.is_active;
        this.setForm();
      }
    });
  }
  setForm() {
    this.tutorForm.patchValue({
      first_name: this.tutorToEdit.first_name,
      last_name: this.tutorToEdit.last_name,
      second_name: this.tutorToEdit.second_name,
      email: this.tutorToEdit.email,
      commitment_level: this.tutorToEdit.commitment_level.id,
      phone: this.tutorToEdit.phone,
      second_phone: this.tutorToEdit.second_phone,
      is_background_checked: this.tutorToEdit.is_background_checked,
      is_code_of_ethics: this.tutorToEdit.is_code_of_ethics,
      is_deleted: this.tutorToEdit.is_deleted,
      is_teacher_experience: this.tutorToEdit.is_teacher_experience,
      is_counselor_experience: this.tutorToEdit.is_counselor_experience,
      emergency_contact_first_name:
        this.tutorToEdit.emergency_contact_first_name,
      emergency_contact_last_name: this.tutorToEdit.emergency_contact_last_name,
      emergency_contact_number: this.tutorToEdit.emergency_contact_number,
      profile_image_path: this.tutorToEdit.profile_image_path,
      is_specialist: this.tutorToEdit.is_specialist,
      note: this.tutorToEdit.note,
      role: this.tutorToEdit.role.id,
      can_share_phone: this.tutorToEdit.can_share_phone,
      schools: this.schoolIds,
      preference: this.tutorToEdit.preference.id,
    });
    this.loading = false;
  }

  updateVolunteer(volunteer) {
    volunteer.first_name = volunteer.first_name.trim();
    volunteer.second_name = volunteer.second_name
      ? volunteer.second_name.trim()
      : "";
    volunteer.last_name = volunteer.last_name.trim();
    volunteer.email = volunteer.email.trim();
    this.loading = true;
    volunteer.profile_image_path = this.imageData;
    if (!this.volunteerId) {
      this.apiService.createVolunteer(volunteer).subscribe(
        (res) => {
          {
            if (res) {
              this.navigateToList(SuccessMessages.CREATE_TUTOR);
            }
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    } else {
      this.involvedSchoolsID.forEach((id) => {
        const school = this.schoolList.find((s) => s.id === id);
        if (school) {
          this.schoolsToUpdate.push(school);
        }
      });
      this.apiService.updateVolunteer(this.volunteerId, volunteer).subscribe(
        (res) => {
          if (res == null) {
            this.updateSlot();
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }

  updateSlot() {
    this.apiService.updateTutorSlot(this.schoolsToUpdate).subscribe(
      (res) => {
        if (res == null) {
          this.schoolList = [];
          this.selectedSchools = [];
          this.previousSelectedSchool = [];
          this.navigateBack(SuccessMessages.UPDATE_TUTOR);
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  tutorListDetails() {
    this.router.navigate([PathRouteConstants.TUTOR_LIST]);
  }

  navigateBack(message) {
    this.toastrService.success(message);
    if (this.active) {
      this.router.navigate([PathRouteConstants.TUTOR_LIST]);
    } else {
      this.router.navigate([PathRouteConstants.INACTIVE_TUTOR_LIST]);
    }
  }
  navigateToList(message) {
    this.toastrService.success(message);
    this.router.navigate([PathRouteConstants.TUTOR_LIST]);
  }

  onImageChanged(e: { file: any }) {
    const fileReader: FileReader = new FileReader();
    const self = this;
    const image = e.file;
    fileReader.readAsDataURL(image);
    fileReader.onloadend = (ex) => {
      const finalImage = (fileReader.result as string).replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
      self.updateImageInForm(finalImage);
    };
  }

  updateImageInForm(image) {
    this.tutorForm.controls.profile_image_path.setValue(image);
    this.tutorForm.markAsDirty();
  }

  maxFileSizeExceeded() {
    this.imageSizeExceededError = true;
  }

  maxFileSizeNotExceeded() {
    this.imageSizeExceededError = false;
  }

  filesSelect(event) {
    this.fileSelected = event.target.files[0];
    if (
      this.fileSelected.size >
      1048576 * environment.MAX_ATTACHMENT_SIZE_IN_MB
    ) {
      this.imageSizeExceededError = true;
    } else {
      this.onFileSelect(this.fileSelected);
    }
  }

  onFileSelect(file) {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result.toString();
      this.imageData = reader.result
        .toString()
        .replace(/^data:image\/[a-z]+;base64,/, "");
      image.onload = () => {
        const element = document.getElementById("logo") as HTMLImageElement;
        element.src = reader.result.toString();
      };
    };
    reader.readAsDataURL(file);
  }
  // schoolSelected(event) {
  //   let selectedValues = event.value;
  //   this.selectedSchools = [];
  //   this.newSchoolList(selectedValues);
  // }

  checkInvolved(id) {
    if (!this.involvedSchoolsID.includes(id)) {
      this.involvedSchoolsID.push(id);
    }
  }

  schoolSelected(event) {
    this.previousSelectedSchool.sort((a, b) => a - b);
    let selectedValues = event.value;
    selectedValues.sort((a, b) => a - b);
    let currentSchoolId = this.compareArrays(
      this.previousSelectedSchool,
      selectedValues
    );
    if (selectedValues.includes(currentSchoolId)) {
      this.updateAssociation(currentSchoolId, 1);
    } else {
      this.updateAssociation(currentSchoolId, -1);
    }
    this.checkInvolved(currentSchoolId);
    this.previousSelectedSchool = selectedValues;
    this.selectedSchools = [];
    this.newSchoolList(selectedValues);
  }

  updateAssociation(id, value) {
    for (let school of this.schoolList) {
      if (school.id === id) {
        school.associated_tutor_count += value;
        break;
      }
    }
  }

  compareArrays(arr1, arr2) {
    let missing = [];
    for (let element of arr1) {
      if (!arr2.includes(element)) {
        missing.push(element);
      }
    }

    for (let element of arr2) {
      if (!arr1.includes(element)) {
        missing.push(element);
      }
    }

    return missing[0];
  }

  newSchoolList(selectedValues) {
    selectedValues.forEach((element) => {
      this.selectedSchools.push(
        this.schoolList.find((ele) => ele.id == element)
      );
    });
  }
  schoolRemoved(id) {
    this.selectedSchools = this.selectedSchools.filter((sch) => sch.id !== id);
    this.schoolIds = this.schoolIds.filter((sch_id) => sch_id !== id);
    this.previousSelectedSchool = this.previousSelectedSchool.filter(
      (x) => x !== id
    );
    this.checkInvolved(id);
    this.updateAssociation(id, -1);
    this.validateSchoolField(this.tutorForm);
  }

  validateSchoolField(formGroup: FormGroup) {
    const schoolControl = formGroup.get("schools");
    if (schoolControl) {
      schoolControl.markAsTouched({ onlySelf: true });
    }
  }
}
