import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import { NumberConstants } from "src/app/shared/constants/utility.constants";
import { AppUser } from "src/app/shared/models/app-user";
import { School } from "src/app/shared/models/school";
import { SearchBase } from "src/app/shared/models/search-base";
import { Tutor } from "src/app/shared/models/tutor-base";
import { TutorInfo } from "src/app/shared/models/tutor-info";
import { ApiDataService } from "src/app/shared/services/api.service";
import { UtilityService } from "src/app/shared/services/utility.service";
import { TutorFilterService } from "src/app/tutor-filter.service";

@Component({
  selector: "app-inactive-tutor-list",
  templateUrl: "./inactive-tutor-list.component.html",
  styleUrls: ["./inactive-tutor-list.component.css"],
})
export class InactiveTutorListComponent implements OnInit {
  loading: boolean;
  schoolSelected = this.tutorFilterService.tutorInfo.school;
  levelSelected = this.tutorFilterService.tutorInfo.commitmentFilter;
  schoolList: School[] = [];
  levelList: any;
  searchObj: SearchBase = { SearchText: "" };
  filteredListCount = { count: 0 };
  filteredTutorList: Tutor[] = [];
  selectedTutors: Tutor[] = [];
  basicLimit = NumberConstants.LIST_LIMIT;
  tutorList: Tutor[];
  entireListSelected: boolean;
  selectedIndex = 0;
  tutorInfo: TutorInfo;
  count: number;
  loggedInUser: AppUser;

  constructor(
    private apiService: ApiDataService,
    private tutorFilterService: TutorFilterService,
    private router: Router
  ) {}

  ngOnInit() {
    this.tutorInfo = this.tutorFilterService.tutorInfo;
    this.tutorInfo.isActive = 0;
    if (this.tutorInfo.offset) {
      this.tutorInfo.offset = 0;
    }
    this.loading = true;
    this.getUserInfo();
    this.getTutorList();
    this.getSchools();
    this.getCommitmentLevels();
  }

  getTutorList() {
    this.loading = true;
    this.apiService.getVolunteerList(this.tutorInfo).subscribe((res) => {
      if (res) {
        // tslint:disable-next-line:prefer-for-of
        for (let value of res.tutor) {
          this.filteredTutorList.push(value.user);
        }
        this.count = res.count;
        this.loading = false;
      }
    });
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
      }
    });
  }
  getSchools() {
    this.apiService.getSchoolList().subscribe((response) => {
      if (response) {
        this.schoolList = response;
        this.schoolList = this.schoolList.filter(
          (data) => data.school_name != null
        );
        UtilityService.sortArray(this.schoolList);
      }
    });
  }
  getCommitmentLevels() {
    this.apiService.getAllCommitments().subscribe((response) => {
      if (response) {
        this.levelList = response;
        this.levelList = this.levelList.filter(
          (data) => data.commitment_level != null
        );
        const allLevel = { id: -1, commitment_level: "All Levels" };
        this.levelList.push(allLevel);
        this.levelList = this.levelList.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
      }
    });
  }

  onLevelChange(event) {
    if (event.value) {
      this.filteredTutorList = [];
      this.tutorInfo.offset = 0;
      this.tutorInfo.commitmentFilter = event.value;
      this.tutorFilterService.tutorInfo.commitmentFilter = event.value;
      this.getTutorList();
    }
  }

  onSchoolChange(event) {
    if (event.value) {
      this.filteredTutorList = [];
      this.tutorInfo.offset = 0;
      this.tutorInfo.school = event.value;
      this.tutorFilterService.tutorInfo.school = event.value;
      this.getTutorList();
    }
  }
  onSearch() {
    this.tutorInfo.offset = 0;
    this.filteredTutorList = [];
    this.getTutorList();
  }
  onScrollDown() {
    this.tutorInfo.offset = this.tutorInfo.offset + this.basicLimit;
    this.getTutorList();
  }
  tutorProfile(volunteerId) {
    this.router.navigate([
      PathRouteConstants.INACTIVE_TUTOR_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.TUTOR_DETAILS, [
          { text: ":id", data: volunteerId },
        ]),
    ]);
  }

  editTutor(tutorId) {
    this.router.navigate([
      PathRouteConstants.INACTIVE_TUTOR_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.UPDATE_TUTOR, [
          { text: ":id", data: tutorId },
        ]),
    ]);
  }
}
