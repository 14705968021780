import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { District } from 'src/app/shared/models/district';
import { SearchBase } from 'src/app/shared/models/search-base';
import { States } from 'src/app/shared/models/states';
import { ApiDataService } from 'src/app/shared/services/api.service';
import { ErrorMessages, SuccessMessages } from 'src/app/shared/constants/utility.constants';


@Component({
  selector: 'app-create-edit-district',
  templateUrl: './create-edit-district.component.html',
})
export class CreateEditDistrictComponent implements OnInit {
  public dialogDataInstance: any;
  districtId: number;
  districtToEdit: District;
  districtForm: FormGroup;
  editDistrict: boolean;
  loading: boolean;
  searchObj: SearchBase = { SearchText: '' };
  filteredListCount = { count: 0 };
  statesObj: States;
  districtIds: number[] = [];
  data: District;
  states: States[] = [];

  constructor(private apiService: ApiDataService, private toastrService: ToastrService, private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateEditDistrictComponent>) {
    this.districtForm = this.formBuilder.group({
      district_name: [null, [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      state: [null, [Validators.required]],
    })
  }


  ngOnInit() {
    this.getStates();
    if (this.dialogDataInstance) {
      this.districtId = this.dialogDataInstance;
      this.editDistrict = true;
      this.getDistrict();
    }
  }
  getDistrict() {
    this.loading = true;
    this.apiService.getDistrict(this.districtId).subscribe(res => {
      if (res) {
        this.districtToEdit = res;
        this.setForm();
      }
      this.loading = false;
    })
  }
  setForm() {
    this.districtForm = this.formBuilder.group({
      id:this.districtToEdit.id,
      district_name: [this.districtToEdit.district_name,[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      state: [this.districtToEdit.state.id, [Validators.required]],
    });
  }

  onCloseConfirm(district) {
    if(district.district_name.length > 0) {
      this.updateDistrict(district);
    }
  }


  getStates() {
    this.apiService.getAllStates().subscribe(response => {
      if (response.length > 0) {
        this.states = response;
        this.states = this.states.filter(data => data.state_name != null);
      }
      this.loading = false;
    });
  }

  updateDistrict(district) {
    if (!this.editDistrict) {
      this.apiService.createDistrict(district).subscribe(res => {
        this.apiActions(res);
        this.toastrService.success(SuccessMessages.CREATE_DISTRICT);
      });
    }
    else {
      this.apiService.updateDistrict(district).subscribe(res => {
        this.apiActions(res);
        this.toastrService.success(SuccessMessages.UPDATE_DISTRICT);
      });
    }
  }

  apiActions(res) {
    if (res) {
      this.dialogRef.close(res);
    } else {
      this.toastrService.error(ErrorMessages.DISTRICT_UPDATE);
    }
  }
}
