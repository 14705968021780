import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "filter",
  pure: false,
})
export class FilterPipe implements PipeTransform {
  searchText;
  transform(items: any[], args: any[]): any[] {
    if (!items) {
      return [];
    }
    let searchText = args[0];
    const filterKeys = args[1];
    const counter = args[2];
    let filteredList = items;
    if (searchText && searchText.trim().length) {
      searchText = searchText.toLowerCase();
      filteredList = items.filter((item) => {
        for (let i = 0; i < filterKeys.length; i++) {
          if (
            item[filterKeys[i]] &&
            item[filterKeys[i]].toString().toLowerCase().indexOf(searchText) >
              -1
          ) {
            return true;
          } else if (i === filterKeys.length) {
            return false;
          }
        }
      });
    }
    if (counter) {
      counter.count = filteredList.length;
    }
    return filteredList;
  }
}
