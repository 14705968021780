import { Injector } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class RestService {
  public baseApiURL: string;
  protected http: HttpClient;

  constructor(injector: Injector) {
    this.http = injector.get(HttpClient);
    this.baseApiURL = environment.baseUrl;
  }

  // get(relativeUrl: string): Observable<any> {
  //     return this.http.get(this.baseApiURL + relativeUrl);
  // }
  get(relativeUrl: string, options?: { params?: HttpParams }): Observable<any> {
    let requestOptions: any = {};

    // If options are provided, include them in the requestOptions
    if (options && options.params) {
      requestOptions.params = options.params;
    }

    return this.http.get(this.baseApiURL + relativeUrl, requestOptions);
  }

  getWithHeaders(relativeUrl: string, headers: HttpHeaders): Observable<any> {
    return this.http.get(this.baseApiURL + relativeUrl, { headers });
  }

  // post(relativeUrl: string, data: any): Observable<any> {
  //   return this.http.post(this.baseApiURL + relativeUrl, data);
  // }
  // put(relativeUrl: string, data: any): Observable<any> {
  //   return this.http.put(this.baseApiURL + relativeUrl, data);
  // }
  post(relativeUrl: string, data: any): Observable<any> {
    const options =
      data instanceof FormData
        ? {} // Let the browser set the headers for FormData
        : { headers: { "Content-Type": "application/json" } }; // Explicitly set for JSON data
    return this.http.post(this.baseApiURL + relativeUrl, data, options);
  }

  put(relativeUrl: string, data: any): Observable<any> {
    const options =
      data instanceof FormData
        ? {} // Let the browser set the headers for FormData
        : { headers: { "Content-Type": "application/json" } }; // Explicitly set for JSON data
    return this.http.put(this.baseApiURL + relativeUrl, data, options);
  }

  postWithHeaders(
    relativeURL: string,
    data: any,
    options: any
  ): Observable<any> {
    return this.http.post(this.baseApiURL + relativeURL, data, options);
  }

  delete(relativeUrl: string): Observable<any> {
    return this.http.delete(this.baseApiURL + relativeUrl);
  }
}
