import { Component, OnInit } from "@angular/core";
import { Tutor } from "src/app/shared/models/tutor-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedDataService } from "src/app/shared/services/shared-data.service";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { ToastrService } from "ngx-toastr";
import { SuccessMessages } from "src/app/shared/constants/utility.constants";
import { Student } from "src/app/shared/models/student-base";
import {
  PathRouteConstants,
  AppRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import { UtilityService } from "src/app/shared/services/utility.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { StudentUtility } from "../../student-list/services/student-utility-services";
import { AppUser } from "src/app/shared/models/app-user";
import { StudentConstants } from "../../student-list/constants/student.constants";

@Component({
  selector: "app-tutor-details",
  templateUrl: "./tutor-details.component.html",
})
export class TutorDetailsComponent implements OnInit {
  loading: boolean;
  tutor: Tutor;
  schoolList1 = [];
  selectedBulkAction: any;
  tutorList: Tutor[];
  levelList = ["All levels", "primary", "substitute"];
  studentList: Student[];
  studentActive: boolean;
  schoolList = ["All Schools"];
  selectedTutors: Tutor[] = [];
  volunteerId: number;
  loggedInUser: AppUser;
  schoolNames: any;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;

  constructor(
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiDataService,
    private sharedDialogService: SharedDialogService,
    private toastrService: ToastrService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.getUserInfo();
    this.route.params.subscribe((params) => {
      this.volunteerId = +params.id;
      this.getVolunteer();
    });
  }
  getUserInfo() {
    this.loading = true;
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.loading = false;
      }
    });
  }
  getVolunteer() {
    this.loading = true;
    this.apiService.getVolunteer(this.volunteerId).subscribe((res) => {
      if (res) {
        this.tutor = res.user;
        this.schoolNames = res.schoolNames;
        this.loading = false;
      }
    });
  }

  activateDeactivateTutor() {
    this.deactivateOrActivateTutor();
  }

  deactivateOrActivateTutor() {
    this.sharedDialogService
      .DeactivateTutor({
        tutor: this.tutor,
        action: this.selectedBulkAction,
        tutorList: this.selectedTutors,
      })
      .subscribe((res) => {
        this.bulkActionSuccessful(res);
      });
  }

  bulkActionSuccessful(success) {
    if (success) {
      this.getTutorList();
      this.selectedTutors = [];
      this.toastrService.success(SuccessMessages.BULK_ACTION);
    }
  }
  getTutorList() {
    this.navigationService.navigateToPage([
      this.tutor.is_active
        ? PathRouteConstants.TUTOR_LIST
        : PathRouteConstants.INACTIVE_TUTOR_LIST,
    ]);
  }
  tutorListDetails() {
    if (this.tutor.is_active) {
      this.router.navigate([PathRouteConstants.TUTOR_LIST]);
    } else {
      this.router.navigate([PathRouteConstants.INACTIVE_TUTOR_LIST]);
    }
  }
  editTutor(tutorId) {
    let path = this.tutor.is_active
      ? PathRouteConstants.TUTOR_LIST
      : PathRouteConstants.INACTIVE_TUTOR_LIST;
    this.router.navigate([
      path +
        "/" +
        UtilityService.replaceText(AppRouteConstants.UPDATE_TUTOR, [
          { text: ":id", data: tutorId },
        ]),
    ]);
  }

  getColor(name) {
    return StudentUtility.getColor(name);
  }
}
