import { Component, Input } from "@angular/core";

@Component({
  selector: "app-tutor-session-report",
  templateUrl: "./tutor-session-report.component.html",
})
export class TutorSessionReportComponent {
  @Input() sessionData: any;
  @Input() title: any;
  @Input() subTitles: any;
  smileys = [
    { value: 1, image: "/assets/smileys/very-unhappy" },
    { value: 2, image: "/assets/smileys/unhappy" },
    { value: 3, image: "/assets/smileys/neutral" },
    { value: 4, image: "/assets/smileys/happy" },
    { value: 5, image: "/assets/smileys/very-happy" },
  ];
  roundedAverage: number;

  getImage(smiley) {
    if (smiley.value === this.roundedAverage) {
      return smiley.image + "-filled.svg";
    } else {
      return smiley.image + "-dark.svg";
    }
  }

  getEngagementAverage(data) {
    let fixedVal = data.toFixed(1).toString();
    if (fixedVal.includes(".0")) {
      fixedVal = fixedVal.split(".")[0];
    }
    this.roundedAverage = Math.round(data);
    return fixedVal;
  }
}
