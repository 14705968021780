import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiDataService } from "src/app/shared/services/api.service";
import { District } from "src/app/shared/models/district";
import { School } from "src/app/shared/models/school";
import { ReportsAPIService } from "../reports-api.service";
import { ToastrService } from "ngx-toastr";
import { ErrorMessages } from "src/app/shared/constants/utility.constants";
import { ReportsService } from "../reports.service";
import { ReportParameters } from "../report-models/report-param";
import { ChartService } from "../charts/charts.service";

@Component({
  selector: "app-school-reports",
  templateUrl: "./school-reports.component.html",
  styleUrls: ["./school-reports.component.css"],
})
export class SchoolReportsComponent implements OnInit {
  loading: boolean;
  allDistricts: District[];
  selectedDistrict: District = {};
  schools: School[] = [];
  schoolYears: string[] = [];
  reportForm: FormGroup;
  selectedIndex: any;
  currentGrade = "fifth_grade";

  filtersSelected: any;
  graphData: ReportParameters = {};
  selectedReport: any;

  fullImageName: string;
  titleDRA: string;
  titleRIT: string;
  titleRank: string;
  titleAcumen: string;
  acumenData: any;
  subtitleAcumen: string;
  id: any;
  reportLoaded: boolean;
  averageGain: number;
  constructor(
    private apiService: ApiDataService,
    private reportsAPIService: ReportsAPIService,
    private toastrService: ToastrService,
    private reportsService: ReportsService,
    private chartService: ChartService
  ) {
    this.reportForm = new FormGroup({
      district: new FormControl("", Validators.required),
      school: new FormControl("", Validators.required),
      school_year: new FormControl("", Validators.required),
    });
  }

  ngOnInit() {
    this.getAllDistricts();
  }

  getAllDistricts() {
    this.loading = true;
    this.apiService.getAllDistricts().subscribe((res) => {
      if (res.length > 0) {
        this.allDistricts = res;
      }
      this.loading = false;
    });
  }
  districtSelected(event) {
    this.loading = true;
    this.id = event.value;
    this.schools = [];
    this.schoolYears = [];
    this.reportLoaded = false;
    this.reportForm.patchValue({
      school: "",
      school_year: "",
    });
    this.reportsAPIService
      .getSchoolsAndYearsByDistrict(this.id)
      .subscribe((res) => {
        if (res) {
          this.schools = res.schools;
          this.schoolYears = res.schoolYears;
        }
        this.loading = false;
      });
  }

  setListForTab(event) {
    switch (event) {
      case 0:
        this.currentGrade = "fifth_grade";
        break;
      case 1:
        this.currentGrade = "second_grade";
        break;
      case 2:
        this.currentGrade = "third_grade";
        break;
    }
    this.parseDataForGrade();
  }

  renderReport(dataSelected) {
    this.filtersSelected = dataSelected;
    this.loading = true;
    this.reportsAPIService.getSchoolReport(dataSelected).subscribe(
      (report) => {
        if (report) {
          this.reportLoaded = true;
          this.selectedReport = report;
          if (this.selectedReport) {
            this.parseDataForGrade();
            this.loading = false;
          }
        }
      },
      (err) => {
        this.loading = false;
        this.toastrService.error(ErrorMessages.RUN_REPORT);
      }
    );
  }

  parseDataForGrade() {
    this.parseTitles();
    const currentGradeData = this.selectedReport[this.currentGrade];
    const currentControlData =
      this.selectedReport[this.currentGrade + "_control"];
    this.graphData = null;
    this.graphData = {
      dra: {
        data: this.parseControlData(
          [
            currentGradeData.dra.fall,
            currentGradeData.dra.winter,
            currentGradeData.dra.spring,
          ],
          [
            currentControlData.dra.fall,
            currentControlData.dra.winter,
            currentControlData.dra.spring,
          ]
        ),
        extraData: [
          { data: currentGradeData.students, description: "Students" },
          {
            data: currentGradeData.dra.mid_year_gain,
            description: "Midyear Gain of HUG Students",
          },
          {
            data: currentGradeData.dra.end_year_gain,
            description: "End of Year Gain of HUG Students",
          },
        ],
      },
      rit: {
        data: this.parseControlData(
          [
            currentGradeData.rit.fall,
            currentGradeData.rit.winter,
            currentGradeData.rit.spring,
          ],
          [
            currentControlData.rit.fall,
            currentControlData.rit.winter,
            currentControlData.rit.spring,
          ]
        ),
        extraData: [
          {
            data: currentGradeData.rit.mid_year_gain,
            description: "Midyear Gain of HUG Students",
          },
          {
            data: currentGradeData.rit.end_year_gain,
            description: "End of Year Gain of HUG Students",
          },
        ],
      },
      rank: {
        data: this.parseControlData(
          [
            currentGradeData.rank.fall,
            currentGradeData.rank.winter,
            currentGradeData.rank.spring,
          ],
          [
            currentControlData.rank.fall,
            currentControlData.rank.winter,
            currentControlData.rank.spring,
          ]
        ),
        extraData: [
          {
            data: currentGradeData.rank.mid_year_gain,
            description: "Midyear Gain of HUG Students",
          },
          {
            data: currentGradeData.rank.end_year_gain,
            description: "End of Year Gain of HUG Students",
          },
        ],
      },
    };
    this.acumenData = this.chartService.formAcumenData(
      currentGradeData.acumen
    ).acumenData;
    this.subtitleAcumen = this.chartService.formAcumenData(
      currentGradeData.acumen
    ).subtitleAcumen;
    this.averageGain =
      Math.round(currentGradeData.acumen.second_attempt) -
      Math.round(currentGradeData.acumen.first_attempt);
  }

  parseTitles() {
    const currentGradeTitle =
      this.currentGrade === "fifth_grade"
        ? "1st Grade"
        : this.currentGrade === "second_grade"
        ? "2nd Grade"
        : this.currentGrade === "third_grade"
        ? "3rd Grade"
        : "";
    const schoolYearGradeCombined = `${this.selectedReport.school}<br>${this.filtersSelected.school_year}<br>${currentGradeTitle}`;
    this.titleDRA = `DRA Averages - ${schoolYearGradeCombined}`;
    this.titleRIT = `RIT Averages - ${schoolYearGradeCombined}`;
    this.titleRank = `Percentile Averages - ${schoolYearGradeCombined}`;
    this.titleAcumen = `H.U.G Acumen - ${schoolYearGradeCombined}`;
    this.fullImageName = `${this.selectedReport.school.replace(
      " ",
      "_"
    )}_${this.selectedReport.school_year.replace(
      "/",
      "_"
    )}_${currentGradeTitle}`;
  }

  parseControlData(data, controlData) {
    let finalControlData = [];

    if (data.length !== controlData.length) {
      this.toastrService.error(ErrorMessages.CONTROL_REAL_DATA_MISMATCH);
    } else
      finalControlData = controlData.map((ctrlData, i) => {
        if (data[i] === 0 && data[i] === null) return 0;
        else return ctrlData;
      });

    return [data, finalControlData];
  }

  exportAllReports() {
    if (this.selectedReport) {
      this.reportsService.takeSnapshot(
        document.getElementById("allGraphs"),
        this.fullImageName
      );
    }
  }
  exportIndividualReport(graphIdentifier) {
    switch (graphIdentifier) {
      case "DRA":
        this.reportsService.takeSnapshot(
          document.getElementById("draChart"),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
      case "RIT":
        this.reportsService.takeSnapshot(
          document.getElementById("ritChart"),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
      case "Rank":
        this.reportsService.takeSnapshot(
          document.getElementById("rankChart"),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
      case "Acumen":
        this.reportsService.takeSnapshot(
          document.getElementById("acumenChart"),
          `${this.fullImageName}_${graphIdentifier}`
        );
        break;
    }
  }
}
