import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { ToastrService } from "ngx-toastr";
import {
  SuccessMessages,
  ErrorMessages,
  ActionMessages,
} from "src/app/shared/constants/utility.constants";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { PathRouteConstants } from "src/app/shared/constants/app-route.constants";
import { UtilityService } from "src/app/shared/services/utility.service";
import { LessonsApiService } from "../../services/lessons-api.service";
import { Lesson } from "../../models/lesson";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AppUser } from "src/app/shared/models/app-user";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentConstants } from "src/app/feature-modules/student-list/constants/student.constants";

@Component({
  selector: "app-lesson-details",
  templateUrl: "./lesson-details.component.html",
  styleUrls: ["./lesson-details.component.css"],
})
export class LessonDetailsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  lessonId: number;
  loading: boolean = true;
  lesson: Lesson;
  images: any[] = [];
  indexBasedImages: any[] = [];
  loggedInUser: AppUser;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;
  image: string[];
  image1: string;

  constructor(
    private apiService: ApiDataService,
    private lessonsApiService: LessonsApiService,
    private sharedDialogService: SharedDialogService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.lessonId = +params.id;
      this.getUserInfo();
      this.getLesson();
    });
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
      }
    });
  }
  getLesson() {
    this.lessonsApiService
      .getLesson(this.lessonId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res) {
            try {
            this.lesson = res;
            res?.passage?.forEach((passage, index) => {
              passage.summary = passage?.summary || ""
              passage.miro_url = passage?.miro_url || ""
              if (!passage?.primary_image_path && !passage?.sec_image_path && !passage?.ter_image_path && !passage?.quat_image_path) {
                 this.indexBasedImages.push({ path: "assets/images/noimage.jpg" });
              }
              if (passage?.primary_image_path) {
                this.indexBasedImages.push({ path: passage?.primary_image_path });
              }
              if (passage?.sec_image_path) {
                this.indexBasedImages.push({ path: passage?.sec_image_path });
              }
              if (passage?.ter_image_path) {
                this.indexBasedImages.push({ path: passage?.ter_image_path });
              }
              if (passage?.quat_image_path) {
                this.indexBasedImages.push({ path: passage?.quat_image_path });
              }
              this.images[index] = this.indexBasedImages
              this.indexBasedImages = []
            })
          } catch (error) {
              this.loading = false;
              this.toastrService.error(ErrorMessages.SOMETHING_WENT_WRONG);
              this.navigateBack();
            }
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastrService.error(ErrorMessages.SOMETHING_WENT_WRONG);
          this.navigateBack();
        }
      );
  }

  deleteLesson() {
    this.sharedDialogService
      .ConfirmAction({
        Title: "Delete " + this.lesson.lesson_plan_name,
        Message: UtilityService.replaceText(
          ActionMessages.DELETE_CONFIRMATION,
          [{ text: "_name", data: this.lesson.lesson_plan_name }]
        ),
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.lessonsApiService
            .deleteLesson(this.lessonId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              if (result) {
                this.toastrService.success(SuccessMessages.DELETE_LESSON);
                this.navigateBack();
              } else {
                this.toastrService.error(ErrorMessages.LESSON_DELETE);
              }
            });
        }
      });
  }

  navigateBack() {
    this.navigationService.navigateToPage([PathRouteConstants.LESSONS]);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
