export class ChartOptions {
    public static barChartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: [],
            crosshair: true
        },
        yAxis: {
            min: 0,
            max: 0,
            tickInterval: 0,
            title: {
                text: ''
            }
        },
        tooltip: {},
        plotOptions: {
            column: {
                pointPadding: 0.05,
                borderWidth: 0,
                groupPadding: 0.3,
                dataLabels: {
                    enabled: true,
                    crop: true,
                    inside: true,
                    verticalAlign: 'top',
                    formatter() {
                        return this.y !== 0 ? this.y : '';
                    }
                }
            },
            series: {
                enableMouseTracking: false
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        series: [
            {
                name: '',
                showInLegend: false,
                color: '',
                type: 'column',
                data: []
            }
        ]
    };

    public static DonutChartOptions = {
        chart: {
            type: 'pie',
            plotShadow: false
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '14px'
            }
        },
        subtitle: {
            text: '',
            verticalAlign: 'middle',
            align: 'center',
            y: 55,
            style: {},
            useHTML: true
        },
        plotOptions: {
            series: {
                showInLegend: true,
                enableMouseTracking: false
            },
            pie: {
                point: {
                    events: {
                        legendItemClick(e) {
                            e.preventDefault();
                        }
                    }
                }
            }
        },
        tooltip: {
            enabled: false,
            shadow: false
        },
        series: [
            {
                name: '',
                data: [],
                size: '100%',
                innerSize: '70%',
                color: '#00b2e2',
                linecap: 'round'
            },
            {
                name: '',
                data: [],
                size: '70%',
                innerSize: '55%',
                color: '#1b3d6e',
                linecap: 'round'
            }
        ],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        legend: {
            useHTML: true,
            verticalAlign: 'top',
            align: 'center',
            layout: 'horizontal',
            itemStyle: {
                fontSize: '12px',
                fontWeight: 'bold'
            }
        }
    };
    public static BENCHMARK_DATA = {
        second_grade: {
            dra: {
                fall: 18,
                winter: 24,
                spring: 28
            },
            rit: {
                fall: 175,
                winter: 175,
                spring: 175
            },
            rank: {
                // TEMP
                fall: 0,
                winter: 0,
                spring: 0
            }
        },
        third_grade: {
            dra: {
                fall: 30,
                winter: 34,
                spring: 38
            },
            rit: {
                fall: 188,
                winter: 188,
                spring: 188
            },
            rank: {
                // TEMP
                fall: 0,
                winter: 0,
                spring: 0
            }
        },
        fifth_grade: {
            dra: {
                fall: 30,
                winter: 34,
                spring: 38
            },
            rit: {
                fall: 188,
                winter: 188,
                spring: 188
            },
            rank: {
                // TEMP
                fall: 0,
                winter: 0,
                spring: 0
            }
        }
    };

    public static horizontalBarChartOptions = {
        chart: {
            type: 'bar'
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: [],
            title: {
                text: ''
            }
        },
        yAxis: {
            // min: 0,
            max: 0,
            tickInterval: 0,
            title: {
                text: ''
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {},
        plotOptions: {
            bar: {
                pointPadding: 0.05,
                borderWidth: 0,
                groupPadding: 0.1,
                dataLabels: {
                    enabled: true,
                    crop: true,
                    inside: true,
                    verticalAlign: 'top',
                    formatter() {
                        return this.y !== 0 ? this.y : '';
                    }
                }
            },
            series: {
                enableMouseTracking: false
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        series: [
            {
                name: '',
                showInLegend: false,
                color: '',
                data: []
            }
        ]
    };
}
