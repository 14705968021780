import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ToastrModule } from "ngx-toastr";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { CreateEditDistrictComponent } from "../feature-modules/districts/create-edit-district/create-edit-district.component";
import { AngularMaterialModule } from "./angular-material.module";
import { SectionLoaderComponent } from "./components/section-loader/section-loader.component";
import { ActivateStudentComponent } from "./modal-dialogs/activate-student/activate-student.component";
import { AddNotesComponent } from "./modal-dialogs/add-notes/add-notes.component";
import { ConfirmActionComponent } from "./modal-dialogs/confirm-action/confirm-action.component";
import { FilterPipe } from "./pipes/common-filter";
import { DistrictFilterPipe } from "./pipes/district-filter.pipe";
import { SearchPipe } from "./pipes/search.pipe";
import { StudentFilterPipe } from "./pipes/student-filter.pipe";
import { VolunteerFilterPipe } from "./pipes/volunteer-filter.pipe";
import { ApiDataService } from "./services/api.service";
import { NavigationService } from "./services/navigation.service";
import { SharedDataService } from "./services/shared-data.service";
import { StorageService } from "./services/storage.service";
import { StudentsApiService } from "../feature-modules/student-list/services/students-api.service";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { IncrementInputComponent } from "./components/increment-input/increment-input.component";
import { StrictNumber } from "./directives/strict-numbers.directive";
import { MessageService } from "./services/message.service";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    StudentFilterPipe,
    VolunteerFilterPipe,
    FilterPipe,
    SearchPipe,
    SafeHtmlPipe,
    DistrictFilterPipe,
    CreateEditDistrictComponent,
    ConfirmActionComponent,
    SectionLoaderComponent,
    AddNotesComponent,
    StrictNumber,
    ActivateStudentComponent,
    IncrementInputComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    NgxMaskModule.forRoot(options),
    ToastrModule.forRoot(),
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    InfiniteScrollModule,
  ],
  exports: [
    StudentFilterPipe,
    FilterPipe,
    SearchPipe,
    SafeHtmlPipe,
    DistrictFilterPipe,
    AngularMaterialModule,
    FormsModule,
    VolunteerFilterPipe,
    NgxMaskModule,
    ReactiveFormsModule,
    ToastrModule,
    SectionLoaderComponent,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    IncrementInputComponent,
  ],
  providers: [
    SharedDataService,
    ApiDataService,
    StudentsApiService,
    StorageService,
    NavigationService,
    MessageService,
    StudentFilterPipe,
    VolunteerFilterPipe,
    FilterPipe,
    SearchPipe,
    DistrictFilterPipe,
    MatDatepickerModule,
  ],
  bootstrap: [],
})
export class SharedModule {}
