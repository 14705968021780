import { Injectable } from "@angular/core";
import { StudentInfo } from "src/app/shared/models/studentinfo";
import { NumberConstants } from "./shared/constants/utility.constants";

@Injectable({
  providedIn: "root",
})
export class StudenFilterService {
  studentInfo: StudentInfo = {
    school: -1,
    isActive: 1,
    grade: -1,
    searchTerm: "",
    limit: NumberConstants.LIST_LIMIT, // = 25
    offset: 0,
    controlGroup: 0,
  };
}
