export class UtilityService {
  static replaceText(val: string, replaceArray: any[]) {
    for (let replaceItem of replaceArray) {
      val = val.replace(replaceItem.text, replaceItem.data);
    }
    return val;
  }

  static sortArray(array: any) {
    array.sort(function (a, b) {
      if (a.UserName < b.UserName) {
        return -1;
      }
      if (a.UserName > b.UserName) {
        return 1;
      }
      return 0;
    });
  }
}
