import { Component, Input, OnInit } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import {
  ActionMessages,
  SuccessMessages,
  Titles,
} from "src/app/shared/constants/utility.constants";
import { AppUser } from "src/app/shared/models/app-user";
import { Student } from "src/app/shared/models/student-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { UtilityService } from "src/app/shared/services/utility.service";
import { StudentConstants } from "../constants/student.constants";
import { SharedDataService } from "src/app/shared/services/shared-data.service";

@Component({
  selector: "app-student-details",
  templateUrl: "./student-details.component.html",
  styleUrls: ["./student-details.component.css"],
})
export class StudentDetailsComponent implements OnInit {
  @Input() tabs: { text: string; value: any }[] = [];
  ngUnsubscribe = new Subject();
  selectedValue;
  studentId: number;
  student: Student;
  loggedInUser: AppUser;
  @Input() set initialValue(value) {
    this.selectedValue = value;
  }
  isTutor: boolean = false;
  user: Student;
  activeTabIndex = 0;
  selectedBulkAction: any;
  selectedStudents: Student[] = [];
  loading = true;
  loadingDetails: boolean;
  studentList: any;
  controlGroupStudents: any;
  filteredStudentList: any;
  schoolSelected: any;
  gradeSelected: any;
  selectedIndex: number;
  schoolList: any;
  updateButtonText = ["Edit Student", "Update Progress", "Edit Scores"];
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;
  id: number;
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiDataService,
    private sharedDialogService: SharedDialogService,
    private toastrService: ToastrService,
    private navigationService: NavigationService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getUserInfo();
    this.route.params.subscribe((params) => {
      this.studentId = +params.id;
      if (this.studentId) {
        this.getStudent();
      }
    });
    this.activeTabIndex = this.sharedDataService.selectedTab;
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.id = res.id;
        this.isTutor = this.loggedInUser === this.tutorRoleId;
      }
    });
  }

  maskSingleName(name: string): string {
    const nameLength = name.length;
    const visibleLetters = Math.min(2, nameLength);
    return `${name.slice(0, visibleLetters)}${"*".repeat(
      Math.max(0, nameLength - visibleLetters)
    )}`;
  }

  getStudent() {
    const loggedInUser = this.apiService.getUserInfo();
    const student = this.apiService.getStudent(this.studentId);
    forkJoin([loggedInUser, student])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((joinedRes) => {
        if (joinedRes && joinedRes.length) {
          this.loggedInUser = joinedRes[0]
            ? joinedRes[0].role.id
            : ({} as AppUser);
          this.student = joinedRes[1] ? joinedRes[1] : ({} as Student);
          this.loading = false;
          this.loadingDetails = false;
        }
      });
  }

  ActivateOrDeactivate() {
    this.student.second_name = this.student.second_name
      ? this.student.second_name
      : "";
    const data = {
      action: "Individual",
      title: Titles.MAKE_STUDENT_INACTIVE,
      message: UtilityService.replaceText(ActionMessages.DEACTIVATE_STUDENT, [
        {
          text: "_studName",
          data: `${this.student.first_name}
        ${this.student.second_name} ${this.student.last_name}`,
        },
      ]),
      studentList: this.student,
    };
    if (this.student.is_active) {
      this.sharedDialogService.DeactivateStudent(data).subscribe((res) => {
        if (res) {
          this.toastrService.success(SuccessMessages.DEACTIVATE_STUDENT);
          this.goToStudentList();
        }
      });
    } else {
      this.replaceText(data);
    }
  }
  replaceText(data) {
    data.title = Titles.REACTIVATE_STUDENTS;
    data.message = UtilityService.replaceText(
      ActionMessages.REACTIVATE_STUDENT,
      [
        {
          text: "_studName",
          data: `${this.student.first_name} ${this.student.second_name} ${this.student.last_name}`,
        },
      ]
    );
    this.sharedDialogService.ReactivateStudent(data).subscribe((res) => {
      if (res) {
        this.toastrService.success(SuccessMessages.REACTIVATE_STUDENT);
        this.goToStudentList();
      }
    });
  }
  tabChanged(event: MatTabChangeEvent) {
    this.sharedDataService.selectedTab = event.index;
    if (!event.index) {
      this.loadingDetails = true;
      this.getStudent();
    }
  }

  updateButtonAction(studentId) {
    switch (this.activeTabIndex) {
      case 0:
        this.editStudent(studentId);
        break;

      case 1:
        this.updateStudentProgress(studentId);
        break;

      case 2:
        this.editScores(studentId);
        break;
    }
  }

  editScores(studentId: any) {
    this.navigationService.navigateToPage([
      PathRouteConstants.STUDENT_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.SCORES_UPDATE, [
          { text: ":id", data: studentId },
        ]),
    ]);
  }

  updateStudentProgress(studentId: any) {
    this.navigationService.navigateToPage([
      PathRouteConstants.STUDENT_LIST +
        "/" +
        UtilityService.replaceText(AppRouteConstants.PROGRESS_UPDATE_WITH_SCHOOL_ID, [
          { text: ":id", data: studentId }, { text: ":schoolId", data: this.student.school.id },
        ]),
    ]);
  }

  editStudent(studentId) {
    const url = this.student.is_active
      ? PathRouteConstants.STUDENT_LIST
      : PathRouteConstants.INACTIVE_STUDENT_LIST;
    this.navigationService.navigateToPage([
      url +
        "/" +
        UtilityService.replaceText(AppRouteConstants.STUDENT_UPDATE, [
          { text: ":id", data: studentId },
        ]),
    ]);
  }

  goToStudentList() {
    this.navigationService.navigateToPage([
      this.student.is_active
        ? PathRouteConstants.STUDENT_LIST
        : PathRouteConstants.INACTIVE_STUDENT_LIST,
    ]);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
