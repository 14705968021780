import { Component, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  AppRouteConstants,
  PathRouteConstants,
} from "src/app/shared/constants/app-route.constants";
import {
  ActionMessages,
  NumberConstants,
  SuccessMessages,
  Titles,
} from "src/app/shared/constants/utility.constants";
import { AppUser } from "src/app/shared/models/app-user";
import { School } from "src/app/shared/models/school";
import { SearchBase } from "src/app/shared/models/search-base";
import { Student } from "src/app/shared/models/student-base";
import { StudentInfo } from "src/app/shared/models/studentinfo";
import { ApiDataService } from "src/app/shared/services/api.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { UtilityService } from "src/app/shared/services/utility.service";
import { StudenFilterService } from "src/app/studen-filter.service";
import { StudentConstants } from "../constants/student.constants";

@Component({
  selector: "app-inactive-student-list",
  templateUrl: "./inactive-student-list.component.html",
  styleUrls: ["./inactive-student-list.component.css"],
})
export class InactiveStudentListComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  loading: boolean;
  selectedSchool = this.studentFilterService.studentInfo.school;
  levelSelected =
    this.studentFilterService.studentInfo.grade == 5
      ? 0
      : this.studentFilterService.studentInfo.grade;
  schoolSelected: string;
  gradeSelected: string;
  gradeList = StudentConstants.GRADES;
  schoolList: School[] = [];
  inactiveStatus = StudentConstants.INACTIVE_STATUS;
  bulkActions = StudentConstants.BULK_ACTIONS_INACTIVE;
  tutorBulkActions = StudentConstants.TUTOR_BULK_ACTIONS_INACTIVE;
  matTabLabels = StudentConstants.MAT_TAB_LABELS;
  searchObj: SearchBase = { Status: "All" };
  filteredStudentList: Student[] = [];
  selectedStudents: Student[] = [];
  studentList: Student[];
  entireListSelected: boolean;
  selectedBulkAction: string;
  controlGroupStudents: Student[];
  selectedIndex = 0;
  basicLimit = NumberConstants.LIST_LIMIT;
  studentInfo: StudentInfo;
  levelList: any;
  count: number;
  filteredListCount = { count: 0 };
  filteredCount: number;
  loggedInUser: AppUser;
  id: number;
  tutorRoleId = StudentConstants.TUTOR_ROLE_ID;
  selectedStudentList = [];
  isTutor: boolean = false;
  constructor(
    private apiService: ApiDataService,
    private studentFilterService: StudenFilterService,
    private sharedDialogService: SharedDialogService,
    private toastrService: ToastrService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.studentInfo = this.studentFilterService.studentInfo;
    this.studentInfo.isActive = 0;
    this.getUserInfo();
    this.getSchools();
    this.getGradeLevels();
    if (sessionStorage.getItem("tab")) {
      this.studentInfo.controlGroup = parseInt(sessionStorage.getItem("tab"));
    }
  }

  getInactiveStudents(isRefreshRequired = false) {
    this.loading = true;
    this.studentInfo.isActive = 0;
    this.apiService
      .getAllStudents(this.studentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        for (let value of res.student) {
          if (this.entireListSelected && !isRefreshRequired) {
            value.selected = true;
          }
          this.filteredStudentList.push(value);
        }
        this.filteredCount = this.filteredStudentList.filter(
          (item) =>
            item.WhyInactive.toLowerCase() ===
              this.inactiveStatus[0].toLowerCase() || item.WhyInactive === ""
        ).length;
        this.selectedStudentList = this.filteredStudentList.filter(
          (student) => student.selected === true
        );
        if (res.count) {
          this.count = res.count;
        } else {
          this.count = this.filteredStudentList.length;
        }
        if (isRefreshRequired) {
          this.selectedStudents = [];
          this.entireListSelected = false;
        }
        this.loading = false;
      });
  }
  maskLastName(fullName: string): string {
    const nameParts = fullName.split(" ");
    if (nameParts.length >= 2) {
      let lastName = nameParts[nameParts.length - 1];
      // Only show the first two letters of the last name
      const visibleLetters = Math.min(2, lastName.length);
      const maskedLastName = `${lastName.slice(0, visibleLetters)}${"*".repeat(
        Math.max(0, lastName.length - visibleLetters)
      )}`;
      nameParts[nameParts.length - 1] = maskedLastName;
      return nameParts.join(" ");
    } else {
      return fullName;
    }
  }
  getUserInfo() {
    this.loading = true;
    this.studentInfo.controlGroup = 0;
    this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUser = res.role.id;
        this.id = res.id;
        this.isTutor = this.loggedInUser === this.tutorRoleId;
      }
      if (this.isTutor) {
        this.getAssociatedStudentList();
      } else {
        this.getInactiveStudents();
      }
    });
    this.loading = false;
  }
  getAssociatedStudentList(isRefreshRequired = false) {
    this.loading = true;
    this.apiService
      .getAllAssociatedStudents(this.id, this.studentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        // tslint:disable-next-line:prefer-for-of
        for (let value of res.student) {
          if (this.entireListSelected && !isRefreshRequired) {
            value.selected = true;
          }
          this.filteredStudentList.push(value);
        }
        this.selectedStudentList = this.filteredStudentList.filter(
          (student) => student.selected === true
        );
        this.count = res.count;
        if (isRefreshRequired) {
          this.selectedStudents = [];
          this.entireListSelected = false;
        }
        this.loading = false;
      });
  }
  resetAllFilter() {
    this.searchObj = {
      Status: this.inactiveStatus[0],
    };
  }
  onScrollDown() {
    this.studentInfo.offset = this.studentInfo.offset + this.basicLimit;
    this.getInactiveStudents();
  }

  getSchools() {
    this.apiService.getSchoolList().subscribe((response) => {
      if (response) {
        this.schoolList = response;
        this.schoolList = this.schoolList.sort((a, b) => {
          if (a.school_name < b.school_name) return -1;
          if (a.school_name > b.school_name) return 1;
          return 0;
        });
        this.selectedStudents = [];
      }
    });
  }

  getGradeLevels() {
    this.apiService.getAllGrades().subscribe((response) => {
      if (response) {
        this.levelList = response;
        const allLevel = { id: -1, grade_level: "All Grades" };
        this.levelList.push(allLevel);
        for (var i in this.levelList) {
          if (this.levelList[i].id == 5) {
            this.levelList[i].id = 0;
            break;
          }
        }
        this.levelList = this.levelList.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
        this.selectedStudents = [];
      }
    });
  }

  onLevelChange(event) {
    if (event.value || event.value == 0) {
      this.filteredStudentList = [];
      this.studentInfo.offset = 0;
      this.studentInfo.grade = event.value;
      if (this.studentInfo.grade == 0) {
        this.studentFilterService.studentInfo.grade = 5;
      } else {
        this.studentFilterService.studentInfo.grade = event.value;
      }
      if (this.loggedInUser === this.tutorRoleId) {
        this.getAssociatedStudentList();
      } else {
        this.getInactiveStudents();
      }
    }
  }

  onSchoolChange(event) {
    if (event.value) {
      this.filteredStudentList = [];
      this.studentInfo.offset = 0;
      this.studentInfo.school = event.value;
      this.studentFilterService.studentInfo.school = event.value;
      if (this.loggedInUser === this.tutorRoleId) {
        this.getAssociatedStudentList();
      } else {
        this.getInactiveStudents();
      }
    }
  }

  studentSelect(event, student) {
    if (event.target.checked) {
      student.selected = true;
      if (
        this.selectedStudents.findIndex(
          (selected) => selected.id === student.id
        ) === -1
      ) {
        this.selectedStudents.push(student);
      }
    } else {
      this.entireListSelected = false;
      const unCheckedStudentIndex = this.selectedStudents.findIndex(
        (stud) => stud.id === student.id
      );
      if (unCheckedStudentIndex !== -1) {
        this.selectedStudents[unCheckedStudentIndex].selected = false;
      }
    }
    this.selectedStudentList = this.selectedStudents.filter(
      (students) => students.selected === true
    );
    if (this.selectedStudentList.length === this.filteredStudentList.length) {
      this.entireListSelected = true;
    }
  }

  selectAllUser(event) {
    this.selectedStudents = [];
    this.entireListSelected = false;
    const checked = event.currentTarget ? event.currentTarget.checked : event;
    this.filteredStudentList.forEach((student) => {
      student.selected = checked;
    });
    if (checked) {
      this.entireListSelected = true;
      this.selectedStudents = this.filteredStudentList;
      this.selectedStudentList = this.filteredStudentList.filter(
        (student) => student.selected === true
      );
    }
  }
  setListForTab(event) {
    this.studentInfo.controlGroup = event;
    sessionStorage.setItem("tab", event);
    this.filteredStudentList = [];
    this.selectedStudentList = [];
    this.studentInfo.offset = 0;
    if (this.loggedInUser === this.tutorRoleId) {
      this.getAssociatedStudentList();
    } else {
      this.getInactiveStudents();
    }
  }

  onSearch() {
    this.studentInfo.offset = 0;
    this.filteredStudentList = [];
    if (this.loggedInUser === this.tutorRoleId) {
      this.getAssociatedStudentList();
    } else {
      this.getInactiveStudents();
    }
  }

  filterByStatus() {
    this.filteredCount = this.filteredStudentList.filter(
      (stu) =>
        stu.WhyInactive.toLowerCase() === this.searchObj.Status.toLowerCase() ||
        stu.WhyInactive === ""
    ).length;
  }

  bulkAction() {
    switch (this.selectedBulkAction) {
      case this.bulkActions[0]: {
        this.sharedDialogService
          .ActivateStudent({
            action: this.selectedBulkAction,
            title: Titles.ACTIVATE_STUDENTS,
            message: ActionMessages.ACTIVATE_STUDENTS,
            studentList: this.selectedStudentList,
            totalStudentsCount: this.count,
          })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            this.bulkActionSuccessful(res);
          });
        break;
      }
      case this.bulkActions[1]: {
        // add notes
        this.sharedDialogService
          .AddNotes({
            action: this.selectedBulkAction,
            studentList: this.selectedStudentList,
            totalStudentsCount: this.count,
          })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            this.bulkActionSuccessful(res);
          });
        break;
      }
    }
  }

  studentProfile(studentId) {
    this.navigationService.navigateToPage([
      PathRouteConstants.FEATURES +
        UtilityService.replaceText(AppRouteConstants.INACTIVE_STUDENT_DETAIL, [
          { text: ":id", data: studentId },
        ]),
    ]);
  }

  editStudent(studentId) {
    this.navigationService.navigateToPage([
      PathRouteConstants.FEATURES +
        UtilityService.replaceText(AppRouteConstants.INACTIVE_STUDENT_UPDATE, [
          { text: ":id", data: studentId },
        ]),
    ]);
  }

  bulkActionSuccessful(success) {
    if (success) {
      this.selectedStudents = [];
      this.loggedInUser === this.tutorRoleId
        ? this.getAssociatedStudentList(true)
        : this.getInactiveStudents(true);
      this.toastrService.success(SuccessMessages.BULK_ACTION);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
