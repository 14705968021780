import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppRouteConstants } from '../constants/app-route.constants';

@Injectable()
export class NavigationService {
  constructor(private _router: Router) {}

  // navigateToPage(route: string[], options?: { queryParams?: any }): void {
  //   this._router.navigate(route, options);
  //   // This is void because navigate doesn't return a promise
  // }
  navigateToPage(route, params = {}) {
    this._router.navigate(route);
  }

  navigateToPageWithData(route, tab) {
    this._router.navigate(route, { state: { data: { id: tab } } });
  }

  navigateRelativeToParent(route, raltiveTo) {
    this._router.navigate(route, { relativeTo: raltiveTo });
  }

  navigateToUserPage() {
    this.navigateToPage([AppRouteConstants.STUDENT_LIST]);
  }

  navigateToLogin() {
    this.navigateToPage([AppRouteConstants.LOGIN]);
  }
  navigateToPageAsync(
    route: string[],
    options?: { queryParams?: any }
  ): Promise<boolean> {
    return this._router.navigate(route, options);
  }
}
